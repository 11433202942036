import { InstallationStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { Pending } from "./1-Pending"
import { UpdateInstallation } from "./10-UpdateInstallation"
import { CertificateSent } from "./11-CertificateSent"
import { CertificateSigned } from "./12-CertificateSigned"
import { SchedulingOnHold } from "./2-SchedulingOnHold"
import { CannotSchedule } from "./3-CannotSchedule"
import { Requested } from "./4-Requested"
import { Scheduled } from "./5-Scheduled"
import { ProjectPrepared } from "./6-ProjectPrepared"
import { InProgress } from "./7-InProgress"
import { NewVisitNeeded } from "./8-NewVisitNeeded"
import { Completed } from "./9-Completed"

export const getInstallationStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case InstallationStep.PENDING:
      return { StepComponent: Pending, isCancellable: false }
    case InstallationStep.SCHEDULING_ON_HOLD:
      return { StepComponent: SchedulingOnHold, isCancellable: false }
    case InstallationStep.CANNOT_SCHEDULE:
      return { StepComponent: CannotSchedule, isCancellable: false }
    case InstallationStep.REQUESTED:
      return { StepComponent: Requested, isCancellable: false }
    case InstallationStep.SCHEDULED:
      return { StepComponent: Scheduled, isCancellable: false }
    case InstallationStep.PROJECT_PREPARED:
      return { StepComponent: ProjectPrepared }
    case InstallationStep.IN_PROGRESS:
      return { StepComponent: InProgress }
    case InstallationStep.NEW_VISIT_NEEDED:
      return { StepComponent: NewVisitNeeded }
    case InstallationStep.COMPLETED:
      return { StepComponent: Completed }
    case InstallationStep.UPDATE_INSTALLATION:
      return { StepComponent: UpdateInstallation }
    case InstallationStep.CERTIFICATE_SENT:
      return { StepComponent: CertificateSent, isCancellable: false }
    case InstallationStep.CERTIFICATE_SIGNED:
      return { StepComponent: CertificateSigned, isCancellable: false }
    default:
      return {
        StepComponent: null,
      }
  }
}
