import { Select } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { GroupedOptions } from "@ensol/entool/utils/form/radio"
import { getSchedulingIssueMainReasonLabel } from "@ensol/entool/utils/projects/schedulingIssues"

type Props<Values> = {
  form: UseFormReturnType<Values>
  initialValues: {
    schedulingIssueMainReason: string | null
    schedulingIssueSubReason: string | null
  }
  options: GroupedOptions<string>[]
}

export const SchedulingIssueFormField = <Values,>({
  form,
  initialValues,
  options,
}: Props<Values>) => (
  <FormField
    initialValues={initialValues}
    propertyName="schedulingIssueMainReason"
    isRequired
  >
    <Select
      w={FIELD_WIDTH}
      searchable
      {...form.getInputProps("schedulingIssueSubReason")}
      error={form.errors.schedulingIssueMainReason}
      onChange={(value) => {
        form
          .getInputProps("schedulingIssueMainReason")
          .onChange(value ? getSchedulingIssueMainReasonLabel(value) : null)
        form.getInputProps("schedulingIssueSubReason").onChange(value)
      }}
      data={options}
    />
  </FormField>
)
