export const EMAILS_FIELDS_LABELS = {
  formalName: "Nom du client",
  opsOwnerName: "Nom du responsable ops",
  opsOwnerPhone: "Téléphone du responsable ops",
  acknowledgmentLink: "Lien du récépissé",
  approvalLink: "Lien de l'autorisation d'urbanisme",
  acknowledgmentABFLink: "Lien du récépissé ABF",
  technicalVisitReportLink: "Lien du rapport de visite technique",
  panelNoticeLink: "Lien notice panneaux",
  inverterNoticeLink: "Lien notice onduleurs",
  applicationName: "Nom de l'application de suivi",
  applicationLink: "Lien de l'application de suivi",
  installationStartDate: "Date de l'installation",
  installationDurationInDays: "Durée de l'installation (jours)",
  consuelFirstNoticeOfVisitLink: "Lien du premier avis de passage",
  consuelVisitWeek: "Semaine de la visite",
  consuelSecondNoticeOfVisitLink: "Lien du second avis de passage",
  consuelVisitDateTime: "Date et heure de la visite",
  consuelAttestationLink: "Attestation visée du Consuel",
  enedisActivationDate: "Date de mise en service",
  signatureLink: "Lien de signature",
  certificateLink: "Lien de l'attestation",
}

const FOLLOW_UP_FIELDS_LABELS = {
  followUpNotes: "Notes",
  followUpExternalPlantIdentifier: "Identifiant système",
  followUpProductionStartDate: "Date de début de production",
  isPanelsLayoutValid: "Est-ce que le calepinage est valide ?",
  areClampsWorking: "Est-ce que les pinces sont correctement installées ?",
  hasNoAlerts: "L'installation ne présente pas d'alertes ?",
  followUpMonthlyReportsPath: "Rapport(s) mois",
  shouldSendMonthlyReports: "Envoyer automatiquement le rapport chaque mois ?",
}

const CONSUEL_FIELDS_LABELS = {
  consuelNotes: "Notes",
  consuelReference: "Référence Consuel",
  consuelRequestForConnectionPath: "Demande de raccordement Enedis",
  consuelComplianceReportPath: "Rapport de conformité",
  consuelCertificatePath: "Attestation Consuel",
  consuelApprovalDate: "Date d'approbation",
  consuelSummonsWeek: "Semaine de la convocation",
  consuelSummonsDate: "Date & heure de la convocation",
  consuelNonComplianceReportPaths: "Rapport de non-conformité",
  isValidationVisitNecessary: "Visite post travaux nécessaire ?",
  consuelFirstNoticeOfVisitPath: "Premier avis de visite",
  consuelSecondNoticeOfVisitPath: "Second avis de visite",
  consuelOtherDocumentsPaths: "Autres documents",
  consuelWorksDate: "Date des travaux",
  consuelWorksLocations: "Lieux des travaux",
  consuelWorksReasons: "Raisons des travaux",
  consuelWorksInstallerId: "Installateur",
}

const ENEDIS_FIELDS_LABELS = {
  enedisNotes: "Notes",
  enedisReference: "Référence Enedis",
  enedisIncompleteFileReasons: "Raisons incomplet",
  enedisIncompleteFileDetails: "Détails incomplet",
  enedisActivationDate: "Date de mise en service",
}

const HARDWARE_FIELDS_LABELS = {
  supplierId: "Fournisseur",
  orderQuoteNumber: "Id du devis",
  orderAmount: "Montant du devis",
  orderDeliveryDate: "Date de livraison",
  isFinalDeliveryDate: "Est-ce la date de livraison finale ?",
  orderDeliveryComment: "Commentaire livraison",
  hardwareDeliveryMode: "Mode de livraison",
  hardwareDeliveryAddress: "Adresse de livraison",
}

const INSTALLATION_FIELDS_LABELS = {
  areInstallationsCommentsConsidered:
    "Les commentaires d'installation sont-ils pris en compte ?",
  isActivationPrepared: "La mise en service a été préparée ?",
  isInstallationReportPrepared:
    "Le compte-rendu d'installation a été préparé ?",
  isTechnicalVisitReportSent:
    "Le compte-rendu de visite technique a été envoyé ?",
  isActivationDone: "La mise en service a été réalisée ?",
  isClientAccountCreated: "La compte client a été créé ?",
  isInstallationReportValidated:
    "Le compte-rendu d'installation a été validé ?",
  installationNewVisitDate: "Date passage",
  installationNewVisitReason: "Raison passage",
  installationSchedulingHoldEndDate: "Date à rappeler",
  installationLastContactDate: "Dernier contact",
  installationStartDate: "Date début installation",
  technicalExpertId: "Expert technique",
  siteManagerId: "Chef de chantier",
  installationReportPath: "Compte-rendu installation",
  installationPhotosPaths: "Photos de l'installation",
  installationSecurityReportPath: "Compte-rendu sécurité",
  installationCertificatePath: "Bon de fin de chantier",
}

const TECHNICAL_VISIT_FIELDS_LABELS = {
  salesVisitK2ReportPath: "Rapport K2 visite commerciale",
  salesVisitPanelsLayoutPath: "Calepinage visite commerciale",
  technicalVisitSchedulingHoldEndDate: "Date à rappeler",
  technicalVisitLastContactDate: "Dernier contact",
  technicalVisitStartDate: "Date & heure de la visite",
  technicalVisitExpectedValidationDate: "Date de validation prévue",
  technicalVisitDifficulty: "Difficulté de la visite",
  installation: "Installation",
  isTechnicalVisitReady:
    "Est-ce que la visite technique est prête et la conversation Kraaft créée ?",
  technicalVisitReportReceivedDate: "Date réception rapport VT",
  technicalVisitRequiredWorks: "Travaux requis",
  technicalVisitRequiredChanges: "Modifications requises",
  technicalVisitRejectedReasons: "Sélectionnez les raisons du rejet :",
  technicalVisitStartOfWorksDate: "Date début des travaux",
  technicalVisitEndOfWorksDate: "Date fin des travaux",
  technicalVisitWorksArtisan: "Nom de l'artisan",
  technicalVisitWorksPhotos: "Photos des travaux",
}

const PRELIMINARY_REQUEST_FIELDS_LABELS = {
  preliminaryRequestNotes: "Notes",
  preliminaryRequestLastContactDate: "Date du dernier contact",
  preliminaryRequestSubmissionDate: "Date d'envoi de la demande",
  preliminaryRequestExpectedValidationDate: "Date de validation prévue",
  preliminaryRequestDeadlineDate: "Date d'échéance de la validation",
  preliminaryRequestReference: "Référence de la demande",
  preliminaryRequestAcknowledgedDate: "Date de réception de accusé",
  preliminaryRequestShouldSendReminderMail: "Envoyer mail J+5 ?",
  preliminaryRequestAcknowledgmentPath: "Accusé de reception",
  preliminaryRequestABFAcknowledgmentPath: "Accusé de reception ABF",
  preliminaryRequestAcknowledgmentExtraPaths: "Documents supplémentaires",
  preliminaryRequestApprovalDate: "Date de validation",
  preliminaryRequestApprovalPaths: "Autorisation",
  preliminaryRequestRequestedChangesPaths: "Demande de pièces complémentaires",
  preliminaryRequestRequestedChangesReasons: "Raisons incomplétude",
  preliminaryRequestAwaitedStakeholder: "En attente",
  preliminaryRequestRejectionPaths: "Justificatif du rejet",
  preliminaryRequestRequestedChanges: "Changements à cause de la DP",
}

const GREENDEAL_FIELDS_LABELS = {
  greendealNotes: "Notes",
  greendealAcknowledgmentPath: "Récépissé demande",
  greendealReference: "Référence",
  greendealSubmissionDate: "Date de dépôt",
}

const PAYMENT_FIELDS_LABELS = {
  cetelemFinancingRequestPath: "Demande de financement Cetelem",
}

const SUBSIDIES_FIELDS_LABELS = {
  edfOaNotes: "Notes",
  edfOaCertificatePath: "Attestation BTA",
}

const SHARED_FIELDS_LABELS = {
  electricityBillPath: "Facture d'électricité",
  technicalVisitPanelsLayoutPath: "Calepinage visite technique",
  technicalVisitK2ReportsPath: "Rapport K2 / ESDEC visite technique",
  technicalVisitReportsPath: "Compte-rendu VT",
  integrationKitType: "Kit d’intégration",
  installerId: "Installateur chantier",
  technicalVisitInstallerId: "Installateur VT",
  installationEstimatedDuration: "Durée estimée de l'installation",
  installationClientAvailabilityDate:
    "Disponibilité client pour l'installation",
  installationEndDate: "Date fin installation",
  hardwareNotes: "Notes matériel",
  isPLCNeeded: "Besoin de CPL ?",
  operationsOwnerId: "Responsable ops",
  operationsOwner: "Responsable ops",
  opsOwnerPhone: "Numéro de téléphone du responsable Ops",
  phone: "Numéro de téléphone du responsable Ops",
  adminOwnerId: "Responsable admin",
  salesOwnerId: "Responsable sales",
  gender: "Civilité du client",
  technicalVisitClientConclusion: "Conclusion VT - Client",
  technicalVisitInstallerConclusion: "Conclusion VT - Installateur",
  schedulingIssueMainReason: "Raison non planifiable",
  schedulingIssueSubReason: "Sous raison non planifiable",
}

export const PROJECT_FIELDS_LABELS = {
  ...FOLLOW_UP_FIELDS_LABELS,
  ...CONSUEL_FIELDS_LABELS,
  ...ENEDIS_FIELDS_LABELS,
  ...HARDWARE_FIELDS_LABELS,
  ...INSTALLATION_FIELDS_LABELS,
  ...PRELIMINARY_REQUEST_FIELDS_LABELS,
  ...TECHNICAL_VISIT_FIELDS_LABELS,
  ...GREENDEAL_FIELDS_LABELS,
  ...SUBSIDIES_FIELDS_LABELS,
  ...SHARED_FIELDS_LABELS,
  ...PAYMENT_FIELDS_LABELS,
}
