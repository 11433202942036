import _ from "lodash"

import { InverterType } from "@ensol/types/prisma-client"

import { computeInvertersCount } from "@ensol/shared/entities/installations/energy"
import {
  CURRENT_CONNECTIONS,
  CurrentType,
} from "@ensol/shared/material/currentType"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"

type ElectricalSafeGuardInfo = {
  maxPower: number
  acPrice: number
  dcPrice: number
  allowedCurrentType: CurrentType
}

export const OTHER_ELECTRICAL_COMPONENTS_COST = 50

export const ELECTRICAL_SAFE_GUARDS: ElectricalSafeGuardInfo[] = [
  {
    maxPower: 4.5,
    acPrice: 185.71 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 200 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.SINGLE_PHASE,
  },
  {
    maxPower: 6,
    acPrice: 250 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 200 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.SINGLE_PHASE,
  },
  {
    maxPower: 9,
    acPrice: 350 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 200 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
  {
    maxPower: 12,
    acPrice: 600 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 200 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
  {
    maxPower: 15,
    acPrice: 900 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 300 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
  {
    maxPower: 18,
    acPrice: 1000 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 400 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
  {
    maxPower: 24,
    acPrice: 1000 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 500 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
  {
    maxPower: 36,
    acPrice: 1000 + OTHER_ELECTRICAL_COMPONENTS_COST,
    dcPrice: 600 + OTHER_ELECTRICAL_COMPONENTS_COST,
    allowedCurrentType: CurrentType.TRI_PHASE,
  },
]

export const getCompatibleElectricalSafeGuard = (
  panelsCount: number,
  inverterType: InverterType,
  currentType: CurrentType,
): ElectricalSafeGuardInfo & { description: string } => {
  const inverter = getInverter(inverterType)
  const inverterPower =
    inverter.power * computeInvertersCount(inverterType, panelsCount)

  const currentConnection = CURRENT_CONNECTIONS[currentType]

  const electricalSafeGuardsCompatibleWithCurrentType =
    ELECTRICAL_SAFE_GUARDS.filter(
      (safeGuard) => safeGuard.allowedCurrentType === currentType,
    )
  // Rule defined with Jean:
  // If installed capacity is above the max power of the last safe guard, we take the last safe guard
  // A warning will be displayed anyway regarding the single phase installed capacity > 6kWc
  const electricalSafeGuardRange =
    electricalSafeGuardsCompatibleWithCurrentType.find(
      (safeGuard) => inverterPower <= safeGuard.maxPower,
    ) ??
    _.maxBy(
      electricalSafeGuardsCompatibleWithCurrentType,
      (safeGuard) => safeGuard.maxPower,
    )

  if (!electricalSafeGuardRange) {
    throw new Error(
      `No electrical safe guard found for inverter power ${inverterPower} kWc`,
    )
  }

  return {
    ...electricalSafeGuardRange,
    description: `Coffret de protection ${currentConnection.name.toLowerCase()} ${
      inverter.isCentralInverter ? "DC/AC" : "AC"
    }`,
  }
}
