export const HOUSE_EQUIPMENTS = [
  "electric-car",
  "battery",
  "panels",
  "air-air-pump",
  "air-water-pump",
  "heat-pump",
  "washing-machine",
  "charging-point",
  "water-tank",
  "gaz-pump",
  "radiator",
  "air-conditioning",
  "pool",
  "pump",
  "stove",
  "devices",
  "dryer",
  "fridge",
] as const

export type HouseEquipment = (typeof HOUSE_EQUIPMENTS)[number]
