import { MantineStyleProps, Stack, Text } from "@mantine/core"
import { ReactNode } from "react"

type Props = {
  label: string
  color?: string
  children?: ReactNode
} & MantineStyleProps

export const KeyFigure = ({ label, color, children, ...props }: Props) => (
  <Stack gap="8" {...props}>
    <Text size="xs" c={color ?? "dark.4"}>
      {label}
    </Text>
    <Stack mih="24" justify="center">
      {typeof children === "string" ? (
        <Text size="sm" c={color ?? "dark.4"} fw="600" lineClamp={1}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Stack>
  </Stack>
)
