import {
  Box,
  Button,
  Card,
  CloseButton,
  Group,
  Loader,
  Modal,
  SegmentedControl,
  Slider,
  ThemeIcon,
  Stack,
  Switch,
  Text,
  useMantineTheme,
  ActionIcon,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import * as Sentry from "@sentry/react"
import { IconHelp, IconLayoutGridAdd, IconX } from "@tabler/icons-react"
import html2canvas from "html2canvas"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { hasPhotovoltaicInstallation } from "@ensol/shared/entities/installations/characteristics"
import { getFullName } from "@ensol/shared/entities/prospects"
import { PanelType, getPanel } from "@ensol/shared/material/photovoltaic/panels"
import { getLoader } from "@ensol/shared/thirdParties/google/loader"
import {
  TiffGranularity,
  useDrawMap,
} from "@ensol/shared/thirdParties/google/maps"

import { httpClient } from "@ensol/entool/backend/axios"
import { BackButton } from "@ensol/entool/components/BackButton"
import { InfoBox } from "@ensol/entool/components/InfoBox"
import { MapWrapper } from "@ensol/entool/components/MapWrapper"
import { ImageryDateInfo } from "@ensol/entool/pages/PanelsLayout/ImageryDateInfo"
import { useSavePanelsLayoutMutation } from "@ensol/entool/queries/installations"

import { AddPanelsGridForm, AddPanelsGridValues } from "./AddPanelsGridForm"
import { PanelsInfo } from "./PanelsInfo"

function rotatePolygon(polygon: google.maps.Polygon, angle: number) {
  const map = polygon.getMap()
  if (!map) return

  const prj = map.getProjection()
  if (!prj) return

  const bounds = new google.maps.LatLngBounds()
  polygon.getPaths().forEach(function (path) {
    path.getArray().forEach((coord) => bounds.extend(coord))
  })
  const origin = prj.fromLatLngToPoint(bounds.getCenter())!

  const coords = polygon
    .getPaths()
    .getArray()
    .map((path) => {
      return path.getArray().map(function (latLng) {
        const point = prj.fromLatLngToPoint(latLng)!
        const rotatedLatLng = prj.fromPointToLatLng(
          rotatePoint(point, origin, angle),
        )!
        return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() }
      })
    })
  polygon.setPaths(coords)
}

function rotatePoint(
  point: google.maps.Point,
  origin: google.maps.Point,
  angle: number,
) {
  const angleRad = (angle * Math.PI) / 180.0
  return new google.maps.Point(
    Math.cos(angleRad) * (point.x - origin.x) -
      Math.sin(angleRad) * (point.y - origin.y) +
      origin.x,
    Math.sin(angleRad) * (point.x - origin.x) +
      Math.cos(angleRad) * (point.y - origin.y) +
      origin.y,
  )
}

const MONTHS = [
  { value: 0, label: "Jan" },
  { value: 1, label: "Fév" },
  { value: 2, label: "Mar" },
  { value: 3, label: "Avr" },
  { value: 4, label: "Mai" },
  { value: 5, label: "Juin" },
  { value: 6, label: "Juil" },
  { value: 7, label: "Août" },
  { value: 8, label: "Sep" },
  { value: 9, label: "Oct" },
  { value: 10, label: "Nov" },
  { value: 11, label: "Déc" },
]

const FETCH_DATA_LAYERS_CONFIG = {
  httpClient,
  radiusMeters: 50,
}

type Props = {
  installation: InstallationsResponses.Installation
}

export const PanelsLayout = ({ installation }: Props) => {
  const { colors } = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isHeatmapShown, setIsHeatmapShown] = useState(false)
  const [withOrthorectifiedMap, setWithOrthorectifiedMap] = useState<boolean>(
    installation.panelsLayout
      ? installation.panelsLayout.withOrthorectifiedMap
      : true,
  )
  const [heatmapGranularity, setHeatmapGranularity] =
    useState<TiffGranularity>("annualFlux")
  const [heatmapMonth, setHeatmapMonth] = useState<number>(0)

  // TODO: can we have a rotation state for each panel?
  const rotation = useRef(0)

  const coords = useMemo(
    () => getCoords(installation.house),
    [installation.house],
  )

  const [panelsCount, setPanelsCount] = useState(0)
  const [selectedPanel, setSelectedPanel] = useState<google.maps.Polygon>()

  const {
    panelsGrids,
    onDrawPanelsGrid,
    onDrawSunshineRasters,
    isMapLoaded,
    areSunshineRastersReady,
    areSunshineRastersNotAvailable,
    rastersImageryDate,
  } = useDrawMap({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    panelsLayoutPaths: installation.panelsLayout?.paths,
    onClickMap: () => handleSelectPanel(undefined),
    withControls: true,
    hidePin: true,
    zoomLevel: 21,
    minZoomLevel: 20,
    coords,
    withOrthorectifiedMap,
    fetchDataLayersConfig: FETCH_DATA_LAYERS_CONFIG,
  })

  const { mutateAsync: saveLayout } = useSavePanelsLayoutMutation(
    installation.id,
  )

  const handleSelectPanel = useCallback(
    (panel: google.maps.Polygon | undefined) => {
      setSelectedPanel(panel)

      panelsGrids.map((p) => p.setValues({ fillColor: "#000000" }))

      if (panel) {
        panel.setValues({ fillColor: colors.blue[5] })
      }
    },
    [colors.blue, panelsGrids],
  )

  const handleRotatePanel = useCallback(
    (panel: google.maps.Polygon, value: number) => {
      const angle = value - rotation.current
      rotatePolygon(panel, angle)
      rotation.current = value
    },
    [],
  )

  const handleDeletePanel = useCallback(
    (panel: google.maps.Polygon) => {
      const deletedPanelsCount = panel.getPaths().getArray().length
      panelsGrids.splice(panelsGrids.indexOf(panel), 1)
      setPanelsCount((count) => count - deletedPanelsCount)
      setSelectedPanel(undefined)
      panel.setMap(null)
    },
    [panelsGrids],
  )

  const handleAddPanelInteractions = useCallback(
    (polygon: google.maps.Polygon) => {
      setPanelsCount((count) => count + polygon.getPaths().getLength())

      polygon.addListener("click", () => {
        handleSelectPanel(polygon)
      })
    },
    [handleSelectPanel],
  )

  useEffect(() => {
    if (isMapLoaded && panelsCount === 0) {
      panelsGrids.map(handleAddPanelInteractions)
    }
  }, [handleAddPanelInteractions, isMapLoaded, panelsCount, panelsGrids])

  useEffect(() => {
    onDrawSunshineRasters(isHeatmapShown, heatmapGranularity, heatmapMonth)
  }, [heatmapGranularity, heatmapMonth, isHeatmapShown, onDrawSunshineRasters])

  const handleAddPanel = useCallback(
    async (panelsConfig: AddPanelsGridValues, panelType: PanelType) => {
      close()
      const loader = getLoader(import.meta.env.VITE_GOOGLE_API_KEY)
      const { spherical } = await loader.importLibrary("geometry")

      const panel = getPanel(panelType)
      const width =
        (panelsConfig.orientation === "portrait"
          ? panel.widthInMillimeters
          : panel.lengthInMillimeters) / 1000
      const height =
        (panelsConfig.orientation === "portrait"
          ? panel.lengthInMillimeters
          : panel.widthInMillimeters) / 1000

      const nw = coords
      // If you want to draw a inclined panel, simply subtract the inclination angle
      const ne = spherical.computeOffset(nw, width, -90)
      const se = spherical.computeOffset(ne, height, 180)
      const sw = spherical.computeOffset(se, width, 90)

      const basePolygon = [ne, nw, sw, se]
      // Same here, subtraction of the inclination angle
      const polygons = _.times(panelsConfig.columns).flatMap((i) => {
        const colPolygon = basePolygon.map((point) =>
          spherical.computeOffset(point, width * i, 90),
        )

        return _.times(panelsConfig.rows, (j) =>
          colPolygon.map((point) =>
            spherical.computeOffset(point, height * j, 180),
          ),
        )
      })

      const panelsGrid = await onDrawPanelsGrid(polygons)
      handleAddPanelInteractions(panelsGrid)
    },
    [close, coords, handleAddPanelInteractions, onDrawPanelsGrid],
  )

  const handleSaveLayout = async () => {
    try {
      setIsSaving(true)
      handleSelectPanel(undefined)
      if (!isHeatmapShown || heatmapGranularity !== "annualFlux") {
        setIsHeatmapShown(true)
        setHeatmapGranularity("annualFlux")
        // Wait 1s to let the heatmap be drawn
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
      // Take a screenshot of the current layout
      const mapDiv = document.getElementById("map")!
      const canvas = await html2canvas(mapDiv, {
        useCORS: true,
        logging: true,
        imageTimeout: 60000,
        ignoreElements: (element) => element.className === "gmnoprint",
        scale: 1,
      })
      const screenshotBase64 = canvas.toDataURL("image/png")

      // Save the layout
      await saveLayout({
        panelsGrids,
        screenshotBase64,
        withOrthorectifiedMap,
      })
    } catch (error) {
      console.error(error)
      Sentry.captureException(error, {
        tags: {
          installationId: installation.id,
        },
      })
    } finally {
      setIsSaving(false)
    }
  }

  if (!hasPhotovoltaicInstallation(installation)) {
    return (
      <InfoBox
        color="red"
        title="L'installation ne comporte pas de panneaux photovoltaïques"
        message="Le calepinage n'est disponible que pour ce type d'installation"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {getFullName(installation.prospect)} - Calepinage {installation.name}{" "}
          | Entool
        </title>
      </Helmet>
      <Stack align="flex-start" w="100%" h="100%">
        <BackButton to={`/installations/${installation.id}`}>
          Retour à la simulation
        </BackButton>
        <Box w="100%" h="calc(100vh - 164px)" pos="relative">
          <Modal opened={opened} onClose={close} title="Ajouter des panneaux">
            <AddPanelsGridForm
              panelType={installation.photovoltaicInstallation.panelType}
              onAddPanel={handleAddPanel}
            />
          </Modal>
          <Box pos="absolute" top={16} left={16} style={{ zIndex: 1 }}>
            <PanelsInfo
              panelType={installation.photovoltaicInstallation.panelType}
              installedPanelsCount={panelsCount} // TODO: is there any calepinage for upsells ?
              totalPanelsCount={
                installation.photovoltaicInstallation.panelsCount
              }
            />
          </Box>
          <Box pos="absolute" top={16} left={242} style={{ zIndex: 1 }}>
            <Group>
              <Box bg="white" p={8} style={{ borderRadius: 8 }}>
                {areSunshineRastersNotAvailable ? (
                  <Group gap={2}>
                    <ThemeIcon c="orange" size="xs">
                      <IconX />
                    </ThemeIcon>
                    <Text c="orange" fz="sm">
                      Ensoleillement indisponible
                    </Text>
                  </Group>
                ) : !areSunshineRastersReady ? (
                  <Group gap={6}>
                    <Loader size={14} />
                    <Text c="gray" fz="sm">
                      Chargement de l&apos;ensoleillement...
                    </Text>
                  </Group>
                ) : (
                  <Group gap={6}>
                    <Switch
                      checked={isHeatmapShown}
                      onChange={() => setIsHeatmapShown((shown) => !shown)}
                      label="Afficher l'ensoleillement"
                    />
                    <ImageryDateInfo solarImageryDate={rastersImageryDate} />
                  </Group>
                )}
              </Box>
              <Box bg="white" p={8} style={{ borderRadius: 8 }}>
                <Group gap={6}>
                  <Switch
                    checked={withOrthorectifiedMap}
                    onChange={() => {
                      setWithOrthorectifiedMap((prevState) => !prevState)
                      setIsHeatmapShown(false)
                    }}
                    label="Afficher la vue rectifiée"
                  />
                  <Tooltip
                    label="La vue rectifiée correspond à la vue utilisée pour les maps d'ensoleillement. Elle permet de s'assurer de l'alignement des panneaux avec l'ensoleillement et doit être utilisée si possible."
                    multiline
                    w={400}
                  >
                    <ActionIcon color="gray" style={{ borderRadius: 24 }}>
                      <IconHelp />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Box>
            </Group>
          </Box>
          {isHeatmapShown && (
            <Stack
              w={600}
              align="center"
              pos="absolute"
              bottom={24}
              left="50%"
              style={{ zIndex: 1, transform: "translateX(-50%)" }}
            >
              <Group
                align="flex-start"
                bg="white"
                pt={12}
                pb={12}
                pl={12}
                pr={20}
                style={{ borderRadius: 8 }}
              >
                <SegmentedControl
                  value={heatmapGranularity}
                  onChange={(granularity) =>
                    setHeatmapGranularity(granularity as TiffGranularity)
                  }
                  data={[
                    { label: "Annuel", value: "annualFlux" },
                    { label: "Mensuel", value: "monthlyFlux" },
                  ]}
                />
                <Slider
                  mt={2}
                  min={0}
                  max={11}
                  w={400}
                  label={null}
                  marks={MONTHS}
                  value={heatmapMonth}
                  disabled={heatmapGranularity === "annualFlux"}
                  onChange={setHeatmapMonth}
                />
              </Group>
            </Stack>
          )}
          <Box pos="absolute" top={16} right={16} style={{ zIndex: 1 }}>
            <Group>
              <Button
                bg="white"
                variant="outline"
                onClick={open}
                disabled={isSaving}
                leftSection={<IconLayoutGridAdd />}
              >
                Ajouter des panneaux
              </Button>
              <Button
                disabled={!panelsGrids.length}
                onClick={handleSaveLayout}
                loading={isSaving}
              >
                Enregistrer
              </Button>
            </Group>
          </Box>
          {selectedPanel && (
            <Box pos="absolute" bottom={16} left={16} style={{ zIndex: 1 }}>
              <Card shadow="xs" radius="md" w={230}>
                <Group
                  w="100%"
                  pos="absolute"
                  top={0}
                  left={0}
                  pt={12}
                  pl={16}
                  pr={8}
                  align="flex-start"
                  justify="space-between"
                >
                  <Text>Panneau sélectionné</Text>
                  <CloseButton onClick={() => handleSelectPanel(undefined)} />
                </Group>
                <Stack mt={48}>
                  <Slider
                    min={0}
                    max={360}
                    onChange={(value) =>
                      handleRotatePanel(selectedPanel, value)
                    }
                  />
                  <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    onClick={() => handleDeletePanel(selectedPanel)}
                  >
                    Supprimer
                  </Button>
                </Stack>
              </Card>
            </Box>
          )}
          <MapWrapper
            mapId="map"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 16,
            }}
            isLoading={!isMapLoaded}
          />
        </Box>
      </Stack>
    </>
  )
}
