import _ from "lodash"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { ResidentialStatus as PrismaResidentialStatus } from "@ensol/types/prisma"
import { Nullable } from "@ensol/types/utils"

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export const GENDERS = {
  [Gender.FEMALE]: { long: "Madame", short: "Mme." },
  [Gender.MALE]: { long: "Monsieur", short: "M." },
}

export enum ResidentialStatus {
  OWNER = "OWNER",
  RENTER = "RENTER",
}

export const isInEligibleArea = (postcode: string) =>
  ["06", "13", "83", "84", "30"].includes(postcode.slice(0, 2))

export const isEligible = (
  residentialStatus: PrismaResidentialStatus,
  postcode: string,
) => isInEligibleArea(postcode) && residentialStatus === ResidentialStatus.OWNER

export const getDefaultUniquePhone = () =>
  `+1${_.random(1000000000, 9999999999)}`

export const getDefaultUniqueEmail = () =>
  `client-${Date.now()}@ensol-offline.com`

export const getFullName = (
  prospect: Nullable<
    Pick<ProspectResponses.Prospect, "firstName" | "lastName">
  >,
) => `${prospect.firstName} ${prospect.lastName}`

export const getFormalName = (
  prospect: Nullable<
    Pick<ProspectResponses.Prospect, "firstName" | "lastName" | "gender">
  >,
) =>
  `${prospect.gender ? GENDERS[prospect.gender].short : ""} ${getFullName(prospect)}`

export const REFERRAL_FORM_URL = "https://form.goensol.com/partager"
