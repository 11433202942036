import { Container, Stack, Loader } from "@mantine/core"
import { useLocation, Outlet, Navigate } from "react-router-dom"

import { Team } from "@ensol/shared/entities/users"

import { useAuthenticatedUser } from "@ensol/entool/queries/users"

const TEAM_REDIRECT_CONFIG: Record<Team, string> = {
  [Team.INSIDE_SALES]: "/prospects",
  [Team.ACCOUNT_EXECUTIVE]: "/sales",
  [Team.ADMIN]: "/projects/processes/preliminaryRequestStep",
  [Team.CUSTOMER_SUCCESS]: "/projects",
  [Team.OPERATIONS]: "/projects",
  [Team.SITE_MANAGER]: "/projects",
  [Team.TECHNICAL_EXPERT]: "/projects",
}

export const DefaultTeamRedirect = () => {
  const location = useLocation()
  const { data: user, isPending } = useAuthenticatedUser()

  if (isPending) {
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )
  }

  if (location.pathname === "/" && user) {
    const [firstTeam] = user.teams
    const redirectPath = firstTeam ? TEAM_REDIRECT_CONFIG[firstTeam] : null

    if (redirectPath) {
      return <Navigate to={redirectPath} replace />
    }
  }

  return <Outlet />
}
