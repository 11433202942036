import { useUser } from "@clerk/clerk-react"
import { AppShell as BaseAppShell } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import * as Sentry from "@sentry/react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"

import { Navbar } from "@ensol/entool/components/layout/Navbar"
import { MOBILE_NAVBAR_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

export const AppShell = () => {
  const isSmallScreen = useMediaQuery(devices.md)
  const { isLoaded, isSignedIn, user } = useUser()

  const [desktopOpened, setDesktopOpened] = useLocalStorage(
    "desktopOpened",
    true,
  )

  const toggleDesktop = () => setDesktopOpened((prev) => !prev)

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      Sentry.setUser({
        id: user.id,
        email: user.emailAddresses[0].emailAddress,
      })
    }
  }, [isLoaded, isSignedIn, user])

  return (
    <BaseAppShell
      transitionDuration={300}
      header={{ height: MOBILE_NAVBAR_HEIGHT_IN_PX, collapsed: !isSmallScreen }}
      navbar={{
        width: desktopOpened ? 206 : 80,

        breakpoint: "md",
        collapsed: { mobile: true },
      }}
      padding="0"
    >
      <Navbar desktopOpened={desktopOpened} toggleDesktop={toggleDesktop} />
      <BaseAppShell.Main display="flex" mah="100dvh">
        <Outlet />
      </BaseAppShell.Main>
    </BaseAppShell>
  )
}
