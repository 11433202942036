import * as Sentry from "@sentry/react"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"

const dateRegex = /^\d{4}-\d{2}-\d{2}$/

const publicHolidaysSchema = z.record(
  z.string().regex(dateRegex, "Invalid date format (YYYY-MM-DD)"),
  z.string(),
)

export type PublicHolidaysEvents = z.infer<typeof publicHolidaysSchema>

export const useGetPublicHolidaysQuery = (enableQuery: boolean) => {
  return useQuery<PublicHolidaysEvents>({
    queryKey: ["publicHolidays"],
    queryFn: async () => {
      try {
        const response = await axios.get<PublicHolidaysEvents>(
          "https://calendrier.api.gouv.fr/jours-feries/metropole.json",
        )
        const parsedPublicHolidays = publicHolidaysSchema.parse(response.data)
        return parsedPublicHolidays
      } catch (error) {
        Sentry.captureException(
          new Error("Could not get public holidays calendrier.api.gouv.fr"),
          {
            extra: { error },
          },
        )
        return {}
      }
    },
    enabled: enableQuery,
  })
}
