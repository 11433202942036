import { ActionIcon, Anchor, Group, Popover } from "@mantine/core"
import { useDisclosure, useClickOutside } from "@mantine/hooks"
import {
  IconExternalLink,
  IconMailFilled,
  IconMapPinFilled,
  IconPhoneFilled,
  TablerIcon,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { IconButton } from "@ensol/entool/components/IconButton"
import { PopoverContent } from "@ensol/entool/components/PopoverContent"

type Props = {
  project: ProjectResponses.ProjectOverview
}

export const ClientInfo = ({ project }: Props) => {
  const { prospect, house } = project

  return (
    <Group justify="space-between" w="fit-content" gap="4" flex={2}>
      <Anchor
        component={Link}
        to={`/projects/${project.id}`}
        size="md"
        fw="500"
        c="black"
      >
        {getFullName(prospect)}
      </Anchor>
      <Group gap={4}>
        <InfoIconPopover
          Icon={IconPhoneFilled}
          name="Téléphone"
          value={formatPhoneNum(prospect.phone)}
        />
        <InfoIconPopover
          Icon={IconMailFilled}
          name="Email"
          value={prospect.email}
        />
        <InfoIconPopover
          Icon={IconMapPinFilled}
          name="Adresse"
          value={house.address}
          Link={
            <IconButton
              Icon={<IconExternalLink size={18} />}
              href={`https://maps.google.com/?q=${house.address}`}
              ariaLabel="Ouvrir l'adresse dans Google Maps"
              isExternal
            />
          }
        />
      </Group>
    </Group>
  )
}

type InfoIconPopoverProps = {
  Icon: TablerIcon
  name: string
  value: string
  Link?: React.ReactNode
}

const InfoIconPopover = ({ Icon, name, value, Link }: InfoIconPopoverProps) => {
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useClickOutside(close)

  return (
    <Popover withArrow position="top" offset={0} opened={opened}>
      <Popover.Target>
        <ActionIcon onClick={open} c={opened ? "blue.4" : "gray.2"}>
          <Icon />
        </ActionIcon>
      </Popover.Target>
      <PopoverContent ref={ref} title={name}>
        <Group gap="8">
          <CopiableText Icon={Icon} text={value} />
          {Link}
        </Group>
      </PopoverContent>
    </Popover>
  )
}
