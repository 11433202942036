import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import _ from "lodash"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { MAX_BUNDLE_SIZE } from "@ensol/shared/entities/installations/bundle"
import { QuoteStatus } from "@ensol/shared/entities/quotes"

import { InstallationsProps } from "./Installations"

export const useInstallations = ({
  houseId,
  installations,
}: InstallationsProps) => {
  const navigate = useNavigate()

  const bundleSize = useMemo(() => {
    return _.sum(
      installations.map((installation) => (installation.bundled ? 1 : 0)),
    )
  }, [installations])

  const hasSignedQuote = installations.some(
    ({ activeQuote }) => activeQuote?.status === QuoteStatus.SIGNED,
  )

  const createNewSimulation = useCallback(
    () => navigate(`/installations/new/${houseId}`),
    [navigate, houseId],
  )

  return {
    createNewSimulation: () =>
      hasSignedQuote
        ? modals.openConfirmModal({
            title: "Cette maison a déjà un devis signé",
            children: (
              <Text size="sm">
                Si vous voulez mettre à jour un devis, mettez à jour la même
                simulation et générez un nouveau devis.
                <br />
                Si vous voulez créer cette simulation pour une autre vente vous
                pouvez confirmer.
              </Text>
            ),
            centered: true,
            labels: { confirm: "Confirmer", cancel: "Annuler" },
            onConfirm: createNewSimulation,
          })
        : createNewSimulation(),
    goToSimulation: (installationId: string) =>
      navigate(`/installations/${installationId}`),
    canBundle: bundleSize < MAX_BUNDLE_SIZE,
  }
}
