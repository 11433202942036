import {
  EV_CHARGER_SUBSIDIES,
  EvChargerSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/evCharger"
import {
  PHOTOVOLTAIC_SUBSIDIES,
  PhotovoltaicSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/photovoltaic"

export type SubsidyType = PhotovoltaicSubsidyType | EvChargerSubsidyType

export type Subsidy<Type extends SubsidyType = SubsidyType> = {
  subsidyType: Type
  subsidyAmount: number
}

const SUBSIDIES = { ...PHOTOVOLTAIC_SUBSIDIES, ...EV_CHARGER_SUBSIDIES }

export const getSubsidy = <Type extends SubsidyType = SubsidyType>(
  subsidyType: Type,
) => SUBSIDIES[subsidyType]
