import { SimpleGrid, Stack, Image, Text } from "@mantine/core"
import _ from "lodash"
import { useCallback } from "react"

import { HouseEquipment } from "@ensol/shared/entities/houses/equipments"

import { RadioButton } from "@ensol/entool/components/form/RadioGroup"
import { Option } from "@ensol/entool/utils/form/radio"

import airConditioning from "./assets/airConditioning.svg"
import airPump from "./assets/airPump.svg"
import battery from "./assets/battery.svg"
import car from "./assets/car.svg"
import chargingPoint from "./assets/chargingPoint.svg"
import devices from "./assets/devices.svg"
import dryer from "./assets/dryer.svg"
import fridge from "./assets/fridge.svg"
import gazPump from "./assets/gazPump.svg"
import heatPump from "./assets/heatPump.svg"
import panel from "./assets/panel.svg"
import pool from "./assets/pool.svg"
import pump from "./assets/pump.svg"
import radiator from "./assets/radiator.svg"
import stove from "./assets/stove.svg"
import washingMachine from "./assets/washingMachine.svg"
import waterPump from "./assets/waterPump.svg"
import waterTank from "./assets/waterTank.svg"

type Props = {
  value?: HouseEquipment[]
  onChange: (value: HouseEquipment[]) => void
}

const HOUSE_EQUIPMENTS_OPTIONS: {
  value: HouseEquipment
  label: string
  icon: string
}[] = [
  {
    value: "electric-car",
    label: "Voiture électrique",
    icon: car,
  },
  {
    value: "battery",
    label: "Ballon électrique",
    icon: waterTank,
  },
  {
    value: "panels",
    label: "Radiateur électrique",
    icon: radiator,
  },
  { value: "air-air-pump", label: "PAC air-air", icon: airPump },
  {
    value: "air-water-pump",
    label: "PAC air-eau",
    icon: waterPump,
  },
  {
    value: "heat-pump",
    label: "Air conditionné",
    icon: airConditioning,
  },
  { value: "washing-machine", label: "Piscine", icon: pool },
  { value: "charging-point", label: "Batterie", icon: battery },
  { value: "water-tank", label: "PV", icon: panel },
  { value: "gaz-pump", label: "Pompe à chaleur", icon: heatPump },
  {
    value: "radiator",
    label: "Machine à laver",
    icon: washingMachine,
  },
  {
    value: "air-conditioning",
    label: "Borne de recharge",
    icon: chargingPoint,
  },
  { value: "pool", label: "Chaudière à gaz", icon: gazPump },
  { value: "pump", label: "Pompe de tirage", icon: pump },
  { value: "stove", label: "Plaque de cuisson", icon: stove },
  {
    value: "devices",
    label: "Multimédia-informatique",
    icon: devices,
  },
  { value: "dryer", label: "Sèche linge", icon: dryer },
  { value: "fridge", label: "Frigo", icon: fridge },
]

export const HouseEquipments = ({ value, onChange }: Props) => {
  const handleChange = useCallback(
    (option: Option<HouseEquipment>) => {
      if (value === undefined) {
        return onChange([option.value])
      }
      if (value.includes(option.value)) {
        return onChange(
          value.filter((optionValue) => optionValue !== option.value),
        )
      }

      return onChange([...value, option.value])
    },
    [value, onChange],
  )
  return (
    <SimpleGrid cols={{ base: 3, md: 6 }} spacing="16">
      {HOUSE_EQUIPMENTS_OPTIONS.map((option) => (
        <RadioButton
          key={option.value.toString()}
          dataTest={_.camelCase(option.value.toString())}
          label={
            <Stack
              p={{ base: 0, md: "8 12" }}
              flex="1"
              h={{ base: "90", md: "110" }}
              align="center"
              justify="center"
              gap="8"
            >
              <Image
                src={option.icon}
                alt={option.label}
                w={{ base: 35, md: 40 }}
              />
              <Text
                size="sm"
                c="blue.5"
                fw={600}
                style={{ textAlign: "center" }}
              >
                {option.label}
              </Text>
            </Stack>
          }
          isSelected={value !== undefined && value.includes(option.value)}
          isDisabled={false}
          onClick={() => handleChange(option)}
        />
      ))}
    </SimpleGrid>
  )
}
