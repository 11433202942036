import { useMemo } from "react"

import { typedKeys } from "@ensol/types/utils"

import { checkInverterCompatibility } from "@ensol/shared/entities/installations/energy"
import { CurrentType } from "@ensol/shared/material/currentType"
import {
  getInverter,
  INVERTERS,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import {
  OPTIMIZERS,
  OptimizerType,
} from "@ensol/shared/material/photovoltaic/optimizers"
import {
  getPanel,
  PANELS,
  PanelType,
} from "@ensol/shared/material/photovoltaic/panels"

import { Option } from "@ensol/entool/utils/form/radio"

const PANEL_OPTIONS: Option<PanelType>[] = typedKeys(PANELS).map(
  (panelType) => {
    const { name, disabled } = PANELS[panelType]

    return { label: name, value: panelType, disabled }
  },
)

const INVERTER_OPTIONS: Option<InverterType>[] = typedKeys(INVERTERS).map(
  (inverterType) => {
    const { name } = INVERTERS[inverterType]

    return {
      label: name,
      value: inverterType,
    }
  },
)

const OPTIMIZER_OPTIONS: Option<OptimizerType>[] = typedKeys(OPTIMIZERS).map(
  (optimizerType) => {
    const { name } = OPTIMIZERS[optimizerType]

    return {
      label: name,
      value: optimizerType,
    }
  },
)

type PhotovoltaicMaterialOptionsInput = {
  installedCapacity: number
  panelsCount: number
  panelType: PanelType
  inverterType: InverterType
  currentType: CurrentType
  optimizerType?: OptimizerType
}

type PhotovoltaicMaterialOptions = {
  panelsOptions: Option<PanelType>[]
  invertersOptions: Option<InverterType, { recommended: boolean }>[]
  optimizerOptions: Option<OptimizerType, { irrelevant: boolean }>[]
  minOptimizerCount: number
}

export const usePhotovoltaicMaterialOptions = ({
  installedCapacity,
  panelsCount,
  panelType,
  inverterType,
  currentType,
  optimizerType,
}: PhotovoltaicMaterialOptionsInput): PhotovoltaicMaterialOptions => {
  const panelsOptions = useMemo(() => {
    return PANEL_OPTIONS.map((panel) => ({
      ...panel,
      subtitle: panel.disabled && "Ce panneau n'est plus disponible",
    })).filter((panel) => !panel.disabled || panel.value === panelType)
  }, [panelType])

  const invertersOptions = useMemo(() => {
    return INVERTER_OPTIONS.map((inverter) => {
      const { DCACPercent, isRecommended, isCompatible } =
        panelsCount && panelType
          ? checkInverterCompatibility({
              panelsCount,
              installedCapacity,
              inverterType: inverter.value,
              currentType,
            })
          : {
              DCACPercent: undefined,
              isRecommended: false,
              isCompatible: false,
            }

      return {
        ...inverter,
        subtitle: DCACPercent ? `Rapport DC/AC: ${DCACPercent}%` : "",
        disabled: !isCompatible,
        metadata: {
          recommended: isRecommended,
        },
      }
    }).filter(
      (inverter) => !inverter.disabled || inverter.value === inverterType,
    )
  }, [currentType, installedCapacity, inverterType, panelType, panelsCount])

  const optimizerOptions = useMemo(() => {
    return OPTIMIZER_OPTIONS.map((optimizer) => {
      const inverter = getInverter(inverterType)
      const isNotCentralDisabled = !inverter.isCentralInverter
      const isPowerTooLowDisabled =
        OPTIMIZERS[optimizer.value].power < getPanel(panelType).power

      return {
        ...optimizer,
        metadata: { irrelevant: isNotCentralDisabled },
        disabled: isNotCentralDisabled || isPowerTooLowDisabled,
        subtitle: isNotCentralDisabled
          ? "Utilisable avec onduleur central uniquement"
          : isPowerTooLowDisabled
            ? "La puissance de l'optimiseur est insuffisante"
            : "",
      }
    }).filter(
      (optimizer) => !optimizer.disabled || optimizer.value === optimizerType,
    )
  }, [inverterType, optimizerType, panelType])

  return {
    panelsOptions,
    invertersOptions,
    optimizerOptions,
    minOptimizerCount: currentType === CurrentType.SINGLE_PHASE ? 4 : 6,
  }
}
