import { Divider, Stack } from "@mantine/core"
import { IconBolt, IconReceipt2 } from "@tabler/icons-react"

import { SimulationResults } from "@ensol/types/simulation"

import {
  formatCurrency,
  formatMonthsCount,
  formatNumber,
} from "@ensol/shared/utils/format"

import {
  SummaryItem,
  SummaryTitle,
} from "@ensol/entool/pages/Installation/Summary/shared"

type Props = {
  simulationResult: SimulationResults
  installedPanelsCount: number
  panelsCount: number
}

export const SimulationSummary = ({
  simulationResult,
  panelsCount,
  installedPanelsCount,
}: Props) => {
  const {
    firstYearMonthlyEnergySavings: {
      autoConsumedAmount,
      resellAmount,
      autoConsumptionSavingsPartPercent,
      resellSavingsPartPercent,
    },
  } = simulationResult

  const breakEventDate = formatMonthsCount(
    simulationResult.monthsBeforeBreakEvenDate,
  )

  return (
    <Stack>
      <SummaryTitle title="Energie" icon={IconBolt} />
      <Stack mb={12} gap={6}>
        <SummaryItem
          title="Puissance"
          value={`${formatNumber(simulationResult.installedCapacity)} kWc`}
          warning={simulationResult.warnings.installedCapacity}
        />
        <SummaryItem
          title="Panneaux"
          value={
            installedPanelsCount > 0
              ? `${installedPanelsCount} (+${panelsCount})`
              : panelsCount
          }
        />
        <Divider my={4} />
        <SummaryItem
          title="Production"
          value={`${formatNumber(
            simulationResult.estimatedYearlyProduction,
          )} kWh`}
        />
        <SummaryItem
          title="Consommation"
          value={`${formatNumber(simulationResult.yearlyConsumption)} kWh`}
        />
        <SummaryItem
          title="Taux d'autoconsommation"
          value={`${simulationResult.autoConsumptionPercent}%`}
        />
      </Stack>
      <SummaryTitle title="Economies" icon={IconReceipt2} />
      <Stack mb={12} gap={6}>
        <SummaryItem
          title="Réduction facture"
          value={`${Math.round(simulationResult.billReductionRatio * 100)}%`}
        />
        <Divider my={4} />
        <SummaryItem
          title="Economies (1 an)"
          value={formatCurrency(simulationResult.oneYearSavings, 0)}
        />
        <SummaryItem
          size="xs"
          title="Autoconsommation"
          value={`${formatCurrency(
            12 * autoConsumedAmount,
            0,
          )} (${autoConsumptionSavingsPartPercent}%)`}
        />
        <SummaryItem
          size="xs"
          title="Revente"
          value={`${formatCurrency(
            12 * resellAmount,
            0,
          )} (${resellSavingsPartPercent}%)`}
        />
        <Divider my={4} />
        <SummaryItem
          title="Economies (10 ans)"
          value={formatCurrency(simulationResult.tenYearsSavings, 0)}
        />
        <SummaryItem
          title="Economies (30 ans)"
          value={formatCurrency(simulationResult.thirtyYearsSavings, 0)}
        />
        <Divider my={4} />
        <SummaryItem title="Seuil de rentabilité" value={breakEventDate} />
      </Stack>
    </Stack>
  )
}
