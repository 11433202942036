import * as Sentry from "@sentry/react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import { Role } from "@ensol/shared/entities/users"

import { AppShell } from "@ensol/entool/components/layout/AppShell"
import { Home } from "@ensol/entool/pages/Home"
import { OpsProjectsAlerts } from "@ensol/entool/pages/OpsProjectsAlerts"
import { PanelsLayoutPage } from "@ensol/entool/pages/PanelsLayout"
import { ProjectFeedbackPage } from "@ensol/entool/pages/ProjectFeedback"
import { ProjectsEventsPage } from "@ensol/entool/pages/ProjectsEvents"
import { ProspectsPage } from "@ensol/entool/pages/Prospects"
import { RoleGuard } from "@ensol/entool/pages/RoleGuard"
import { SalesTasksPage } from "@ensol/entool/pages/SalesTasks"
import { SalesVisitPage } from "@ensol/entool/pages/SalesVisit"

import { AuthGuard } from "./AuthGuard"
import { AuthProvider } from "./AuthProvider"
import { DefaultTeamRedirect } from "./DefaultTeamRedirect"
import { InstallationPage, NewInstallationPage } from "./Installation"
import { LegacyDomainRedirect } from "./LegacyDomainRedirect"
import { NewProspectPage } from "./NewProspect"
import { OpsProjectPage } from "./OpsProject"
import { OpsProjectsByProcess } from "./OpsProjectsByProcess"
import { OpsProjectsOverview } from "./OpsProjectsOverview"
import { ProspectPage } from "./Prospect"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<LegacyDomainRedirect />}>
      <Route element={<AuthProvider />}>
        <Route element={<AppShell />}>
          <Route element={<AuthGuard />}>
            <Route element={<RoleGuard roles={[Role.EMPLOYEE]} />}>
              <Route element={<DefaultTeamRedirect />}>
                <Route index element={<Home />} />
                <Route path="/prospects">
                  <Route index element={<ProspectsPage />} />
                  <Route path="new" element={<NewProspectPage />} />
                  <Route path=":prospectId">
                    <Route index element={<ProspectPage />} />
                  </Route>
                </Route>
                <Route path="/installations">
                  <Route
                    path="new/:houseId"
                    element={<NewInstallationPage />}
                  />
                  <Route path=":installationId">
                    <Route index element={<InstallationPage />} />
                    <Route
                      path="panels-layout"
                      element={<PanelsLayoutPage />}
                    />
                  </Route>
                </Route>
                <Route path="/projects">
                  <Route index element={<OpsProjectsOverview />} />
                  <Route path="alerts" element={<OpsProjectsAlerts />} />
                  <Route
                    path="processes/:processId"
                    element={<OpsProjectsByProcess />}
                  />
                  <Route path=":projectId" element={<OpsProjectPage />} />
                </Route>
                <Route path="/events">
                  <Route index element={<ProjectsEventsPage />} />
                </Route>
                <Route path="/sales">
                  <Route index element={<SalesTasksPage />} />
                  <Route path=":projectId" element={<SalesVisitPage />} />
                </Route>
                <Route path="/feedbacks">
                  <Route path=":projectId" element={<ProjectFeedbackPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          {/* This route is an unauthenticated one used by Cypress tests to programmatically login*/}
          <Route path="/cypress/sign-in" />
        </Route>
      </Route>
    </Route>,
  ),
)
