import { ActionIcon } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"

import { useDownloadFileMutation } from "@ensol/entool/queries/files"

type Props = {
  filePath: string
  fileName: string
  type?: string
}

export const DownloadFileButton = ({ filePath, fileName, type }: Props) => {
  const { mutateAsync: downloadFile, isPending: isDownloading } =
    useDownloadFileMutation(filePath, `${fileName}.${type}`)

  return (
    <ActionIcon
      aria-label="Télécharger"
      loading={isDownloading}
      onClick={() => downloadFile()}
    >
      <IconDownload />
    </ActionIcon>
  )
}
