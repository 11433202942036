import { Helmet } from "react-helmet-async"

import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { SalesTasks } from "@ensol/entool/pages/SalesTasks/SalesTasks"

export const SalesTasksPage = () => (
  <PageLayout title="Suivi AE" contentContainerProps={{ px: 0 }}>
    <Helmet>
      <title>Suivi AE | Entool</title>
    </Helmet>
    <SalesTasks />
  </PageLayout>
)
