import { ButtonProps, MantineStyleProps } from "@mantine/core"

import hubspotLogo from "@ensol/entool/assets/hubspotLogo.webp"
import { Link } from "@ensol/entool/components/Link"
import { getHubspotProspectLink } from "@ensol/entool/utils/hubspot"

type Props = {
  hubspotContactId: string | null
  emptyContent?: React.ReactNode | string
} & MantineStyleProps &
  ButtonProps

export const HubspotLink = ({
  hubspotContactId,
  emptyContent = null,
  ...props
}: Props) => {
  if (hubspotContactId === null || hubspotContactId === undefined) {
    return emptyContent
  }

  return (
    <Link
      label="Contact Hubspot"
      href={getHubspotProspectLink(hubspotContactId)}
      color="hubspot"
      Icon={<img src={hubspotLogo} height={20} />}
      isExternal
      {...props}
    />
  )
}
