import { Accordion } from "@mantine/core"
import { IconHome, IconTimelineEvent } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { ProcessesReport } from "@ensol/entool/pages/OpsProjectsOverview/Details/ProcessesReport"
import { STATUS_SECTION_CONFIG } from "@ensol/entool/pages/OpsProjectsOverview/config"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectResponses.ProjectOverview
}

export const Details = ({ project }: Props) => {
  const { processes } = STATUS_SECTION_CONFIG[project.status]

  return (
    <Accordion flex={1} py={24} variant="contained" defaultValue="process">
      <Accordion.Item value="installation">
        <Accordion.Control bg="white" icon={<IconHome size={16} />}>
          Installation
        </Accordion.Control>
        <Accordion.Panel bg="white">
          <InstallationCharacteristics
            installation={project.installation}
            installers={getProjectInstallers(project)}
          />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="process">
        <Accordion.Control bg="white" icon={<IconTimelineEvent size={16} />}>
          Process
        </Accordion.Control>
        <Accordion.Panel bg="white">
          <ProcessesReport processes={processes} project={project} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}
