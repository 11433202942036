export const IntegrationKitType = {
  ESDEC_HOOK: "ESDEC_HOOK",
  ESDEC_LAG_SCREW_250: "ESDEC_LAG_SCREW_250",
  ESDEC_LAG_SCREW_350: "ESDEC_LAG_SCREW_350",
  ESDEC_ROOF_HOOK: "ESDEC_ROOF_HOOK",
  K2_HOOK: "K2_HOOK",
  K2_LAG_SCREW_250: "K2_LAG_SCREW_250",
  K2_DOME: "K2_DOME",
} as const

export type IntegrationKitType = keyof typeof IntegrationKitType

export const INTEGRATION_KITS = {
  [IntegrationKitType.ESDEC_HOOK]: {
    name: "ESDEC - Crochet - Trusshook",
  },
  [IntegrationKitType.ESDEC_LAG_SCREW_250]: {
    name: "ESDEC - Tire-fonds 250mm",
  },
  [IntegrationKitType.ESDEC_LAG_SCREW_350]: {
    name: "ESDEC - Tire-fonds 350mm",
  },
  [IntegrationKitType.ESDEC_ROOF_HOOK]: {
    name: "ESDEC - Pince",
  },
  [IntegrationKitType.K2_HOOK]: {
    name: "K2 - Crochet 4S",
  },
  [IntegrationKitType.K2_LAG_SCREW_250]: {
    name: "K2 - Tire-fonds 250mm",
  },
  [IntegrationKitType.K2_DOME]: {
    name: "K2 - Dome",
  },
}

export const getIntegrationKitName = (
  integrationKit: IntegrationKitType | null,
): string | null =>
  integrationKit ? INTEGRATION_KITS[integrationKit].name : null
