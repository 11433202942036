import { ProjectEventStatus } from "@ensol/shared/entities/projects/events"
import { colors } from "@ensol/shared/styles/colors"

export const EVENT_STATUSES_COLORS: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: colors.yellow[200],
  [ProjectEventStatus.TO_SCHEDULE]: colors.red[300],
  [ProjectEventStatus.SCHEDULED]: colors.blue[500],
  [ProjectEventStatus.REJECTED]: colors.red[500],
  [ProjectEventStatus.COMPLETED]: colors.green[600],
} as const

export const EXTERNAL_EVENT_COLOR = "black" as const

export const EVENT_WARNING_BORDER_COLOR = "red.5" as const

export const EVENT_STATUSES_NAMES: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: "Date proposée",
  [ProjectEventStatus.TO_SCHEDULE]: "Évènement prêt à planifier",
  [ProjectEventStatus.SCHEDULED]: "Évènement prévu",
  [ProjectEventStatus.REJECTED]: "Invitation refusée",
  [ProjectEventStatus.COMPLETED]: "Évènement terminé",
} as const
