import { typedKeys } from "@ensol/types/utils"

import { EV_CHARGER_SUBSIDIES } from "@ensol/shared/entities/installations/subsidies/evCharger"
import { EV_CHARGERS, EvChargerType } from "@ensol/shared/material/evCharger"

import { Option } from "@ensol/entool/utils/form/radio"

export const EV_CHARGER_OPTIONS: Option<EvChargerType>[] = typedKeys(
  EV_CHARGERS,
).map((evChargerType) => {
  const { name } = EV_CHARGERS[evChargerType]

  return {
    label: name,
    value: evChargerType,
  }
})

export const EV_CHARGER_SUBSIDIES_OPTIONS = typedKeys(EV_CHARGER_SUBSIDIES).map(
  (evChargerSubsidyType) => {
    const { name } = EV_CHARGER_SUBSIDIES[evChargerSubsidyType]

    return {
      label: name,
      value: evChargerSubsidyType,
    }
  },
)
