import { Badge, MantineSize } from "@mantine/core"

import { QuoteStatus, STATUSES } from "@ensol/shared/entities/quotes"

type Props = {
  status?: QuoteStatus | null
  size?: MantineSize
}

export const QuoteStatusBadge = ({ status, size = "md" }: Props) => {
  if (!status) {
    return (
      <Badge size={size} color="gray">
        Non créé
      </Badge>
    )
  }

  const { label, color } = STATUSES[status]

  return (
    <Badge size={size} color={color}>
      {label}
    </Badge>
  )
}
