import { Stack } from "@mantine/core"
import { IconAt, IconPhone } from "@tabler/icons-react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { ProspectHeader } from "@ensol/entool/components/entities/Prospect/ProspectHeader"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetProjectFeedbackQuery } from "@ensol/entool/queries/projects"

import { FeedbackForm } from "./FeedbackForm"

export const ProjectFeedbackPage = () => {
  const { projectId } = useParams()
  const feedbackQuery = useGetProjectFeedbackQuery(projectId!)

  return (
    <QueryWrapper query={feedbackQuery}>
      {({ data: { prospect, feedback } }) => (
        <Stack w="100%">
          <Helmet>
            <title>Feedback | Entool</title>
          </Helmet>
          <ProspectHeader
            prospect={prospect}
            backPath="/sales"
            LeftComponent={
              <HeaderInfos
                infos={[
                  { Icon: IconPhone, text: prospect.phone },
                  { Icon: IconAt, text: prospect.email },
                ]}
              />
            }
            RightComponent={null}
          />
          <ContentContainer flex="1">
            <FeedbackForm projectId={projectId!} feedback={feedback} />
          </ContentContainer>
        </Stack>
      )}
    </QueryWrapper>
  )
}
