import { Stack, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  InstallationCharacteristic,
  computeInstallationCharacteristics,
} from "@ensol/shared/entities/installations/characteristics"
import { CURRENT_CONNECTIONS } from "@ensol/shared/material/currentType"

type Props = {
  installation: ProjectResponses.Project["installation"]
  installers: string[]
}

export const InstallationCharacteristics = ({
  installation,
  installers,
}: Props) => {
  const {
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
  } = computeInstallationCharacteristics(installation)

  return (
    <Stack gap="4">
      <Text size="sm">
        Type de courant :
        <b> {CURRENT_CONNECTIONS[installation.house.currentType].name}</b>
      </Text>
      {photovoltaicInstallation.map(({ label, value }) => (
        <CharacteristicRow key={label} label={label} value={value} />
      ))}
      {batteryInstallation.map(({ label, value }) => (
        <CharacteristicRow key={label} label={label} value={value} />
      ))}
      {evChargerInstallation.map(({ label, value }) => (
        <CharacteristicRow key={label} label={label} value={value} />
      ))}
      {installers.length > 0 && (
        <Text size="sm">
          Installateur{installers.length > 1 ? "s" : ""} :{" "}
          <b>{installers.join(", ")}</b>
        </Text>
      )}
    </Stack>
  )
}

const CharacteristicRow = ({ label, value }: InstallationCharacteristic) => {
  if (value === null) {
    return null
  }

  return (
    <Text key={label} size="sm">
      {label} : <b>{value}</b>
    </Text>
  )
}
