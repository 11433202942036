import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { Coords } from "@ensol/types/forms/houses"

import { useDrawMap } from "@ensol/shared/thirdParties/google/maps"

import { httpClient } from "@ensol/entool/backend/axios"

import { MapWrapper } from "./MapWrapper"

type Props = {
  coords: Coords
  onPlacePinPoint?: (coords: Coords) => void
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  mapId: string
  style?: React.CSSProperties
}

const FETCH_DATA_LAYERS_CONFIG = {
  httpClient,
  radiusMeters: 30,
}

export const Map = ({
  coords,
  mapId,
  onPlacePinPoint,
  panelsLayout,
  style,
}: Props) => {
  const isEditable = !!onPlacePinPoint
  const withDataLayers = !!panelsLayout

  const { isMapLoaded } = useDrawMap({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    coords,
    onClickMap: onPlacePinPoint,
    panelsLayoutPaths: panelsLayout?.paths,
    withControls: isEditable,
    withOrthorectifiedMap: panelsLayout
      ? panelsLayout.withOrthorectifiedMap
      : false,
    mapId,
    fetchDataLayersConfig: withDataLayers
      ? FETCH_DATA_LAYERS_CONFIG
      : undefined,
  })
  return (
    <MapWrapper
      mapId={mapId}
      style={{ width: "100%", borderRadius: 16, ...style }}
      isLoading={!isMapLoaded}
    />
  )
}
