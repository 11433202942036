import * as z from "zod"
import { CurrentType } from "@ensol/shared/material/currentType"
import { RoofType } from "@ensol/shared/material/roofType"
import { DEFAULT_HOUSE_CONFIG } from "@ensol/shared/entities/installations/defaults"
import { transformSchemaForApi } from "../utils"
import { HOUSE_EQUIPMENTS } from "@ensol/shared/entities/houses/equipments"

export const coordsSchema = z.object({
  lat: z.number(),
  lng: z.number(),
})

export type Coords = z.infer<typeof coordsSchema>

/**
 * Location describes a place with its coordinates and address. It's pure geolocation data
 * It's mainly used as an interface for the Google Places API
 */
export const locationSchema = z.object({
  ...coordsSchema.shape,
  placeId: z.string(),
  address: z.string(),
  streetAddress: z.string(),
  city: z.string(),
  postcode: z.string(),
  country: z.string(),
  region: z.string(),
})

export type Location = z.infer<typeof locationSchema>

export const roofSectionConfigSchema = z.object({
  id: z.string().optional(), // The option here is there to help distinguish existing roof sections from new ones.
  inclination: z.number().nonnegative(),
  orientation: z.number(),
})

export type RoofSectionConfig = z.infer<typeof roofSectionConfigSchema>

export enum HouseUpdateType {
  GENERAL = "general",
  VISIT = "visit",
  UPDATE_ROOF_TYPE = "updateRoofType",
  UPDATE_EQUIPMENTS = "updateEquipments",
  UPDATE_TOWN_HALL = "updateTownHall",
  UPDATE_ELECTRICAL_METER_PHOTOS = "updateElectricalMeterPhotos",
  UPDATE_EXISTING_INSTALLATION = "updateExistingInstallation",
}

export const houseConfigSchema = z.object({
  updateType: z.undefined(),
  constructionYear: z.number().nonnegative(),
  hasFlatRoof: z.boolean(),
  hasGroundInstallation: z.boolean(),
  roofSections: z.array(roofSectionConfigSchema).min(1),
  currentType: z
    .nativeEnum(CurrentType)
    .optional()
    .default(DEFAULT_HOUSE_CONFIG.currentType),
  hasLinky: z.boolean().nullable(),
  roofType: z
    .nativeEnum(RoofType)
    .optional()
    .default(DEFAULT_HOUSE_CONFIG.roofType),
  surface: z.number().nonnegative().nullable(),
  floors: z.number().nullable(),
  isIndividualHouse: z.boolean().nullable(),
  hasAsbestos: z.boolean().nullable(),
  isHistoricBuilding: z.boolean().nullable(),
  isAtticAccessible: z.boolean().nullable(),
  roofFramingMaterial: z.string().nullable(),
  equipments: z.array(z.enum(HOUSE_EQUIPMENTS)),
})

/**
 * House describes a house with its location and its configuration for the simulator
 * It extends Location with the configuration for the simulator (current type, roof type, flat roof prop)
 */
export const createHouseSchema = locationSchema.merge(houseConfigSchema)

export const generalUpdateHouseSchema = createHouseSchema.extend({
  updateType: z.literal(HouseUpdateType.GENERAL),
})

export const houseFormSchema = z.discriminatedUnion("updateType", [
  createHouseSchema,
  generalUpdateHouseSchema,
])

export type HouseInput = z.infer<typeof houseFormSchema>

export const pdlSchema = z
  .string()
  .regex(/^[0-9\s]+$/, "PDL invalide")
  .refine((value) => value.replace(/\s+/g, "").length === 14, {
    message: "Le PDL doit être composé de 14 chiffres",
  })
  .transform((value) => value.replace(/\s+/g, ""))

export const visitUpdateHouseSchema = z.object({
  updateType: z.literal(HouseUpdateType.VISIT),
  existingInstallationCapacity: z.number().nullable(),
  existingInstallationContract: z.string().nullable(),
  existingInstallationAge: z.string().nullable(),

  constructionYear: z.number().nonnegative(),
  surface: z.number().nonnegative().nullable(),
  floors: z.number().nullable(),
  isIndividualHouse: z.boolean().nullable(),
  hasAsbestos: z.boolean().nullable(),
  isHistoricBuilding: z.boolean().nullable(),
  fromStreetPhotos: z.array(z.string()).min(1, "Champ obligatoire"),
  fromFrontPhotos: z.array(z.string()).min(1, "Champ obligatoire"),
  hasUnRegulatedConstruction: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  unRegulatedConstructionNote: z.string().optional().nullable(),
  electricMeterPhotos: z.array(z.string()).min(1, "Champ obligatoire"),
  hasLinky: z.boolean().nullable(),
  pdl: pdlSchema,

  electricalPanelSurroundingsPhotos: z
    .array(z.string())
    .min(1, "Champ obligatoire"),
  electricalPanelPhotos: z.array(z.string()).min(1, "Champ obligatoire"),

  isAtticAccessible: z.boolean().nullable(),
  atticPhotos: z.array(z.string()),
  roofFramingMaterial: z.string().nullable(),

  roofPhotos: z.array(z.string()).min(1, "Champ obligatoire"),
  potentialShading: z.string().nullable(),
  isPowerLineNearby: z.boolean().nullable(),
  isPodNeeded: z.boolean().nullable(),

  otherPhotos: z.array(z.string()),
  equipments: z.array(z.enum(HOUSE_EQUIPMENTS)),
})

export const updateRoofTypeConfigSchema = z.object({
  updateType: z.literal(HouseUpdateType.UPDATE_ROOF_TYPE),
  roofType: z.nativeEnum(RoofType, {
    invalid_type_error: "La couverture est requise",
  }),
})

export const updateEquipmentsConfigSchema = z.object({
  updateType: z.literal(HouseUpdateType.UPDATE_EQUIPMENTS),
  equipments: z.array(z.enum(HOUSE_EQUIPMENTS)),
})

export const updateTownHallSchema = z.object({
  updateType: z.literal(HouseUpdateType.UPDATE_TOWN_HALL),
  townHallId: z.string().nullable().optional(),
})

export const updateElectricalMeterConfigSchema = z.object({
  updateType: z.literal(HouseUpdateType.UPDATE_ELECTRICAL_METER_PHOTOS),
  electricMeterPhotos: z.array(z.string()),
})

export const updateExistingInstallationSchema = z.object({
  updateType: z.literal(HouseUpdateType.UPDATE_EXISTING_INSTALLATION),
  existingInstallationCapacity: z.number().nullable(),
  existingInstallationContract: z.string().nullable(),
  existingInstallationAge: z.string().nullable(),
  existingInstallationDetails: z.string().optional(),
  existingInstallationFilesPaths: z.array(z.string()),
})

export const updateHouseSchema = z.discriminatedUnion("updateType", [
  generalUpdateHouseSchema,
  visitUpdateHouseSchema,
  updateRoofTypeConfigSchema,
  updateEquipmentsConfigSchema,
  updateTownHallSchema,
  updateElectricalMeterConfigSchema,
  updateExistingInstallationSchema,
])

export type UpdateHouseInput = z.infer<typeof updateHouseSchema>

export const updateHouseSchemaForApi = z.discriminatedUnion("updateType", [
  generalUpdateHouseSchema,
  transformSchemaForApi(
    visitUpdateHouseSchema.extend({ pdl: z.string().nullable().optional() }),
  ),
  updateRoofTypeConfigSchema,
  updateEquipmentsConfigSchema,
  updateTownHallSchema,
  updateElectricalMeterConfigSchema,
  updateExistingInstallationSchema,
])
