import { showNotification } from "@mantine/notifications"
import dayjs from "dayjs"

import { queryClient } from "@ensol/entool/backend/queryClient"
import {
  useCompleteProcessStepMutation,
  useUpdateProcessDataMutation,
} from "@ensol/entool/queries/projects"

type ScheduleInstallationInput = {
  projectId: string
  start: Date
  end: Date
  installerName: string | null
  onClose: () => void
}

export const useScheduleInstallation = ({
  projectId,
  start,
  end,
  installerName,
  onClose,
}: ScheduleInstallationInput) => {
  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useUpdateProcessDataMutation("installation", projectId)
  const { mutateAsync: completeStep, isPending: isCompletingStep } =
    useCompleteProcessStepMutation()

  return {
    isScheduling: isUpdatingProject || isCompletingStep,
    scheduleInstallation: async () => {
      if (installerName === null) {
        return showNotification({
          title: "Des informations sont manquantes ou incorrectes",
          message:
            "L'installateur doit être sélectionné pour planifier l'installation",
          color: "red",
        })
      }

      await updateProject({
        installationStartDate: dayjs(start).add(12, "hour").toDate(),
        installationEndDate: dayjs(end).subtract(12, "hour").toDate(),
      })
      await completeStep({
        projectId,
        processId: "installationStep",
        action: "validate",
      })
      await queryClient.invalidateQueries({
        queryKey: ["projects", "events"],
      })
      onClose()
    },
  }
}
