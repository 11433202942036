import { Stack } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { useCallback } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Team } from "@ensol/shared/entities/users"

import { HouseForm } from "@ensol/entool/components/form/House/SalesVisit/HouseForm"
import { useHouseForm } from "@ensol/entool/components/form/House/SalesVisit/useHouseForm"
import { ProjectForm } from "@ensol/entool/components/form/Project/ProjectForm"
import { useProjectForm } from "@ensol/entool/components/form/Project/useProjectForm"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { Actions } from "@ensol/entool/pages/SalesVisit/Actions"
import { useStartProjectMutation } from "@ensol/entool/queries/projects"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"
import { isFormValid } from "@ensol/entool/utils/form/validation"

import { Header } from "./Header"

type Props = {
  project: ProjectResponses.Project
}

export const SalesVisitForm = ({ project }: Props) => {
  const { mutateAsync: startProject, isPending: isStartingProject } =
    useStartProjectMutation(project.id)
  const { data: user } = useAuthenticatedUser()

  const installation = project.installation
  const house = installation.house
  const prospect = house.prospect

  const houseForm = useHouseForm(house)
  const projectForm = useProjectForm(project)

  const submitProject = useCallback(async () => {
    if (user?.teams.includes(Team.INSIDE_SALES)) {
      await startProject()
    } else {
      houseForm.validate()
      projectForm.validate()

      if (!isFormValid(houseForm) || !isFormValid(projectForm)) {
        return showNotification({
          title: "Formulaire incomplet",
          message: "Veuillez remplir tous les champs obligatoires.",
          color: "red",
        })
      }
    }
    await startProject()
  }, [houseForm, projectForm, startProject, user])

  return (
    <Stack>
      <Header
        prospect={prospect}
        projectId={project.id}
        address={house.address}
        installationId={installation?.id}
      >
        <Actions
          project={project}
          submitProject={submitProject}
          isSubmitting={isStartingProject}
        />
      </Header>
      <ContentContainer flex="1">
        <ProjectForm project={project} form={projectForm} />
        <HouseForm house={house} form={houseForm} />
      </ContentContainer>
    </Stack>
  )
}
