import { ConsuelStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  WEEK_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"

import { InfoComponentProps } from "../config"

export const ConsuelInfo = ({
  project: {
    consuelStep,
    consuelSummonsWeek,
    consuelSummonsDate,
    isValidationVisitNecessary,
  },
}: InfoComponentProps) => (
  <ProcessInfos
    infos={[
      {
        name: "Convocation",
        value: consuelSummonsDate
          ? formatDate(consuelSummonsDate, SHORT_DISPLAY_DATE_FORMAT)
          : formatDate(consuelSummonsWeek, WEEK_FORMAT),
        isHidden:
          consuelStep !== ConsuelStep.VISIT_NEEDED &&
          consuelStep !== ConsuelStep.VISIT_SCHEDULED &&
          consuelStep !== ConsuelStep.VISIT_WAITING_VALIDATION &&
          consuelStep !== ConsuelStep.QUALITY_PV_VISIT_NEEDED &&
          consuelStep !== ConsuelStep.QUALITY_PV_VISIT_SCHEDULED &&
          consuelStep !== ConsuelStep.QUALITY_PV_WAITING_VALIDATION,
      },
      {
        name: "Visite post travaux ?",
        value: findSelectedOption(isValidationVisitNecessary, booleanOptions)
          ?.label,
        isHidden: consuelStep !== ConsuelStep.WORK_NEEDED,
      },
    ]}
  />
)
