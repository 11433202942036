import dayjs from "dayjs"

import { DEFAULT_PANELS_INSTALLATION_INCLUDES } from "@ensol/types/endpoints/installations"
import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"

export const computeInstallationEndDate = (
  project: ProjectResponses.Project<{
    include: {
      installation: {
        include: {
          house: true
          photovoltaicInstallation: {
            include: typeof DEFAULT_PANELS_INSTALLATION_INCLUDES
          }
          batteryInstallation: true
        }
      }
    }
  }>,
  startDate: Date,
) => {
  // TODO: See during ops multi-product refacto if we want to refine
  // auto installation duration computation for non-photovoltaic installations
  let estimatedDuration = 1

  if (project.installation.photovoltaicInstallation !== null) {
    const installedCapacity = computePanelsCapacity(
      project.installation.photovoltaicInstallation,
    )
    estimatedDuration = Math.ceil(installedCapacity / 3)
  }

  const duration = project.installationEstimatedDuration ?? estimatedDuration

  return dayjs(startDate)
    .add(duration - 1, "day")
    .toDate()
}
