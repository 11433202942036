import { Stack, SimpleGrid } from "@mantine/core"
import {
  IconAdjustmentsHorizontal,
  IconSettings,
  IconSun,
} from "@tabler/icons-react"

import {
  UpdateProjectInput,
  updateProjectForm,
} from "@ensol/types/forms/projects"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { Team } from "@ensol/shared/entities/users"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { MilestonesStepper } from "@ensol/entool/components/entities/Project/MilestonesStepper"
import { ViewProps } from "@ensol/entool/components/entities/Project/ProcessView"
import { ProcessesMilestoneReport } from "@ensol/entool/components/entities/Project/ProcessView/MilestoneReport"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { Field } from "@ensol/entool/components/form/Field"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { ProjectActions } from "@ensol/entool/pages/OpsProject/ProjectActions"
import { PROJECT_STATUSES } from "@ensol/entool/utils/projects/statuses"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

import { PROJECT_PROCESS_DETAILS_VIEWS } from "./config"

export const Overview = ({ project }: Pick<ViewProps, "project">) => {
  const form = useProcessForm<UpdateProjectInput>({
    schema: updateProjectForm,
    initialValues: {
      operationsOwnerId: project.operationsOwnerId,
      salesOwnerId: project.salesOwnerId,
      adminOwnerId: project.adminOwnerId,
    },
    projectId: project.id,
  })

  const { activeQuote, house } = project.installation
  const { prospect } = house

  return (
    <Stack gap="20">
      <Section
        title="Statut du projet"
        titleOrder={2}
        icon={IconSun}
        Actions={<ProjectActions project={project} />}
      >
        <MilestonesStepper<ProjectStatus>
          currentMilestone={project.status as ProjectStatus}
          milestonesConfig={PROJECT_STATUSES}
          abortedMilestones={[ProjectStatus.ABORTED]}
          height={180}
          project={project}
        />
      </Section>
      <Section
        title="Avancement"
        titleOrder={2}
        icon={IconAdjustmentsHorizontal}
      >
        <SimpleGrid cols={{ base: 2, xl: 3 }} spacing="xs">
          <ProcessesMilestoneReport
            processes={PROJECT_PROCESS_DETAILS_VIEWS.map(({ id }) => id)}
            project={project}
          />
        </SimpleGrid>
      </Section>
      <Section title="Projet" titleOrder={3} icon={IconSettings}>
        <SimpleGrid cols={2} spacing="32">
          <Stack gap="24">
            <Field name="Responsable AE" noBorder>
              <UserSelect
                teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
                w="300"
                withDefault={false}
                {...form.getInputProps("salesOwnerId")}
              />
            </Field>
            <Field name="Responsable Ops" noBorder>
              <UserSelect
                teams={[Team.CUSTOMER_SUCCESS]}
                w="300"
                withDefault={false}
                {...form.getInputProps("operationsOwnerId")}
              />
            </Field>
            <Field name="Responsable Admin" noBorder>
              <UserSelect
                teams={[Team.ADMIN]}
                w="300"
                withDefault={false}
                {...form.getInputProps("adminOwnerId")}
              />
            </Field>
            <Field name="Devis" noBorder>
              {activeQuote !== null && activeQuote.quotePath !== null ? (
                <MultiFilePreview
                  filesPaths={[activeQuote.quotePath]}
                  fileName={getProspectFileName("devis", prospect)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field name="Mandat" noBorder>
              {house.mandatePath !== null ? (
                <MultiFilePreview
                  filesPaths={[house.mandatePath]}
                  fileName={getProspectFileName("mandat", prospect)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
          </Stack>
          <ProjectNotes projectId={project.id} mah={528} />
        </SimpleGrid>
      </Section>
    </Stack>
  )
}
