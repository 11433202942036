import { Group } from "@mantine/core"

import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"

import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { FUNDING_SOURCE_OPTIONS } from "@ensol/entool/utils/projects/options"

import { FundingSourceStatusInfo } from "./FundingSourceStatusInfo"
import { TextInfo } from "./TextInfo"

type Props = {
  fundingSource: FundingSource | null
  fundingSourceStatus: FundingSourceStatus | null
}

export const FundingSourceInfo = ({
  fundingSource,
  fundingSourceStatus,
}: Props) => {
  const financingOption = findSelectedOption(
    fundingSource,
    FUNDING_SOURCE_OPTIONS,
  )

  return (
    financingOption && (
      <Group gap={8}>
        <TextInfo value={financingOption.label} />
        <FundingSourceStatusInfo type={fundingSourceStatus} />
      </Group>
    )
  )
}
