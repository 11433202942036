import { Alert } from "@mantine/core"
import {
  IconArrowBackUp,
  IconClockPause,
  IconInfoCircle,
} from "@tabler/icons-react"

import { preliminaryValidationStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { PreliminaryRequestForm } from "./components/PreliminaryRequestForm"

export const PreliminaryValidation = ({
  project,
  ...props
}: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={preliminaryValidationStepFormSchema}
    initialValues={getInitialValues(
      preliminaryValidationStepFormSchema,
      project,
    )}
    project={project}
    actions={[
      {
        action: "reject",
        label: "Renvoyer à l'AE pour compléter des infos",
        color: "orange",
        Icon: IconArrowBackUp,
      },
      {
        action: "hold",
        color: "orange",
        Icon: IconClockPause,
        label: "Demande préalable en attente",
      },
      { action: "validate", label: "Projet complet" },
    ]}
    {...props}
  >
    <Alert
      variant="light"
      color="blue"
      title="Vérifier que les informations présentes ci-dessous sont complètes et correctes."
      icon={<IconInfoCircle size={30} />}
      mb="12"
    />
  </PreliminaryRequestForm>
)
