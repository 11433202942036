import { Alert, Button, SimpleGrid, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import { IconAlertCircle, IconDeviceFloppy } from "@tabler/icons-react"

import {
  ProspectInput,
  updateProspectSchema,
} from "@ensol/types/forms/prospects"
import { Nullable } from "@ensol/types/utils"

import { ResidentialStatus } from "@ensol/shared/entities/prospects"

import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { useCreateProspectMutation } from "@ensol/entool/queries/prospects"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { GENDER_OPTIONS } from "@ensol/entool/utils/prospects/options"

export const FallbackForm = ({
  gender,
  firstName,
  lastName,
  email,
  phone,
  residentialStatus,
  hubspotContactId,
}: Nullable<ProspectInput>) => {
  const { mutateAsync: createProspect, isPending } = useCreateProspectMutation()

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateProspectSchema),
    initialValues: {
      gender,
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      residentialStatus,
      hubspotContactId: hubspotContactId ?? "",
    },
  })

  return (
    <ContentContainer flex="1">
      <Alert
        color="red"
        title={
          "Nous n'avons pas pu créer le client automatiquement, ajouter les informations manquantes"
        }
        icon={<IconAlertCircle />}
      />
      <Stack p="16" mt="16">
        <Stack gap="16" align="flex-end">
          <SimpleGrid cols={2} spacing="48" w="100%">
            <Field name="Civilité" noBorder withAsterisk>
              <RadioGroup
                options={GENDER_OPTIONS}
                {...form.getInputProps("gender")}
              />
            </Field>
          </SimpleGrid>
          <SimpleGrid cols={2} spacing="48" verticalSpacing="16" w="100%">
            <Field name="Prénom" noBorder withAsterisk>
              <TextInput w={FIELD_WIDTH} {...form.getInputProps("firstName")} />
            </Field>
            <Field name="Nom" noBorder withAsterisk>
              <TextInput w={FIELD_WIDTH} {...form.getInputProps("lastName")} />
            </Field>
            <Field name="Email" noBorder withAsterisk>
              <TextInput w={FIELD_WIDTH} {...form.getInputProps("email")} />
            </Field>
            <Field name="Téléphone" noBorder withAsterisk>
              <TextInput w={FIELD_WIDTH} {...form.getInputProps("phone")} />
            </Field>
            <Field name="Civilité" noBorder>
              <RadioGroup
                options={[
                  { label: "Propriétaire", value: ResidentialStatus.OWNER },
                  { label: "Locataire", value: ResidentialStatus.RENTER },
                ]}
                {...form.getInputProps("residentialStatus")}
              />
            </Field>
            <Field name="Id Hubspot" noBorder>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("hubspotContactId")}
              />
            </Field>
          </SimpleGrid>
          <Button
            mt="16"
            loading={isPending}
            leftSection={<IconDeviceFloppy />}
            onClick={() => {
              form.validate()
              if (!isFormValid(form)) {
                return showNotification({
                  title: "Formulaire incomplet",
                  message: "Veuillez remplir tous les champs obligatoires.",
                  color: "red",
                })
              }
              createProspect(form.values as ProspectInput)
            }}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>
    </ContentContainer>
  )
}
