import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { computeExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import {
  ProductCosts,
  withMargin,
} from "@ensol/shared/entities/installations/costs/index"
import { computeInvertersCount } from "@ensol/shared/entities/installations/energy"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

type PanelsMaterialCostInput = {
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation
  house: Pick<HousesResponses.House, "currentType" | "hasFlatRoof">
}

// MATERIAL COSTS {€)
const VARIABLE_PANEL_COST = 100

// Build kits
const K_2_CROSS_HOOK_PRICE = 10.4
const K_2_MIDDLE_CLAMP_XS_PRICE = 2.48
const K_2_END_CLAMP_XS_PRICE = 2.6
const K_2_TERRAGRIF_PRICE = 2.5
const K_2_SINGLE_RAIL_PRICE = 20.6
const K_2_CONNECTEUR_SINGLE_RAIL_36_PRICE = 5.96
const RENUSOL_PRICE = 75 // €

// WORKFORCE COSTS {€)
const WORKFORCE_COST = 150

// VAT RATE
export const EXTRA_PANELS_VAT_RATE = 0.2

export const computeBuildKitUnitPrice = (hasFlatRoof: boolean): number => {
  if (hasFlatRoof) return withMargin(RENUSOL_PRICE)
  return withMargin(
    2 * K_2_CROSS_HOOK_PRICE +
      2 * K_2_MIDDLE_CLAMP_XS_PRICE +
      K_2_END_CLAMP_XS_PRICE +
      K_2_TERRAGRIF_PRICE +
      K_2_SINGLE_RAIL_PRICE +
      K_2_CONNECTEUR_SINGLE_RAIL_36_PRICE,
  )
}

export const computeExtraPanelUnitPrice = (panelType: PanelType): number => {
  const panel = getPanel(panelType)
  return withMargin(panel.price)
}

export const computeInverterUnitPrice = (
  inverterType: InverterType,
): number => {
  const inverter = getInverter(inverterType)
  return withMargin(inverter.price)
}

export const computeExtraPanelsMaterialCost = ({
  extraPanelsInstallation,
  house,
}: PanelsMaterialCostInput): number => {
  const { panelType, panelsCount, inverterType } = extraPanelsInstallation

  // Only if micro-inverters are already installed. Otherwise we keep the current central inverter
  const inverter = getInverter(inverterType)
  const invertersCost = inverter.isCentralInverter
    ? 0
    : computeInvertersCount(inverterType, panelsCount) *
      computeInverterUnitPrice(inverterType)

  return (
    panelsCount *
      (computeExtraPanelUnitPrice(panelType) +
        computeBuildKitUnitPrice(house.hasFlatRoof)) +
    invertersCost
  )
}

export const computeExtraPanelsWorkforceCost = (panelsCount: number) => {
  if (panelsCount === 0) return 0
  return withMargin(WORKFORCE_COST + panelsCount * VARIABLE_PANEL_COST)
}

type AllCostsInput = {
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation
  house: Pick<
    HousesResponses.House,
    "constructionYear" | "currentType" | "roofType" | "hasFlatRoof"
  >
}

export const computeExtraPanelsInstallationCost = ({
  extraPanelsInstallation,
  house,
}: AllCostsInput): number => {
  const materialCostHT = computeExtraPanelsMaterialCost({
    extraPanelsInstallation,
    house,
  })
  const workforceCostHT = computeExtraPanelsWorkforceCost(
    extraPanelsInstallation.panelsCount,
  )
  const extraWorksCostHT = computeExtraWorksCosts(extraPanelsInstallation)

  const totalCostHT = materialCostHT + workforceCostHT + extraWorksCostHT

  return roundDecimalNumber(totalCostHT * (1 + EXTRA_PANELS_VAT_RATE))
}

export const computeAllExtraPanelsCosts = ({
  extraPanelsInstallation,
  house,
}: AllCostsInput): ProductCosts => {
  const installationCost = computeExtraPanelsInstallationCost({
    extraPanelsInstallation,
    house,
  })

  const installationCostBeforeTax = roundDecimalNumber(
    installationCost / (1 + EXTRA_PANELS_VAT_RATE),
  )

  return {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies: installationCost,
    vatRate: EXTRA_PANELS_VAT_RATE,
  }
}
