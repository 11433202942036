import { Request } from "express"
import * as z from "zod"

/**
 * Use like this:
 *
 * const schema = z.object({
 *  name: z.string(),
 * })
 *
 * router.post('/', (req, res) => {
 *  validateBody(req.body, schema)
 *  req.body.name // is now a string
 * })
 */
export const validateBody = async <S extends z.Schema>(
  body: Request["body"],
  schema: S,
): Promise<z.infer<typeof schema>> => {
  const validatedBody = await schema.parseAsync(body)
  return validatedBody
}

export const validateQueryParams = async <S extends z.Schema>(
  body: Request["query"],
  schema: S,
): Promise<z.infer<typeof schema>> => {
  const validatedQueryParams = await schema.parseAsync(body)
  return validatedQueryParams
}

export const booleanFromStringSchema = z
  .string()
  .optional()
  .transform((value) => (value === "true" ? true : false))
  .pipe(z.boolean())
