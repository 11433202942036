import _ from "lodash"

import { ProductCosts } from "@ensol/shared/entities/installations/costs"

export const computeDiscountBeforeTax = ({
  totalDiscount,
  totalInstallationCost,
  productsCosts,
}: {
  totalDiscount: number
  totalInstallationCost: number
  productsCosts: ProductCosts[]
}) =>
  _.sum(
    productsCosts.map(
      ({ installationCost, vatRate }) =>
        computeProductDiscount({
          totalDiscount,
          totalInstallationCost,
          productInstallationCost: installationCost,
        }) /
        (1 + vatRate),
    ),
  )

export const computeProductDiscount = ({
  totalDiscount,
  totalInstallationCost,
  productInstallationCost,
}: {
  totalDiscount: number
  totalInstallationCost: number
  productInstallationCost: number
}) => totalDiscount * (productInstallationCost / totalInstallationCost)
