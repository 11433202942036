import { TitleOrder } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconWashMachine } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateEquipmentsConfigSchema,
} from "@ensol/types/forms/houses"

import { Section } from "@ensol/entool/components/Section"
import { HouseEquipments } from "@ensol/entool/components/form/House/HouseEquipments"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

type Props = {
  house: HousesResponses.House
  titleOrder?: TitleOrder
}

export const UpdateEquipments = ({ house, titleOrder = 2 }: Props) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateEquipmentsConfigSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_EQUIPMENTS,
      equipments: house.equipments,
    },
  })

  return (
    <Section title="Équipements" titleOrder={titleOrder} icon={IconWashMachine}>
      <HouseEquipments {...form.getInputProps("equipments")} />
    </Section>
  )
}
