import { EnedisStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { Pending } from "./1-Pending"
import { Submitted } from "./2-Submitted"
import { Incomplete } from "./3-Incomplete"
import { Validated } from "./4-Validated"
import { ActivationPending } from "./5-ActivationPending"
import { Activated } from "./6-Activated"

export const getEnedisStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case EnedisStep.PENDING:
      return { StepComponent: Pending, isCancellable: false }
    case EnedisStep.SUBMITTED:
      return { StepComponent: Submitted }
    case EnedisStep.INCOMPLETE:
      return { StepComponent: Incomplete }
    case EnedisStep.VALIDATED:
      return { StepComponent: Validated }
    case EnedisStep.ACTIVATION_PENDING:
      return { StepComponent: ActivationPending }
    case EnedisStep.ACTIVATED:
      return { StepComponent: Activated }
    default:
      return { StepComponent: null }
  }
}
