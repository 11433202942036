import _ from "lodash"

export const pickDefinedProperties = <T>(keys: (keyof T)[], object: T) =>
  keys.reduce((definedProperties: Partial<T>, key) => {
    const value = object[key]

    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      !(_.isArray(value) && value.length === 0)
    ) {
      definedProperties[key] = object[key]
    }

    return definedProperties
  }, {})
