import { RoofSectionConfig } from "@ensol/types/forms/houses"

import { FLAT_ROOF_SECTION } from "@ensol/shared/entities/houses/roofSection"

type RoofTypeInfo = {
  name: string
  cost: number
}

export const RoofType = {
  SLATE: "SLATE",
  MARSEILLE_TILE: "MARSEILLE_TILE",
  ROMAN_TILE: "ROMAN_TILE",
  REVERSED_CANAL_TILE_WOOD: "REVERSED_CANAL_TILE_WOOD",
  REVERSED_CANAL_TILE_CONCRETE: "REVERSED_CANAL_TILE_CONCRETE",
  HOOKED_CANAL_TILE: "HOOKED_CANAL_TILE",
  REDLAND_TILE: "REDLAND_TILE",
  PST: "PST",
  STEEL_PAN: "STEEL_PAN",
  EPDM: "EPDM",
  BITUMEN: "BITUMEN",
  GRAVEL: "GRAVEL",
  OTHER: "OTHER",
} as const

export type RoofType = keyof typeof RoofType

export const ROOF_TYPES: Record<RoofType, RoofTypeInfo> = {
  [RoofType.SLATE]: {
    name: "Ardoise",
    cost: 500,
  },
  [RoofType.MARSEILLE_TILE]: {
    name: "Tuiles de Marseille",
    cost: 250,
  },
  [RoofType.ROMAN_TILE]: {
    name: "Tuiles romanes",
    cost: 250,
  },
  [RoofType.REVERSED_CANAL_TILE_WOOD]: {
    name: "Tuiles canal inversées - Bois",
    cost: 250,
  },
  [RoofType.REVERSED_CANAL_TILE_CONCRETE]: {
    name: "Tuiles canal inversées - Béton",
    cost: 250,
  },
  [RoofType.HOOKED_CANAL_TILE]: {
    name: "Tuiles canal à crochet",
    cost: 250,
  },
  [RoofType.REDLAND_TILE]: {
    name: "Tuiles Redland",
    cost: 250,
  },
  [RoofType.PST]: {
    name: "PST",
    cost: 250,
  },
  [RoofType.STEEL_PAN]: {
    name: "Bac acier",
    cost: 250,
  },
  [RoofType.EPDM]: {
    name: "EPDM",
    cost: 250,
  },
  [RoofType.BITUMEN]: {
    name: "Bitume",
    cost: 250,
  },
  [RoofType.GRAVEL]: {
    name: "Gravier",
    cost: 250,
  },
  [RoofType.OTHER]: {
    name: "Autre",
    cost: 250,
  },
}

export const ROOF_MULTI_SECTIONS_COST = 250 // €

const FLAT_ROOF_INCLINATION_THRESHOLD = 5 // °

export const checkHasFlatRoof = (
  roofSections: RoofSectionConfig[],
): boolean => {
  return roofSections.some(
    (section) => section.inclination < FLAT_ROOF_INCLINATION_THRESHOLD,
  )
}

type RoofConfigInput = {
  hasFlatRoof?: boolean
  roofSections: RoofSectionConfig[]
}

type RoofConfig = {
  hasFlatRoof: boolean
  roofSections: RoofSectionConfig[]
}

/**
 * Compute the roof configuration based on the roof type and the inclination and orientation
 * If hasFlatRoof is undefined, we check for a 5° inclination threshold
 * If roof is flat, then panels disposition is free. We chose a 30° inclination and a south orientation
 */
export const computeRoofSectionsConfig = ({
  hasFlatRoof,
  roofSections,
}: RoofConfigInput): RoofConfig => {
  if (
    hasFlatRoof ||
    (hasFlatRoof === undefined && checkHasFlatRoof(roofSections))
  ) {
    return {
      hasFlatRoof: true,
      roofSections:
        roofSections.length === 1 && checkHasFlatRoof(roofSections)
          ? roofSections
          : [FLAT_ROOF_SECTION],
    }
  }

  return {
    hasFlatRoof: false,
    roofSections,
  }
}
