import * as z from "zod"
import { transformSchemaForApi } from "../utils"

export const emailPendingStepFormSchema = z.object({
  greendealNotes: z.string().optional(),
})

export const submissionPendingStepFormSchema =
  emailPendingStepFormSchema.extend({
    greendealAcknowledgmentPath: z.string().nullable().optional(),
    greendealReference: z.string().nullable().optional(),
    greendealSubmissionDate: z.date().nullable().optional(),
  })

export const submittedStepFormSchema = submissionPendingStepFormSchema.extend({
  greendealAcknowledgmentPath: z
    .string({ invalid_type_error: "Champ obligatoire" })
    .min(1, "Champ obligatoire"),
  greendealReference: z.string().min(1, "Champ obligatoire"),
  greendealSubmissionDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type GreendealStepInput =
  | z.infer<typeof emailPendingStepFormSchema>
  | z.infer<typeof submissionPendingStepFormSchema>
  | z.infer<typeof submittedStepFormSchema>

export const greendealProcessSchema = transformSchemaForApi(
  submittedStepFormSchema,
)
