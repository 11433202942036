import { UseFormReturnType } from "@mantine/form"
import { useEffect } from "react"

import { InstallationProductsInput } from "@ensol/types/forms/installations"

import { getInstallationProductsCount } from "@ensol/shared/entities/installations/characteristics"

export const useUpdateProductsCount = <
  Values extends InstallationProductsInput,
>(
  form: UseFormReturnType<Values>,
) => {
  useEffect(() => {
    const value = getInstallationProductsCount(form.values)
    form.getInputProps("productsCount").onChange(value)
  }, [
    form.values.photovoltaicInstallation,
    form.values.batteryInstallation,
    form.values.extraPanelsInstallation,
    form.values.evChargerInstallation,
    form,
  ])
}
