import { acknowledgmentStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const Incomplete = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={acknowledgmentStepFormSchema}
    initialValues={getInitialValues(acknowledgmentStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "waitingForSolteo",
        label: "En attente",
      },
      {
        action: "validate",
        label: "À redéposer en mairie",
      },
    ]}
    {...props}
  />
)
