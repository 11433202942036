import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import {
  SALES_TASKS_CATEGORIES,
  SalesTaskCategory,
  SalesTaskCategoryConfig,
} from "@ensol/shared/entities/projects/salesTasks"
import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

type TaskComponent = React.ComponentType<{
  project: UnpackArray<ProjectResponses.ProjectsList>
}>

const TASKS_COMPONENTS: Record<SalesTaskCategory, TaskComponent> = {
  signedToComplete: ({ project }) => (
    <ProcessInfos
      infos={[{ name: "Notes Ops", value: project.preliminaryRequestNotes }]}
    />
  ),
  updateQuote: () => null,
  technicalVisitNonSchedulable: ({ project }) => (
    <ProcessInfos
      infos={[
        {
          name: "Dernier contact",
          value: formatDate(
            project.technicalVisitLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
        },
        {
          name: "Raison principale",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                project.schedulingIssueMainReason
                  ? [project.schedulingIssueMainReason]
                  : []
              }
            />
          ),
        },
        {
          name: "Sous raison",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                project.schedulingIssueSubReason
                  ? [project.schedulingIssueSubReason]
                  : []
              }
            />
          ),
        },
      ]}
    />
  ),
  installationNonSchedulable: ({ project }) => (
    <ProcessInfos
      infos={[
        {
          name: "Dernier contact",
          value: formatDate(
            project.installationLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
        },
        {
          name: "Raison principale",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                project.schedulingIssueMainReason
                  ? [project.schedulingIssueMainReason]
                  : []
              }
            />
          ),
        },
        {
          name: "Sous raison",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                project.schedulingIssueSubReason
                  ? [project.schedulingIssueSubReason]
                  : []
              }
            />
          ),
        },
      ]}
    />
  ),
  googleReviewToAsk: ({ project }) => (
    <ProcessInfos
      infos={[{ name: "Plateforme", value: project.feedback?.reviewPlatform }]}
      milestones={{
        processes: ["followUpStep"],
        project,
      }}
    />
  ),
}

export const SALES_TASKS_VIEWS: (SalesTaskCategoryConfig & {
  Component: TaskComponent
})[] = SALES_TASKS_CATEGORIES.map((props) => ({
  ...props,
  Component: TASKS_COMPONENTS[props.id],
}))
