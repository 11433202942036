import { useForm, zodResolver } from "@mantine/form"
import { useState } from "react"
import { z } from "zod"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import {
  useCompleteProcessStepMutation,
  useUpdateProcessDataMutation,
} from "@ensol/entool/queries/projects"

const schema = z
  .object({
    technicalVisitRequiredChanges: z.array(z.string()),
    technicalVisitRequiredWorks: z.array(z.string()),
    isChangesNeeded: z.boolean(),
    isWorksNeeded: z.boolean(),
    technicalVisitEndOfWorksDate: z.date().optional().nullable(),
  })
  .refine(
    (data) => {
      if (data.technicalVisitRequiredWorks.length > 0) {
        return !!data.technicalVisitEndOfWorksDate
      }
      return true
    },
    {
      message: "Champs obligatoire",
      path: ["technicalVisitEndOfWorksDate"],
    },
  )

type ValidateActionModalInput = z.infer<typeof schema>

export const useValidateActionModal = ({
  project,
  processId,
  onClose,
}: CustomActionModalProps) => {
  const [active, setActive] = useState(0)

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      technicalVisitRequiredChanges: project.technicalVisitRequiredChanges,
      technicalVisitRequiredWorks: project.technicalVisitRequiredWorks,
      isChangesNeeded: project.technicalVisitRequiredChanges.length > 0,
      isWorksNeeded: project.technicalVisitRequiredWorks.length > 0,
      technicalVisitEndOfWorksDate: project.technicalVisitEndOfWorksDate,
    },
  })

  const { mutateAsync: updateProjectAsync, isPending: isUpdatingProject } =
    useUpdateProcessDataMutation<ValidateActionModalInput>(
      "technical-visit",
      project.id,
    )

  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current))

  const prevStep = () => {
    if (active === 0) {
      onClose()
    } else setActive(active - 1)
  }

  const { mutateAsync: completeStep, isPending: isCompletingStep } =
    useCompleteProcessStepMutation()

  const handleSubmit = async (values: ValidateActionModalInput) => {
    await updateProjectAsync(values)

    const action = values.technicalVisitRequiredChanges.length
      ? "validateWithChanges"
      : "validate"

    await completeStep({ projectId: project.id, processId, action })

    onClose()
  }

  const isLoading = isUpdatingProject || isCompletingStep

  return {
    form,
    isLoading,
    active,
    setActive,
    handleSubmit,
    nextStep,
    prevStep,
  }
}
