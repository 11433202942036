import { Card, Divider } from "@mantine/core"
import { useMemo } from "react"

import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { CardRow } from "./CardRow"

type Props = {
  panelType: PanelType
  installedPanelsCount: number
  totalPanelsCount: number
}

export const PanelsInfo = ({
  installedPanelsCount,
  totalPanelsCount,
  panelType,
}: Props) => {
  const panel = useMemo(() => getPanel(panelType), [panelType])

  const installedCapacity = useMemo(
    () =>
      computePanelsCapacity({
        panelType,
        panelsCount: totalPanelsCount,
      }),
    [panelType, totalPanelsCount],
  )

  return (
    <Card w={210} shadow="xs" radius="md">
      <CardRow label="Puissance" value={`${installedCapacity} kWc`} />
      <Divider my={8} />
      <CardRow
        label="Panneaux PV"
        value={`${installedPanelsCount} / ${totalPanelsCount}`}
        color={installedPanelsCount > totalPanelsCount ? "red" : undefined}
      />
      <Divider my={8} />
      <CardRow label="Modèle" value={panel.name} />
    </Card>
  )
}
