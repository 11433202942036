import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { HousesResponses } from "@ensol/types/endpoints/houses"

import {
  ConsentAskStatus,
  OrderStatus,
} from "@ensol/shared/entities/houses/switchgrid"

import { queryClient } from "@ensol/entool/backend/queryClient"

export const useAutoRefreshWhilePending = (
  house?: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
    }
  }>,
) => {
  const { installationId } = useParams()

  useEffect(() => {
    if (
      house?.switchgridConsent?.askStatus ===
        ConsentAskStatus.PENDING_USER_ACTION ||
      house?.switchgridOrder?.orderStatus === OrderStatus.PENDING
    ) {
      const intervalId = setInterval(async () => {
        if (installationId) {
          await queryClient.invalidateQueries({
            queryKey: ["installations", installationId],
          })
        }
        await queryClient.invalidateQueries({ queryKey: ["houses", house.id] })
      }, 10000)

      return () => clearInterval(intervalId)
    }
  }, [house, installationId])
}
