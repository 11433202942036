import { Carousel } from "@mantine/carousel"
import { useCallback, useState } from "react"

import { FileThumbnail } from "./FileThumbnail"
import { MultiFilePreviewModal } from "./MultiFilePreviewModal"
import { FILE_THUMBNAIL_HEIGHT_IN_PX, PREVIEW_WIDTH } from "./constants"
import { FilePreviewProps } from "./types"

export const MultiFilePreview = ({
  filesPaths,
  fileName,
  width,
  onClear,
}: FilePreviewProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const onClose = useCallback(() => setIsPreviewOpen(false), [setIsPreviewOpen])
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  return (
    <>
      <Carousel
        w={width ?? PREVIEW_WIDTH}
        height={FILE_THUMBNAIL_HEIGHT_IN_PX}
        withControls={filesPaths.length > 1}
        onSlideChange={(index: number) => setCurrentSlideIndex(index)}
        loop
        styles={{ root: { flexShrink: 0 } }}
      >
        {filesPaths.map((path, index) => (
          <Carousel.Slide key={path}>
            <FileThumbnail
              filePath={path}
              width={width}
              fileName={`${fileName}${index > 0 ? `-${index + 1}` : ""}`}
              onClear={onClear ? () => onClear(path) : undefined}
              onClick={() => setIsPreviewOpen(true)}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      <MultiFilePreviewModal
        isPreviewOpen={isPreviewOpen}
        filePaths={filesPaths}
        onClose={onClose}
        currentSlideIndex={currentSlideIndex}
      />
    </>
  )
}
