import { useLocalStorage } from "@uidotdev/usehooks"

export type CalendarView =
  | "dayGridMonth"
  | "timeGridWeek"
  | "resourceTimelineMonth"

const EVENTS_CALENDAR_VIEW_KEY = "eventsCalendarView"

export const useCalendarView = () => {
  const [currentCalendarView, setCurrentCalendarView] =
    useLocalStorage<CalendarView>(EVENTS_CALENDAR_VIEW_KEY, "dayGridMonth")

  return { currentCalendarView, setCurrentCalendarView }
}
