import * as z from "zod"

export const feedbackFormSchema = z.object({
  npsRating: z.number().min(1).max(10).nullable().optional(),
  goodExperienceReasons: z.string().optional(),
  badExperienceReasons: z.string().optional(),
  comment: z.string().optional(),
  reviewRating: z.number().min(1).max(5).nullable().optional(),
  reviewPlatform: z.string().nullable().optional(),
  willNotPostReview: z.boolean(),
})

export type FeedbackInput = z.infer<typeof feedbackFormSchema>
