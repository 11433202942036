export enum Role {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  INSTALLER = "INSTALLER",
  CLIENT = "CLIENT",
}

export enum Team {
  INSIDE_SALES = "INSIDE_SALES",
  ACCOUNT_EXECUTIVE = "ACCOUNT_EXECUTIVE",
  CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS",
  ADMIN = "ADMIN",
  SITE_MANAGER = "SITE_MANAGER",
  TECHNICAL_EXPERT = "TECHNICAL_EXPERT",
  OPERATIONS = "OPERATIONS",
}
