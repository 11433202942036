import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"
import {
  HardwareStep,
  PaymentStep,
  PreliminaryRequestStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { CheckBadge } from "../components/CheckBadge"
import { TechnicalVisitBadge } from "../components/TechnicalVisitBadge"
import { InfoComponentProps } from "../config"

export const HardwareInfo = ({ project }: InfoComponentProps) => {
  const hardwareStep = project.hardwareStep

  return (
    <ProcessInfos
      infos={[
        {
          name: "Acompte payé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getStepIndex(project.paymentStep, "paymentStep") >=
                getStepIndex(PaymentStep.DEPOSIT_INVOICE_PAID, "paymentStep")
              }
            />
          ),
          isHidden:
            hardwareStep === HardwareStep.PREORDER_PENDING ||
            hardwareStep === HardwareStep.PREORDER_DONE ||
            project.fundingSource === FundingSource.CETELEM,
        },
        {
          name: "Financement validé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                project.fundingSourceStatus === FundingSourceStatus.APPROVED
              }
            />
          ),
          isHidden:
            hardwareStep === HardwareStep.PREORDER_PENDING ||
            hardwareStep === HardwareStep.PREORDER_DONE ||
            project.fundingSource !== FundingSource.CETELEM,
        },
        {
          name: "VT validée ?",
          dataType: "component",
          value: <TechnicalVisitBadge project={project} />,
          isHidden:
            hardwareStep === HardwareStep.PREORDER_PENDING ||
            hardwareStep === HardwareStep.PREORDER_DONE,
        },
        {
          name: "DP validée ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getStepIndex(
                  project.preliminaryRequestStep,
                  "preliminaryRequestStep",
                ) >=
                getStepIndex(
                  PreliminaryRequestStep.APPROVED,
                  "preliminaryRequestStep",
                )
              }
            />
          ),
          isHidden:
            hardwareStep === HardwareStep.PREORDER_PENDING ||
            hardwareStep === HardwareStep.PREORDER_DONE,
        },
        {
          name: "Date de livraison",
          value: formatDate(
            project.orderDeliveryDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
        },
        {
          name: "Fournisseur",
          value: project.supplier?.name,
        },
        {
          name: "Installateur",
          value: project.installer?.name,
        },
        {
          name: "Date d'installation",
          value: formatDate(
            project.installationStartDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            hardwareStep === HardwareStep.PREORDER_PENDING ||
            hardwareStep === HardwareStep.PREORDER_DONE,
        },
      ]}
    />
  )
}
