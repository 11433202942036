import { ClerkProvider } from "@clerk/clerk-react"
import { useNavigate, Outlet } from "react-router-dom"

import { clerk } from "@ensol/entool/backend/clerk"

export const AuthProvider = () => {
  const navigate = useNavigate()

  return (
    <ClerkProvider
      Clerk={clerk}
      routerPush={(url: string) => navigate(url)}
      routerReplace={(url: string) => navigate(url)}
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
    >
      <Outlet />
    </ClerkProvider>
  )
}
