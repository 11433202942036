import { IconArrowBackUp } from "@tabler/icons-react"

import { beforeVisitStepsFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { BeforeVisitCompletedForm } from "./components/BeforeVisitCompletedForm"

export const InvitationRejected = ({
  project,
  ...props
}: StepComponentProps) => (
  <BeforeVisitCompletedForm
    schema={beforeVisitStepsFormSchema}
    initialValues={getInitialValues(beforeVisitStepsFormSchema, project)}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Repasser en prêt à planifier",
        Icon: IconArrowBackUp,
      },
    ]}
    {...props}
  />
)
