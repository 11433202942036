import { Button, Text } from "@mantine/core"
import { modals } from "@mantine/modals"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { isQuoteOutdated, QuoteStatus } from "@ensol/shared/entities/quotes"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import { useCreateQuoteMutation } from "@ensol/entool/queries/quotes"

type Props = {
  activeQuote: InstallationsResponses.Quote | null
  installationId: string
  installationDate: Date
  isInstallationValid: boolean
}

export const GenerateQuoteButton = ({
  activeQuote,
  installationId,
  installationDate,
  isInstallationValid,
}: Props) => {
  const isSigned = activeQuote?.status === QuoteStatus.SIGNED
  const isOutdated = isQuoteOutdated(installationDate, activeQuote)

  const { mutateAsync: sendQuote, isPending: isSendingQuote } =
    useCreateQuoteMutation(installationId)

  let label
  if (activeQuote === null) {
    label = "Créer le devis"
  } else if (!isOutdated) {
    label = "Le devis est à jour"
  } else if (isSendingQuote) {
    label = "Génération du devis"
  } else {
    label = "Mettre à jour le devis"
  }

  return (
    <Button
      data-testid="generate-quote"
      variant="light"
      onClick={() => {
        if (isSigned) {
          return modals.openConfirmModal({
            title: "Le devis de cette installation est signé",
            children: (
              <Text size="sm">
                Êtes-vous sûr(e) de vouloir remplacer ce devis signé par un
                nouveau ? Cette action archivera l&apos;anciens devis et
                nécessitera que le nouveau devis soit signé à la place.
              </Text>
            ),
            confirmProps: { color: "red" },
            centered: true,
            labels: {
              confirm: "Remplacer le devis",
              cancel: "Annuler",
            },
            onConfirm: sendQuote,
          })
        }

        sendQuote()
      }}
      leftSection={isSendingQuote && <LinkLoader />}
      disabled={!isInstallationValid || !isOutdated || isSendingQuote}
      color="purple"
    >
      {label}
    </Button>
  )
}
