import _ from "lodash"

export enum AbortReason {
  REGULARIZATION = "Régularisation",
  ABF = "ABF",
  CLASSIFIED_AREA_NO_PANELS = "Zone classée sans panneaux",
  PC = "PC",
  CARPORT = "Careport",
  EXTERNAL_CABLE_PASSAGE = "Passage cable extérieur",
  LINKY = "Linky",
  ELECTRICAL = "Électrique",
  ROOF = "Toiture",
  COMPETITION = "Concurrence",
  PERSONAL_REASONS = "Raisons perso",
  GHOSTING = "Ghosting",
  TERMINATION_IN_PROGRESS = "Résiliation en cours",
  DEPOSIT = "Acompte",
  WAITING_FOR_BANK_RESPONSE = "Attente retour banque",
  ENSOL_FINANCING_REQUEST = "Demande financement Ensol",
  FINANCING_REFUSED = "Financement refusé",
  REDUCTION_NUMBER_OF_PANELS = "Baisse nb de panneaux",
  SHADING = "Ombrage",
  INCREASE_QUOTE_PRICE = "Augmentation prix du devis",
  AESTHETIC_CHANGE = "Changement esthétique",
  TERRACOTTA_PV = "PV terracotta",
}

export const isAbortReason = (value: string): value is AbortReason => {
  return _.includes(Object.values(AbortReason), value)
}
