import { Stack } from "@mantine/core"

import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"

import { FileUploader } from "./FileUploader"
import { BaseFileFieldProps } from "./types"

type Props = BaseFileFieldProps & {
  value?: string | null
  error?: string
  onChange: (filePath: string | null) => void
}

export const FileField = ({
  value,
  error,
  previewProps,
  onChange,
  inputProps,
}: Props) => (
  <Stack gap="4" w={{ base: "100%", md: FILE_THUMBNAIL_WIDTH_IN_PX }}>
    {value ? (
      <MultiFilePreview
        filesPaths={[value]}
        onClear={() => onChange(null)}
        {...previewProps}
      />
    ) : (
      <FileUploader
        width={FILE_THUMBNAIL_WIDTH_IN_PX}
        onSuccess={({ filePath }) => onChange(filePath)}
        error={error}
        {...inputProps}
      />
    )}
  </Stack>
)
