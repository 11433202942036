import { Group } from "@mantine/core"

import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"

import { FileUploader } from "./FileUploader"
import { BaseFileFieldProps } from "./types"

type Props = BaseFileFieldProps & {
  value?: string[]
  error?: string
  onChange: (filePath: string[]) => void
  placeholderImage?: string
}

export const MultiFileField = ({
  value = [],
  error,
  onChange,
  inputProps,
  previewProps,
  placeholderImage,
}: Props) => (
  <Group wrap="nowrap" gap="8">
    {value.length > 0 && (
      <MultiFilePreview
        filesPaths={value}
        onClear={(filePath: string) =>
          onChange(value.filter((path) => path !== filePath))
        }
        {...previewProps}
      />
    )}
    <FileUploader
      fileUploadedCount={value.length}
      width={
        value.length > 0
          ? { base: "100%", md: 70 }
          : { base: "100%", md: FILE_THUMBNAIL_WIDTH_IN_PX }
      }
      onSuccess={({ filePath }) => onChange([...value, filePath])}
      error={error}
      {...inputProps}
      placeholderImage={placeholderImage}
    />
  </Group>
)
