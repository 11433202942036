import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { RoofSectionConfig } from "@ensol/types/forms/houses"

import {
  getOrientation,
  ORIENTATIONS,
} from "@ensol/shared/entities/houses/orientation"

export const DEFAULT_INCLINATION = 20
export const DEFAULT_ORIENTATION = 180

export const DEFAULT_ROOF_SECTION: RoofSectionConfig = {
  orientation: DEFAULT_ORIENTATION,
  inclination: DEFAULT_INCLINATION,
}

export const FLAT_ROOF_SECTION: RoofSectionConfig = {
  inclination: 10,
  orientation: DEFAULT_ORIENTATION,
}

export const sortRoofSections = <T extends RoofSectionConfig>(
  roofSections: T[],
): T[] => {
  return _.sortBy(roofSections, (section) =>
    Math.abs(section.orientation - 180),
  )
}

export const addPanelsToFirstRoofSection = (
  roofSections: RoofSectionConfig[],
  panelsCount: number,
): InstallationsResponses.PhotovoltaicInstallation["roofSectionsWithPanels"] => {
  return roofSections.map((section, index) => {
    return {
      panelsCount: index === 0 ? panelsCount : 0,
      roofSection: {
        id: `section-${section.orientation}-${section.inclination}-${index}`, // Temporary  to let computation run before saving a house
        ...section,
      },
    }
  })
}

export const formatRoofSectionName = (
  roofSection: RoofSectionConfig,
  hasGroundInstallation: boolean,
  hasFlatRoof: boolean,
): string => {
  if (
    roofSection.orientation === FLAT_ROOF_SECTION.orientation &&
    roofSection.inclination === FLAT_ROOF_SECTION.inclination
  ) {
    if (hasGroundInstallation) {
      return "Pose au sol"
    }

    if (hasFlatRoof) {
      return "Toit plat"
    }
  }

  return `${
    ORIENTATIONS[getOrientation(roofSection.orientation)].name
  } - ${roofSection.inclination}°`
}
