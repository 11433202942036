import { ExtraWorkType } from "@ensol/shared/material/extraWorks"
import * as z from "zod"

export const extraWorksSchema = z.object({
  extraWorks: z.array(
    z.object({
      type: z.nativeEnum(ExtraWorkType, {
        invalid_type_error: "Champ obligatoire",
      }),
      name: z.string().min(1, "Champ obligatoire"),
      category: z.string().min(1, "Champ obligatoire"),
      description: z.string().min(1, "Champ obligatoire"),
      price: z
        .number({
          invalid_type_error: "Champ obligatoire",
        })
        .nonnegative(),
    }),
  ),
})
