import { Card, Text } from "@mantine/core"
import { Handle, Position } from "reactflow"

import { MilestoneNode } from "@ensol/entool/components/entities/Project/MilestonesStepper"

export const STEP_WIDTH_IN_PX = 150

type Props<MilestoneType> = {
  data: MilestoneNode<MilestoneType>["data"]
  currentMilestone: MilestoneType | null
}

export const Milestone = <MilestoneType,>({
  data,
  currentMilestone,
}: Props<MilestoneType>) => {
  const isActive = currentMilestone === data.id

  return (
    <Card
      withBorder
      shadow={isActive ? "lg" : "none"}
      w={STEP_WIDTH_IN_PX}
      p="8"
      radius="8"
      bg={isActive ? `${data.color}.2` : "white"}
      style={({ colors }) => ({
        borderColor: isActive ? colors[data.color][4] : colors.gray[4],
        borderWidth: isActive ? 3 : 2,
      })}
    >
      <Handle type="target" position={Position.Left} id="leftHandle" />
      <Text fz="sm">{data.label}</Text>
      <Handle type="source" position={Position.Right} id="rightHandle" />
    </Card>
  )
}
