import { showNotification } from "@mantine/notifications"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { Location } from "@ensol/types/forms/houses"
import { CreateInstallationInput } from "@ensol/types/forms/installations"
import {
  InstallationEnergyConsumptionInput,
  InstallationEnergyProductionInput,
  InstallationEnergyStatsInput,
} from "@ensol/types/forms/installations/energy"
import { HouseSignedInstallations } from "@ensol/types/installation"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"
import { hasPanelsInstallation } from "@ensol/shared/entities/installations/characteristics"

import { httpClient } from "@ensol/entool/backend/axios"

type UseInstallationEnergyProductionInput = {
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<
    CreateInstallationInput,
    | "photovoltaicInstallation"
    | "extraPanelsInstallation"
    | "horizonProfileFilePath"
  >
}

export const useEnergyProductionPerDataSource = ({
  coords,
  installation,
}: UseInstallationEnergyProductionInput) =>
  useQuery<Record<ProductionDataSource, number> | null>({
    queryKey: [
      "energy",
      "production",
      coords,
      _.pick(installation.photovoltaicInstallation, [
        "panelType",
        "panelsCount",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      _.pick(installation.extraPanelsInstallation, [
        "panelType",
        "panelsCount",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      installation.horizonProfileFilePath,
    ],
    queryFn: async () => {
      if (!hasPanelsInstallation(installation)) {
        return null
      }

      try {
        const yearlyProductions = await Promise.all(
          Object.values(ProductionDataSource).map(async (source) => {
            const payload = {
              coords,
              ...installation,
              productionDataSource: source,
            } satisfies InstallationEnergyProductionInput

            const response = await httpClient.post<EnergyResponses.Production>(
              "/energy/production",
              payload,
            )
            return { source, yearlyProduction: response.data?.yearlyProduction }
          }),
        )

        return yearlyProductions.reduce(
          (energyProductionPerDataSource, { source, yearlyProduction }) => ({
            ...energyProductionPerDataSource,
            [source]: yearlyProduction,
          }),
          {} as Record<ProductionDataSource, number>,
        )
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul de la production",
          message:
            "La production des différentes sources n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })

        return null
      }
    },
  })

type UseInstallationEnergyStatsInput = {
  installationId?: string
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<
    CreateInstallationInput,
    | "photovoltaicInstallation"
    | "extraPanelsInstallation"
    | "batteryInstallation"
    | "productionDataSource"
    | "consumptionDataSource"
    | "yearlyConsumption"
    | "horizonProfileFilePath"
  >
  switchgridOrderId: string | null
  signedInstallations?: HouseSignedInstallations
}

export const useInstallationEnergyStats = ({
  installationId,
  coords,
  installation,
  switchgridOrderId,
  signedInstallations,
}: UseInstallationEnergyStatsInput) =>
  useQuery({
    queryKey: [
      "energy",
      "stats",
      coords,
      _.pick(installation.photovoltaicInstallation, [
        "panelType",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      _.pick(installation.extraPanelsInstallation, [
        "panelType",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      installation.batteryInstallation,
      installation.productionDataSource,
      installation.consumptionDataSource,
      installation.yearlyConsumption,
      installation.horizonProfileFilePath,
      switchgridOrderId,
    ],
    queryFn: async () => {
      if (
        !hasPanelsInstallation(installation) &&
        !signedInstallations?.photovoltaicInstallation &&
        !signedInstallations?.extraPanelsInstallations.length
      ) {
        return null
      }

      try {
        const payload = {
          installationId,
          coords,
          switchgridOrderId,
          ...installation,
          signedInstallations,
        } satisfies InstallationEnergyStatsInput

        const response = await httpClient.post<EnergyResponses.Stats>(
          "/energy/stats",
          payload,
        )
        return response.data
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul des données énergétiques",
          message:
            "La production, la consommation et le taux d'autoconsommation n'ont pas pu être récupérés. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })
        return null
      }
    },
  })

const fetchEnergyConsumption = async (
  input: InstallationEnergyConsumptionInput,
) => {
  try {
    const response = await httpClient.post<EnergyResponses.Consumption>(
      "/energy/consumption",
      input,
    )

    return response.data
  } catch (error) {
    showNotification({
      title: `Erreur lors de la récupération de la consommation ${input.consumptionDataSource}`,
      message:
        "La production des différentes sources n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
      color: "red",
    })

    return null
  }
}

export const useBillConsumption = (yearlyConsumption: number) => {
  return useQuery({
    queryKey: ["energy", "consumption", "bill", yearlyConsumption],
    queryFn: async () => {
      return fetchEnergyConsumption({
        consumptionDataSource: ConsumptionDataSource.BILL,
        yearlyConsumption,
      })
    },
  })
}

export const useSwitchgridConsumption = (
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>,
) => {
  const { switchgridOrder, switchgridConsent } = house

  return useQuery({
    queryKey: [
      "energy",
      "consumption",
      "switchgrid",
      switchgridOrder?.orderId,
      switchgridOrder?.orderStatus,
    ],
    queryFn: async () => {
      if (
        switchgridConsent === null ||
        switchgridOrder === null ||
        switchgridOrder.orderId === null ||
        (switchgridOrder.orderStatus !== OrderStatus.SUCCEEDED &&
          switchgridOrder.orderStatus !== OrderStatus.SOME_REQUESTS_SUCCEEDED &&
          switchgridOrder.orderStatus !== OrderStatus.RETRY_LATER) ||
        switchgridConsent.expirationDate < new Date()
      ) {
        return null
      }

      return fetchEnergyConsumption({
        consumptionDataSource: ConsumptionDataSource.SWITCHGRID,
        switchgridOrderId: switchgridOrder.orderId,
      })
    },
  })
}

export const useRealDataConsumption = (
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>,
  installationId?: string,
) => {
  return useQuery({
    queryKey: [
      "energy",
      "consumption",
      "real",
      installationId,
      house.switchgridOrder?.orderId,
      house.switchgridOrder?.orderStatus,
    ],
    queryFn: async () => {
      if (!installationId) return null

      return fetchEnergyConsumption({
        installationId,
        switchgridOrderId: house.switchgridOrder?.orderId,
        consumptionDataSource: ConsumptionDataSource.REAL,
      })
    },
  })
}
