import { useQuery } from "@tanstack/react-query"

import { TownHallsResponses } from "@ensol/types/endpoints/townHalls"

import { httpClient } from "@ensol/entool/backend/axios"

export const useGetTownHalls = () => {
  return useQuery<TownHallsResponses.TownHall[]>({
    queryKey: ["townHalls"],
    queryFn: async () => {
      const response =
        await httpClient.get<TownHallsResponses.TownHallsList>("/town-halls")
      return response.data.results
    },
  })
}

export const useGetTownHall = (townHallId: string | null) => {
  const { data: townHalls, isPending } = useGetTownHalls()

  return {
    townHall: townHalls?.find((townHall) => townHall.id === townHallId),
    isPending,
  }
}
