import { IconX, IconCalendarPause } from "@tabler/icons-react"
import { z } from "zod"

import { beforeVisitStepsFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { BeforeVisitCompletedForm } from "./components/BeforeVisitCompletedForm"

export const Pending = ({ project, ...props }: StepComponentProps) => (
  <BeforeVisitCompletedForm
    schema={beforeVisitStepsFormSchema}
    initialValues={getInitialValues(beforeVisitStepsFormSchema, project)}
    project={project}
    actions={[
      {
        action: "cannotSchedule",
        label: "Impossible à planifier",
        color: "red",
        Icon: IconX,
      },
      {
        action: "hold",
        label: "Non planifiable, à rappeler",
        color: "orange",
        Icon: IconCalendarPause,
      },
      {
        action: "validate",
        label: "Planifier la visite technique",
        validateAction: validateAction(
          z.object({
            technicalVisitInstallerId: z.string({
              invalid_type_error: "Champ obligatoire",
            }),
            technicalVisitStartDate: z.date({
              invalid_type_error: "Champ obligatoire",
            }),
          }),
        ),
      },
    ]}
    {...props}
  />
)
