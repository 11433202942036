import { CheckboxProps, Checkbox as MantineCheckbox } from "@mantine/core"

export const Checkbox = (props: CheckboxProps) => (
  <MantineCheckbox
    {...props}
    size="md"
    styles={(theme) => ({
      label: { color: theme.colors.gray[7], fontWeight: 500 },
    })}
  />
)
