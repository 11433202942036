import { Group, Badge, Text, ThemeIcon } from "@mantine/core"
import { IconChecklist, IconBellRinging, IconEye } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { ProjectTaskType } from "@ensol/types/project"

import { TaskDetails } from "../config"

type ProjectTaskDetailsProps = {
  taskDetails: TaskDetails
  type: ProjectTaskType
  project: ProjectResponses.ProjectOverview
}

export const ProjectTaskDetails = ({
  taskDetails: { label, color, ExtraInfoComponent },
  type,
  project,
}: ProjectTaskDetailsProps) => {
  return (
    <Group gap={8}>
      <Badge
        variant="light"
        color={color}
        size="sm"
        styles={{
          label: { display: "flex", alignItems: "center", gap: 4 },
        }}
      >
        <ThemeIcon c={color}>
          <ProjectTaskIcon type={type} />
        </ThemeIcon>
        {label}
      </Badge>
      {ExtraInfoComponent && <ExtraInfoComponent project={project} />}
    </Group>
  )
}

type ProjectTaskIconProps = {
  type: ProjectTaskType
}

const ProjectTaskIcon = ({ type }: ProjectTaskIconProps) => {
  switch (type) {
    case ProjectTaskType.TODO:
      return <IconChecklist />
    case ProjectTaskType.REMINDER:
      return <IconBellRinging />
    case ProjectTaskType.MONITORING:
      return <IconEye />
    default:
      return null
  }
}

export const SchedulingIssueReasonsInfo = ({
  project,
}: {
  project: ProjectResponses.ProjectOverview
}) => {
  return (
    project.schedulingIssueMainReason &&
    project.schedulingIssueSubReason && (
      <Text c="red" style={{ fontSize: 12 }}>
        Raison :{" "}
        <b>
          {project.schedulingIssueMainReason} (
          {project.schedulingIssueSubReason})
        </b>
      </Text>
    )
  )
}
