import { Badge } from "@mantine/core"
import { IconBarrierBlock, IconCheck } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { TechnicalVisitStep } from "@ensol/shared/entities/projects/processes"

type Props = {
  project: UnpackArray<ProjectResponses.ProjectsList>
}

export const TechnicalVisitBadge = ({ project }: Props) => {
  const isValidated =
    project.technicalVisitStep === TechnicalVisitStep.VALIDATED
  const hasRequiredWorks = project.technicalVisitRequiredWorks.length > 0

  return (
    <Badge
      color={hasRequiredWorks ? "orange" : isValidated ? "green" : "red"}
      variant="outline"
      leftSection={
        isValidated ? (
          <IconCheck size="14" />
        ) : hasRequiredWorks ? (
          <IconBarrierBlock size="14" />
        ) : null
      }
    >
      {hasRequiredWorks ? "Travaux" : isValidated ? "Oui" : "Non"}
    </Badge>
  )
}
