import { getInverter, InverterType } from "./inverters"

type SmartMeterInfo = {
  name: string
  price: number
  techDocUrl: string
  warranty: number
}

const SMART_METER_FOR_CENTRAL_INVERTER: SmartMeterInfo = {
  name: "Huawei Smart Dongle Wlan-FE",
  price: 100,
  techDocUrl:
    "https://solar.huawei.com/-/media/Solar/attachment/pdf/mea/datasheet/SmartDongle-WLAN-FE.pdf",
  warranty: 2,
}

const SMART_METER_FOR_MICRO_INVERTER: SmartMeterInfo = {
  name: "Enphase Envoy-S Metered",
  price: 320,
  techDocUrl:
    "https://enphase.com/sites/default/files/2021-08/fiche_technique_r_seau_enphase.pdf",
  warranty: 5,
}

export const getPhotovoltaicSmartMeter = (
  inverterType: InverterType,
): SmartMeterInfo => {
  const inverter = getInverter(inverterType)
  return inverter.isCentralInverter
    ? SMART_METER_FOR_CENTRAL_INVERTER
    : SMART_METER_FOR_MICRO_INVERTER
}
