export const MountingType = {
  ON_WALL: "ON_WALL",
  ON_FLOOR: "ON_FLOOR",
} as const

export const MOUNTING_TYPE_LABELS: Record<MountingType, string> = {
  [MountingType.ON_WALL]: "Mural",
  [MountingType.ON_FLOOR]: "Sol",
}

export type MountingType = keyof typeof MountingType
