import { FollowUpStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { InstallationToValidate } from "./1-InstallationToValidate"
import { LessThan1MonthProducing } from "./2-LessThan1MonthProducing"
import { OnboardingPending } from "./3-OnboardingPending"
import { OnboardingDone } from "./4-OnboardingDone"
import { MoreThan3MonthsProducing } from "./5-MoreThan3MonthsProducing"
import { MoreThan6MonthsProducing } from "./6-MoreThan6MonthsProducing"
import { MoreThan12MonthsProducing } from "./7-MoreThan12MonthsProducing"

export const getFollowUpStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case FollowUpStep.INSTALLATION_TO_VALIDATE:
      return { StepComponent: InstallationToValidate, isCancellable: false }
    case FollowUpStep.LESS_THAN_1_MONTH_PRODUCING:
      return { StepComponent: LessThan1MonthProducing }
    case FollowUpStep.ONBOARDING_PENDING:
      return { StepComponent: OnboardingPending }
    case FollowUpStep.ONBOARDING_DONE:
      return { StepComponent: OnboardingDone }
    case FollowUpStep.MORE_THAN_3_MONTHS_PRODUCING:
      return { StepComponent: MoreThan3MonthsProducing }
    case FollowUpStep.MORE_THAN_6_MONTHS_PRODUCING:
      return { StepComponent: MoreThan6MonthsProducing }
    case FollowUpStep.MORE_THAN_12_MONTHS_PRODUCING:
      return { StepComponent: MoreThan12MonthsProducing }
    default:
      return {
        StepComponent: null,
      }
  }
}
