import { activationPendingStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/OpsProject/processes/Enedis/components/EnedisForm"
import { ENEDIS_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Enedis/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

export const ActivationPending = ({
  project,
  processId,
}: StepComponentProps) => (
  <EnedisForm
    schema={activationPendingStepFormSchema}
    initialValues={{
      ...getInitialValues(activationPendingStepFormSchema, project),
      ...pickDefinedProperties(ENEDIS_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "Mis en service" }]}
  />
)
