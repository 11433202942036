import { Prospect } from "@ensol/types/prisma-client"

export const FILE_UPLOAD_FIELD_NAME = "file"

export const getFileBaseUrl = (apiBaseUrl: string) => `${apiBaseUrl}/files`

export const getProspectFileName = (
  documentName: string,
  prospect: Pick<Prospect, "firstName" | "lastName">,
) => `${documentName}-${prospect.firstName}-${prospect.lastName}`

export const getExtension = (fileName: string) => fileName.split(".").pop()
