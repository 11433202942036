type OptimizerInfo = {
  name: string
  power: number
  price: number
  warranty: number
  techDocUrl: string
}

export const OptimizerType = {
  HUAWEI_SUN_2000_450: "HUAWEI_SUN_2000_450",
  HUAWEI_SUN_2000_600: "HUAWEI_SUN_2000_600",
} as const

export type OptimizerType = keyof typeof OptimizerType

export const OPTIMIZERS: Record<OptimizerType, OptimizerInfo> = {
  HUAWEI_SUN_2000_450: {
    name: "Huawei Sun2000 450W",
    power: 0.45,
    price: 60,
    warranty: 25,
    techDocUrl:
      "https://solar.huawei.com/en/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FSUN2000-450W-P.pdf",
  },
  HUAWEI_SUN_2000_600: {
    name: "Huawei Sun2000 600W",
    power: 0.6,
    price: 65,
    warranty: 25,
    techDocUrl:
      "https://cdn.estg.eu/p/2/2/9/7/2297e76d1bc48a44054c6cc16a146e6d81f23c34_Huawei_SUN2000_Optimizer_600W_P_GB.pdf?_gl=1*1nqz842*_gcl_au*MTk3NzgxNDA4NC4xNzMwMzAyNzI3",
  },
}

export const getOptimizer = (
  optimizerType?: OptimizerType | null,
): OptimizerInfo | undefined =>
  optimizerType ? OPTIMIZERS[optimizerType] : undefined
