import { useEffect } from "react"
import { useLocation, Outlet } from "react-router-dom"

const LEGACY_DOMAIN = "sales-tools.goensol.com"
const CURRENT_DOMAIN = "entool.goensol.com"

export const LegacyDomainRedirect = () => {
  const location = useLocation()

  useEffect(() => {
    if (window.location.hostname === LEGACY_DOMAIN) {
      const newUrl = `https://${CURRENT_DOMAIN}${location.pathname}${location.search}${location.hash}`
      window.location.replace(newUrl)
    }
  }, [location])

  return <Outlet />
}
