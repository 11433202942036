import { ProjectResponses } from "@ensol/types/endpoints/projects"

export const PRELIMINARY_REQUEST_PROPERTIES: (keyof ProjectResponses.Project)[] =
  [
    "preliminaryRequestNotes",
    "preliminaryRequestLastContactDate",
    "preliminaryRequestReference",
    "preliminaryRequestSubmissionDate",
    "preliminaryRequestExpectedValidationDate",
    "preliminaryRequestDeadlineDate",
    "preliminaryRequestRequestedChangesPaths",
    "preliminaryRequestAcknowledgmentPath",
    "preliminaryRequestABFAcknowledgmentPath",
    "preliminaryRequestAcknowledgmentExtraPaths",
    "preliminaryRequestAcknowledgedDate",
    "preliminaryRequestApprovalPaths",
    "preliminaryRequestRejectionPaths",
    "preliminaryRequestApprovalDate",
  ]
