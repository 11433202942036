import { FormErrors, useForm, zodResolver } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  visitUpdateHouseSchema,
} from "@ensol/types/forms/houses"

import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export const useHouseForm = ({
  id: houseId,
  existingInstallationCapacity,
  existingInstallationContract,
  existingInstallationAge,
  constructionYear,
  surface,
  floors,
  isIndividualHouse,
  hasAsbestos,
  isHistoricBuilding,
  fromStreetPhotos,
  fromFrontPhotos,
  electricMeterPhotos,
  pdl,
  hasLinky,
  electricalPanelSurroundingsPhotos,
  electricalPanelPhotos,
  isAtticAccessible,
  atticPhotos,
  roofFramingMaterial,
  roofPhotos,
  potentialShading,
  isPowerLineNearby,
  isPodNeeded,
  otherPhotos,
  equipments,
  hasUnRegulatedConstruction,
  unRegulatedConstructionNote,
}: HousesResponses.House) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(houseId)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const houseForm = useForm({
    validateInputOnChange: true,
    validate: (values) => {
      const formErrors: FormErrors = zodResolver(visitUpdateHouseSchema)(values)
      const isUnRegulatedConstructionNoteValid =
        values.hasUnRegulatedConstruction !== "yes" ||
        values.unRegulatedConstructionNote !== ""
      if (!isUnRegulatedConstructionNoteValid) {
        return {
          ...formErrors,
          unRegulatedConstructionNote: "Champ obligatoire",
        }
      }
      return formErrors
    },
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.VISIT,
      existingInstallationCapacity,
      existingInstallationContract,
      existingInstallationAge,
      constructionYear,
      surface,
      floors,
      isIndividualHouse,
      hasAsbestos,
      isHistoricBuilding,
      fromStreetPhotos,
      fromFrontPhotos,
      electricMeterPhotos,
      pdl: pdl ?? "",
      hasLinky,
      electricalPanelSurroundingsPhotos,
      electricalPanelPhotos,
      isAtticAccessible,
      atticPhotos,
      roofFramingMaterial,
      roofPhotos,
      potentialShading,
      isPowerLineNearby,
      isPodNeeded,
      otherPhotos,
      equipments,
      hasUnRegulatedConstruction,
      unRegulatedConstructionNote: unRegulatedConstructionNote ?? "",
    },
  })

  return houseForm
}
