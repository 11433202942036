import { orderDeliveredStepSchema } from "@ensol/types/forms/projects/hardware"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HardwareForm } from "@ensol/entool/pages/OpsProject/processes/Hardware/components/HardwareForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const OrderInstalled = ({ project, ...props }: StepComponentProps) => (
  <HardwareForm
    schema={orderDeliveredStepSchema}
    initialValues={getInitialValues(orderDeliveredStepSchema, project)}
    project={project}
    actions={[]}
    {...props}
  />
)
