import { DEFAULT_HOUSE_CONSTRUCTION_YEAR } from "@ensol/shared/entities/houses"
import { MountingType } from "@ensol/shared/entities/installations/batteries"
import { EvChargerSubsidyType } from "@ensol/shared/entities/installations/subsidies/evCharger"
import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { BatteryType } from "@ensol/shared/material/batteries"
import { CurrentType } from "@ensol/shared/material/currentType"
import { EvChargerType } from "@ensol/shared/material/evCharger"
import { InverterType } from "@ensol/shared/material/photovoltaic/inverters"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { RoofType } from "@ensol/shared/material/roofType"

export const DEFAULT_PHOTOVOLTAIC_INSTALLATION = {
  panelType: PanelType.DUALSUN_500_TOPCON,
  inverterType: InverterType.ENPHASE_IQ_8_P,
  optimizerType: null,
  optimizerCount: null,
  extraWorks: [],
  subsidyType: PhotovoltaicSubsidyType.AUTO_CONSUMPTION,
}

export const DEFAULT_BATTERY_INSTALLATION = {
  type: BatteryType.ENPHASE_5P,
  mountingType: MountingType.ON_FLOOR,
  extraWorks: [],
}

export const DEFAULT_EV_CHARGER_INSTALLATION = {
  type: EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO,
  additionalCost: 0,
  subsidyType: EvChargerSubsidyType.TAX_REDUCTION,
  extraWorks: [],
}

export const DEFAULT_HOUSE_CONFIG = {
  hasGroundInstallation: false,
  constructionYear: DEFAULT_HOUSE_CONSTRUCTION_YEAR,
  surface: null,
  floors: null,
  isIndividualHouse: null,
  hasAsbestos: null,
  isHistoricBuilding: null,
  isAtticAccessible: null,
  roofFramingMaterial: null,
  currentType: CurrentType.SINGLE_PHASE,
  roofType: RoofType.OTHER,
  pdl: null,
  hasLinky: null,
  equipments: [],
}
