import { createContext, ReactNode, useState } from "react"

type Props = {
  children: ReactNode
}

export const ProjectSearchContext = createContext({
  search: "",
  setSearch: (_: string) => {},
})

export const ProjectSearchProvider = ({ children }: Props) => {
  const [search, setSearch] = useState("")

  return (
    <ProjectSearchContext.Provider value={{ search, setSearch }}>
      {children}
    </ProjectSearchContext.Provider>
  )
}
