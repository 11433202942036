import { useMediaQuery } from "@mantine/hooks"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { useListProjectsOverviewQuery } from "@ensol/entool/queries/projects"
import { devices } from "@ensol/entool/styles/theme"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjectsOverview = (
  currentTab: string,
  showOnlyActionNeeded: boolean,
) => {
  const isMobile = useMediaQuery(devices.sm)
  const { search, operationsOwnerId, installerId } = useProjectsSearch()
  const statusesFilters = isProjectStatus(currentTab)
    ? [{ status: currentTab, isIncluded: true }]
    : [
        { status: ProjectStatus.CREATED, isIncluded: false },
        ...(search === ""
          ? [
              { status: ProjectStatus.DONE, isIncluded: false },
              { status: ProjectStatus.ABORTED, isIncluded: false },
            ]
          : []),
      ]

  return useListProjectsOverviewQuery({
    filters: {
      search,
      operationsOwnerId: operationsOwnerId ?? undefined,
      installerId: installerId ?? undefined,
      statuses: statusesFilters,
      onlyActionNeeded: showOnlyActionNeeded,
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })
}

const isProjectStatus = (tab: string): tab is ProjectStatus =>
  tab in ProjectStatus
