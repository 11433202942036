import { SimpleGrid, Text } from "@mantine/core"

type Props = {
  name: string
  children: React.ReactNode
}

export const Info = ({ name, children }: Props) => (
  <SimpleGrid cols={2} spacing={16}>
    <Text fw={500} c="gray.7">
      {name}
    </Text>
    {typeof children === "string" ? (
      <Text size="sm" fw="600" px="12">
        {children}
      </Text>
    ) : (
      children
    )}
  </SimpleGrid>
)
