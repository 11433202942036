import { notifications, showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { FilesResponses } from "@ensol/types/endpoints/files"

import { downloadFile } from "@ensol/shared/utils/download"
import { isEnsolError } from "@ensol/shared/utils/errors"
import { FILE_UPLOAD_FIELD_NAME } from "@ensol/shared/utils/files"

import { httpClient } from "@ensol/entool/backend/axios"

export const useUploadFileMutation = (
  uploadUrl: string,
  onSuccess: (data: FilesResponses.UploadFile) => void,
) => {
  return useMutation<
    FilesResponses.UploadFile,
    FilesResponses.UploadFile | Error,
    File | null
  >({
    mutationFn: async (file) => {
      if (!file) {
        return { error: "No file uploaded" }
      }

      const form = new FormData()
      form.append(FILE_UPLOAD_FIELD_NAME, file)

      const response = await httpClient.post(uploadUrl, form)
      return response.data
    },
    onSuccess: async (data) => {
      if (data.error) {
        showNotification({
          title: "Erreur de chargement du fichier",
          message: data.error,
          color: "red",
        })
        return
      }
      if (!data.filePath) {
        showNotification({
          title: "Erreur de chargement du fichier",
          message: "Le fichier n'a pas été uploadé. Merci de réessayer",
          color: "red",
        })
        return
      }
      onSuccess(data)
      notifications.show({ message: "Fichier uploadé !" })
    },
    onError: (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur de chargement du fichier",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur de chargement du fichier",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useDownloadFileMutation = (filePath: string, fileName: string) => {
  return useMutation<string | undefined>({
    mutationFn: async () => {
      const response = await httpClient.get(`/files/${filePath}/download`, {
        responseType: "blob",
      })
      return response.data
    },
    onSuccess: (data) => {
      if (!data) return
      downloadFile(data, fileName, "text/csv")
    },
    onError: () => {
      showNotification({
        message:
          "Nous n'avons pas pu télécharger le fichier, merci de réessayer",
        color: "red",
      })
    },
  })
}
