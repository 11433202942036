import { Group, Loader, Paper, Stack, Text } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"

import { useGetTownHall } from "@ensol/entool/queries/townHalls"

type Props = {
  townHallId: string | null
}

export const TownHallRequirements = ({ townHallId }: Props) => {
  const { townHall, isPending } = useGetTownHall(townHallId)

  if (townHallId === null) {
    return null
  }

  if (!isPending && townHall === undefined) {
    return null
  }

  const hasRequirements =
    !isPending && townHall !== undefined && townHall.requirements !== ""

  return (
    <Paper
      withBorder
      shadow="none"
      w={400}
      px={24}
      py={16}
      bg={isPending ? "white" : hasRequirements ? "orange.0" : "teal.0"}
    >
      {isPending ? (
        <Stack flex="1" h="60" align="center" justify="center">
          <Loader size="sm" color="gray" />
        </Stack>
      ) : (
        <Stack>
          <Stack gap="8">
            <Group gap="8">
              {hasRequirements && <IconAlertTriangle size={20} />}
              <Text fw="bold">
                {hasRequirements
                  ? "Exigences de la mairie"
                  : "Pas d'exigences de la mairie"}
              </Text>
            </Group>
            <Text size="sm" fs="italic">
              {hasRequirements
                ? `Attention, la mairie de ${townHall?.cityName} a les exigences suivantes :`
                : `Nous avons déjà travaillé avec la mairie de ${townHall?.cityName} mais elle n'a pas d'exigences spécifiques connues.`}
            </Text>
          </Stack>
          {hasRequirements && <Text size="sm">{townHall.requirements}</Text>}
        </Stack>
      )}
    </Paper>
  )
}
