import { Button, Group, Text, ThemeIcon, Tooltip } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconChevronsRight,
  IconCheckbox,
  IconCircleX,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ProjectActions } from "@ensol/entool/pages/OpsProject/ProjectActions"
import { devices } from "@ensol/entool/styles/theme"

type Props = {
  project: ProjectResponses.Project
  submitProject: () => void
  isSubmitting: boolean
}

const BUTTON_WIDTH_IN_PX = 300

export const Actions = ({ project, submitProject, isSubmitting }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (project.status === ProjectStatus.CREATED) {
    return (
      <Tooltip label="Le projet n'est pas encore signé">
        <Button disabled w={BUTTON_WIDTH_IN_PX}>
          Transmettre le projet aux Ops
        </Button>
      </Tooltip>
    )
  }

  if (project.isSalesVisitValidated === false) {
    return (
      <Group>
        <Button
          mt="4"
          leftSection={<IconChevronsRight size={14} />}
          loading={isSubmitting}
          onClick={submitProject}
          w={BUTTON_WIDTH_IN_PX}
        >
          Transmettre le projet aux Ops
        </Button>
        {!isMobile && <ProjectActions project={project} />}
      </Group>
    )
  }

  if (project.status === ProjectStatus.ABORTED) {
    return (
      <Group align="center" gap="4" pt="4">
        <ThemeIcon c="red">
          <IconCircleX />
        </ThemeIcon>
        <Text size="sm" c="red">
          Projet annulé
        </Text>
      </Group>
    )
  }

  return (
    <Group align="center" gap="4" pt="4">
      <ThemeIcon c="green">
        <IconCheckbox />
      </ThemeIcon>
      <Text size="sm" c="green.9">
        Projet commencé
      </Text>
    </Group>
  )
}
