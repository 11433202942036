import dayjs from "dayjs"

import { getInstallationAlert } from "@ensol/shared/entities/installations/alerts"

import { InsightsBadges } from "@ensol/entool/components/entities/Installation/InsightsBadges"
import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const FollowUpInfo = ({
  project: { followUpProductionStartDate, installation },
}: InfoComponentProps) => {
  const daysSinceProduction = dayjs().diff(followUpProductionStartDate, "day")

  return (
    <ProcessInfos
      infos={[
        {
          name: "Produit depuis",
          value: followUpProductionStartDate
            ? `${daysSinceProduction} jours`
            : null,
        },
        {
          name: "Alertes",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={installation.openedAlerts.map(
                (type) => getInstallationAlert(type).shortName,
              )}
            />
          ),
          isHidden: installation.openedAlerts.length === 0,
        },
        {
          name: "Conseil",
          dataType: "component",
          value: <InsightsBadges insights={installation.insights} />,
          isHidden: installation.insights.length === 0,
        },
      ]}
    />
  )
}
