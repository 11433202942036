import { Group, Badge } from "@mantine/core"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

type Props = {
  installation: Pick<
    InstallationsResponses.Installation,
    | "photovoltaicInstallation"
    | "extraPanelsInstallation"
    | "batteryInstallation"
    | "evChargerInstallation"
  >
}

export const InstallationProducts = ({ installation }: Props) => (
  <Group gap="4">
    {installation.photovoltaicInstallation !== null && (
      <Badge variant="light">PV</Badge>
    )}
    {installation.extraPanelsInstallation !== null && (
      <Badge variant="light">Panels</Badge>
    )}
    {installation.batteryInstallation !== null && (
      <Badge variant="light">Batterie</Badge>
    )}
    {installation.evChargerInstallation !== null && (
      <Badge variant="light">Borne</Badge>
    )}
  </Group>
)
