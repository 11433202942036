import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { UpdateInstallationStep } from "@ensol/entool/components/form/Installation/UpdateInstallationStep"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"

export const UpdateInstallation = ({
  project,
  processId,
}: StepComponentProps) => {
  const simulationQuery = useGetInstallationQuery(project.installationId)

  return (
    <QueryWrapper query={simulationQuery}>
      {({ data: installation }) => (
        <UpdateInstallationStep
          installation={installation}
          project={project}
          processId={processId}
          actions={[
            {
              action: "validate",
              label: "Bon de fin de chantier envoyé",
            },
          ]}
        />
      )}
    </QueryWrapper>
  )
}
