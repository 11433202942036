import { PENNYLANE_URL } from "@ensol/entool/config"

export const getPennylaneEstimateLink = (
  firstName: string,
  lastName: string,
) => {
  const filter = [
    {
      field: "customer",
      operator: "search_all",
      value: `${firstName} ${lastName}`,
    },
  ]
  return `${PENNYLANE_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`
}
