import _ from "lodash"
import { useCallback } from "react"

export const useDebounce = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  method: (...args: any[]) => void,
  timeout: number,
) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  return useCallback(_.debounce(method, timeout), [method])
}
