import { Button, Group, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import {
  UpdateProspectInput,
  updateProspectSchema,
} from "@ensol/types/forms/prospects"

import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateProspectMutation } from "@ensol/entool/queries/prospects"
import { getZIndex } from "@ensol/entool/styles/z-index"
import { GENDER_OPTIONS } from "@ensol/entool/utils/prospects/options"

type Props = {
  prospect: ProspectResponses.Prospect
  onSuccess?: () => void
}

export const ProspectForm = ({
  prospect: { id, gender, firstName, lastName, email, phone, hubspotContactId },
  onSuccess,
}: Props) => {
  const { mutateAsync: updateProspect, isPending } = useUpdateProspectMutation(
    id,
    onSuccess,
  )

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateProspectSchema),
    initialValues: {
      gender,
      firstName,
      lastName,
      email,
      phone,
      hubspotContactId,
    },
  })

  return (
    <Stack pt="16" pb="60">
      <Field name="Civilité" noBorder withAsterisk>
        <RadioGroup
          options={GENDER_OPTIONS}
          {...form.getInputProps("gender")}
        />
      </Field>
      <Field name="Prénom" noBorder withAsterisk>
        <TextInput w={FIELD_WIDTH} {...form.getInputProps("firstName")} />
      </Field>
      <Field name="Nom" noBorder withAsterisk>
        <TextInput w={FIELD_WIDTH} {...form.getInputProps("lastName")} />
      </Field>
      <Field name="Email" noBorder withAsterisk>
        <TextInput w={FIELD_WIDTH} {...form.getInputProps("email")} />
      </Field>
      <Field name="Téléphone" noBorder withAsterisk>
        <TextInput w={FIELD_WIDTH} {...form.getInputProps("phone")} />
      </Field>
      <Group
        pos="fixed"
        bottom={0}
        left={0}
        p={16}
        w="100%"
        bg="white"
        justify="flex-end"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Button
          disabled={!form.isValid()}
          loading={isPending}
          onClick={() => {
            // We need to do this so typescript understands gender is not null here
            updateProspect(form.values as UpdateProspectInput)
          }}
        >
          Enregistrer
        </Button>
      </Group>
    </Stack>
  )
}
