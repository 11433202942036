import {
  Table,
  UnstyledButton,
  Group,
  Center,
  MantineStyleProps,
} from "@mantine/core"
import {
  IconChevronUp,
  IconChevronDown,
  IconSelector,
} from "@tabler/icons-react"

type SortedThProps = {
  children: React.ReactNode
  reversed: boolean
  sorted: boolean
  onSort(): void
}

export function SortedTh({
  children,
  reversed,
  sorted,
  onSort,
  ...props
}: SortedThProps & MantineStyleProps) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector

  return (
    <Table.Th {...props}>
      <UnstyledButton onClick={onSort}>
        <Group justify="space-between">
          {children}
          <Center>
            <Icon />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  )
}
