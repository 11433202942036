import { Stack, Button, Textarea, SimpleGrid } from "@mantine/core"
import { IconCurrencyEuro } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  DepositToSendStepInput,
  depositToSendStepFormSchema,
} from "@ensol/types/forms/projects/payment"

import { computeQuoteDetails } from "@ensol/shared/entities/quotes/details"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT, formatCurrency } from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { PennylaneLink } from "@ensol/entool/pages/OpsProject/processes/Payment/components/PennylaneLink"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"
import { useCreateEstimateMutation } from "@ensol/entool/queries/projects"
import { getPennylaneEstimateLink } from "@ensol/entool/utils/pennylane"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

type Props = {
  project: ProjectResponses.Project
}

export const GenerateEstimateForm = ({ project }: Props) => {
  const { mutateAsync: createEstimate, isPending: isEstimatePending } =
    useCreateEstimateMutation(project.id)

  const installationQuery = useGetInstallationQuery(project.installationId)

  const form = useProcessForm<DepositToSendStepInput>({
    schema: depositToSendStepFormSchema,
    initialValues: {
      paymentNotes: project.paymentNotes,
      paymentHoldEndDate: project.paymentHoldEndDate,
      fundingSource: project.fundingSource,
    },
    projectId: project.id,
    processId: "paymentStep",
  })

  const {
    activeQuote,
    house: { prospect },
  } = project.installation

  return (
    <SimpleGrid cols={2} spacing={32}>
      <Stack>
        <FundingSourceField
          fundingSourceStatus={project.fundingSourceStatus}
          form={form}
        />
        <Field name="Notes" noBorder>
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("paymentNotes")}
          />
        </Field>
        <Field
          name="Date de relance"
          subtitle="À remplir si la facture ne peut pas encore être envoyée."
          noBorder
        >
          <DateInput
            w={FIELD_WIDTH}
            clearable
            valueFormat={DISPLAY_DATE_FORMAT}
            minDate={new Date()}
            {...form.getInputProps("paymentHoldEndDate")}
          />
        </Field>
      </Stack>
      <Stack>
        <Field name="Devis" noBorder>
          {project.pennylaneEstimateId === null ? (
            <Button
              loading={isEstimatePending}
              w="fit-content"
              onClick={() => createEstimate()}
              leftSection={<IconCurrencyEuro />}
              color="rgb(0, 102, 102)"
            >
              Générer le devis sur Pennylane
            </Button>
          ) : (
            <PennylaneLink
              label="Voir sur Pennylane"
              href={getPennylaneEstimateLink(
                prospect.firstName,
                prospect.lastName,
              )}
            />
          )}
        </Field>
        <QueryWrapper query={installationQuery}>
          {({ data: installation }) => (
            <Field
              name="Montant total TTC devis"
              subtitle="Vérifiez que ce montant correspond à celui dans PennyLane."
              noBorder
            >
              <TextInfo
                value={formatCurrency(
                  activeQuote?.details?.totals.finalCost ??
                    computeQuoteDetails(installation).totals.finalCost,
                )}
              />
            </Field>
          )}
        </QueryWrapper>

        <Field name="Devis" noBorder>
          {activeQuote !== null && activeQuote.quotePath !== null ? (
            <MultiFilePreview
              filesPaths={[activeQuote.quotePath]}
              fileName={getProspectFileName("devis", prospect)}
            />
          ) : (
            <NotFound type="file" />
          )}
        </Field>
      </Stack>
    </SimpleGrid>
  )
}
