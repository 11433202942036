import { ColorSwatch, Group, Text } from "@mantine/core"

import { ProjectEventStatus } from "@ensol/shared/entities/projects/events"

import {
  EVENT_STATUSES_COLORS,
  EVENT_STATUSES_NAMES,
  EXTERNAL_EVENT_COLOR,
} from "@ensol/entool/utils/projects/events"

export const Legend = () => {
  return (
    <Group style={{ flexWrap: "nowrap" }}>
      <Text size="sm" fw="bold">
        Légende :{" "}
      </Text>
      {Object.values(ProjectEventStatus).map((status) => (
        <Group key={status} gap="4">
          <ColorSwatch size="20" color={EVENT_STATUSES_COLORS[status]} />
          <Text size="xs">{EVENT_STATUSES_NAMES[status]}</Text>
        </Group>
      ))}
      <Group gap="4">
        <ColorSwatch
          size="20"
          color="white"
          styles={{
            root: { border: `2px solid ${EXTERNAL_EVENT_COLOR}` },
            shadowOverlay: { display: "none" },
          }}
        />
        <Text size="xs">
          Évènement du calendrier installateur ou jour ferié
        </Text>
      </Group>
    </Group>
  )
}
