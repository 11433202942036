import { Group, Avatar, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import { LabelledInsert } from "@ensol/entool/components/LabelledInsert"

type Props = {
  project: ProjectResponses.Project
  background: string
}

export const ProjectOwners = ({ project, background }: Props) => {
  const { salesOwner, operationsOwner, adminOwner, technicalExpert } = project

  return (
    <>
      <Owner
        name={salesOwner ? getFullName(salesOwner) : null}
        avatarUrl={salesOwner ? salesOwner.avatarUrl : null}
        label="Responsable AE"
        background={background}
      />
      <Owner
        name={operationsOwner ? getFullName(operationsOwner) : null}
        avatarUrl={operationsOwner ? operationsOwner.avatarUrl : null}
        label="Responsable Ops"
        background={background}
      />
      <Owner
        name={adminOwner ? getFullName(adminOwner) : null}
        avatarUrl={adminOwner ? adminOwner.avatarUrl : null}
        label="Responsable Admin"
        background={background}
      />
      <Owner
        name={technicalExpert ? getFullName(technicalExpert) : null}
        avatarUrl={technicalExpert ? technicalExpert.avatarUrl : null}
        label="Expert Technique"
        background={background}
      />
    </>
  )
}

type OwnerProps = {
  name: string | null
  avatarUrl: string | null
  label: string
  background: string
}

const Owner = ({ name, avatarUrl, label, background }: OwnerProps) => (
  <LabelledInsert label={label} bg={background}>
    {name !== null ? (
      <Group gap="4" mih="24">
        {avatarUrl !== null && <Avatar src={avatarUrl} size="24" />}
        <Text size="sm" fw="500">
          {name}
        </Text>
      </Group>
    ) : (
      <Text size="sm" c="gray" mih="24">
        -
      </Text>
    )}
  </LabelledInsert>
)
