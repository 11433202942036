import * as z from "zod"
import { transformSchemaForApi } from "../utils"

export const pendingStepFormSchema = z.object({
  enedisNotes: z.string().optional(),
  electricityBillPath: z.string().nullable().optional(),
  enedisReference: z.string().nullable().optional(),
})

export const incompleteStepFormSchema = pendingStepFormSchema.extend({
  enedisIncompleteFileReasons: z.array(z.string()).min(1, "Champ obligatoire"),
  enedisIncompleteFileDetails: z.string().min(1, "Champ obligatoire"),
})

export const submittedStepFormSchema = incompleteStepFormSchema.extend({
  enedisReference: z.string().min(1, "Champ obligatoire"),
  enedisIncompleteFileReasons: z.array(z.string()),
  enedisIncompleteFileDetails: z.string(),
})

export const activationPendingStepFormSchema = submittedStepFormSchema.extend({
  enedisActivationDate: z.date({ invalid_type_error: "Champ obligatoire" }),
})

export type EnedisStepInput =
  | z.infer<typeof pendingStepFormSchema>
  | z.infer<typeof incompleteStepFormSchema>
  | z.infer<typeof submittedStepFormSchema>
  | z.infer<typeof activationPendingStepFormSchema>

export const enedisProcessSchema = transformSchemaForApi(
  activationPendingStepFormSchema,
)
