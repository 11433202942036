import { ActionIcon } from "@mantine/core"
import { ReactNode } from "react"

type Props = {
  Icon: ReactNode
  onClick?: () => void
  href?: string
  isExternal?: boolean
  ariaLabel?: string
}

export const IconButton = ({
  Icon,
  onClick,
  href,
  isExternal,
  ariaLabel,
}: Props) => (
  <ActionIcon
    component={href ? "a" : "button"}
    variant="outline"
    w="32"
    h="32"
    c="blue.4"
    style={({ colors }) => ({ borderColor: colors.gray[4] })}
    aria-label={ariaLabel}
    href={href}
    onClick={onClick}
    target={isExternal ? "_blank" : undefined}
  >
    {Icon}
  </ActionIcon>
)
