import { IconArrowBackUp } from "@tabler/icons-react"

import { schedulingOnHoldStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { BeforeVisitCompletedForm } from "./components/BeforeVisitCompletedForm"

export const Requested = ({ project, ...props }: StepComponentProps) => (
  <BeforeVisitCompletedForm
    schema={schedulingOnHoldStepFormSchema}
    initialValues={getInitialValues(schedulingOnHoldStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reschedule",
        label: "Repasser en prêt à planifier",
        color: "orange",
        Icon: IconArrowBackUp,
        confirmationMessage:
          "L'évènement installateur sera supprimé et l'installateur sera averti. Continuer ?",
      },
      {
        action: "validate",
        label: "Passer en prévue",
        confirmationMessage:
          "Cette étape devrait être passée automatiquement lors de la réponse de l'installateur à l'évènement, êtes-vous sûr de vouloir la passer manuellement ?",
      },
    ]}
    {...props}
  />
)
