import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel"
import { Modal, Image, Stack, Text } from "@mantine/core"
import { IconFileBroken } from "@tabler/icons-react"
import { useState } from "react"

import { getExtension } from "@ensol/shared/utils/files"

import { getFileUrl } from "@ensol/entool/utils/files"
import { isImage } from "@ensol/entool/utils/files/images"

type Props = {
  filePaths: string[]
  currentSlideIndex: number
  isPreviewOpen: boolean
  onClose: () => void
}

const TRANSITION_DURATION = 200
const MODAL_VERTICAL_PADDING_IN_PX = 50
const MODAL_HORIZONTAL_PADDING_IN_PX = 35
const CAROUSEL_HEIGHT = `calc(100dvh - ${MODAL_VERTICAL_PADDING_IN_PX * 2}px)`
const CAROUSEL_WIDTH = `calc(100dvw - ${MODAL_HORIZONTAL_PADDING_IN_PX * 2}px)`

export const MultiFilePreviewModal = ({
  filePaths,
  currentSlideIndex,
  isPreviewOpen,
  onClose,
}: Props) => {
  const [embla, setEmbla] = useState<Embla | null>(null)

  useAnimationOffsetEffect(embla, TRANSITION_DURATION)

  return (
    <Modal
      opened={isPreviewOpen}
      onClose={onClose}
      withCloseButton={false}
      size="95dvw"
      p="0"
      centered
      styles={{
        content: { height: "100%", background: "unset", boxShadow: "none" },
        inner: {
          padding: `${MODAL_VERTICAL_PADDING_IN_PX}px ${MODAL_HORIZONTAL_PADDING_IN_PX}px`,
        },
        body: { padding: 0, height: "100%", overflow: "hidden" },
      }}
    >
      <Carousel
        slideSize={CAROUSEL_WIDTH}
        withControls={filePaths.length > 1}
        align="start"
        initialSlide={currentSlideIndex}
        getEmblaApi={setEmbla}
        loop
        styles={{
          slide: { height: CAROUSEL_HEIGHT },
        }}
      >
        {filePaths.map((path) => {
          const type = getExtension(path)
          const url = getFileUrl(path)

          if (type === undefined) {
            return null
          }

          if (type === "pdf") {
            return (
              <Carousel.Slide key={path}>
                <object
                  key={path}
                  data={url}
                  width="100%"
                  style={{ height: "100%", overflow: "auto" }}
                />
              </Carousel.Slide>
            )
          }

          if (isImage(type)) {
            return (
              <Carousel.Slide key={path}>
                <Image
                  key={url}
                  src={url}
                  h={CAROUSEL_HEIGHT}
                  onClick={onClose}
                  fit="contain"
                />
              </Carousel.Slide>
            )
          }

          return (
            <Carousel.Slide key={url}>
              <Stack align="center" justify="center" h="100%" bg="white">
                <IconFileBroken size={60} />
                <Text size="xl">
                  La visualisation des fichiers .{type} n&apos;est pas supportée
                </Text>
              </Stack>
            </Carousel.Slide>
          )
        })}
      </Carousel>
    </Modal>
  )
}
