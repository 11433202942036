import { Group, Stack, StackProps, Text, TitleOrder } from "@mantine/core"
import { IconBolt, IconHome, IconSkewY } from "@tabler/icons-react"
import { useState } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { HouseSignedInstallations } from "@ensol/types/installation"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { getBattery } from "@ensol/shared/material/batteries"
import { CURRENT_CONNECTIONS } from "@ensol/shared/material/currentType"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"
import { ROOF_TYPES } from "@ensol/shared/material/roofType"

import { Map } from "@ensol/entool/components/Map"
import { Section } from "@ensol/entool/components/Section"
import { SignedInstallationCard } from "@ensol/entool/components/form/House/SignedInstallationCard"

import { EditHouseButton } from "./EditHouseButton"

type Props = {
  house: ProspectResponses.HouseWithRoofSections
  signedInstallations?: HouseSignedInstallations
  onSuccess?: (house: ProspectResponses.HouseWithRoofSections) => void
  titleOrder?: TitleOrder
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
} & StackProps

export const HouseSection = ({
  house,
  titleOrder,
  panelsLayout,
  signedInstallations,
  onSuccess,
  ...props
}: Props) => {
  const [savedHouse, setSavedHouse] =
    useState<ProspectResponses.HouseWithRoofSections>(house)

  return (
    <Section {...props} title="Maison" icon={IconHome} titleOrder={titleOrder}>
      {savedHouse && (
        <Group w="100%" align="flex-start" wrap="nowrap">
          <Stack w="100%" gap={8} miw={300}>
            <Text fw="bold">
              {savedHouse.streetAddress}
              <br />
              {savedHouse.postcode} {savedHouse.city}
            </Text>
            <Group gap={8} c="gray.6">
              <IconBolt strokeWidth={1.5} />
              <Text>{CURRENT_CONNECTIONS[savedHouse.currentType].name}</Text>
            </Group>
            <Group gap={8} c="gray.6">
              <IconSkewY strokeWidth={1.5} />
              <Text>{ROOF_TYPES[savedHouse.roofType].name}</Text>
            </Group>
            <EditHouseButton
              house={savedHouse}
              onSuccess={(house) => {
                setSavedHouse(house)
                onSuccess?.(house)
              }}
              buttonProps={{ w: 180, variant: "outline" }}
            />
            {signedInstallations && (
              <Stack mt={20} gap={8}>
                <Text fw={600} fz="md">
                  Produits installés
                </Text>
                <Group>
                  <SignedInstallationCard
                    installationType="photovoltaic"
                    productNames={[
                      `${signedInstallations.photovoltaicInstallation.panelsCount} ${getPanel(signedInstallations.photovoltaicInstallation.panelType).name}`,
                      getInverter(
                        signedInstallations.photovoltaicInstallation
                          .inverterType,
                      ).name,
                    ]}
                  />
                  <SignedInstallationCard
                    installationType="panels"
                    productNames={signedInstallations.extraPanelsInstallations.map(
                      (installation) =>
                        `${installation.panelsCount} ${getPanel(installation.panelType).name}`,
                    )}
                  />
                  <SignedInstallationCard
                    installationType="battery"
                    productNames={signedInstallations.batteryInstallations.map(
                      (installation) => getBattery(installation.type).name,
                    )}
                  />
                  <SignedInstallationCard
                    installationType="evCharger"
                    productNames={signedInstallations.evChargerInstallations.map(
                      (installation) => getEvCharger(installation.type).name,
                    )}
                  />
                </Group>
              </Stack>
            )}
          </Stack>
          <Stack w="100%" maw={500}>
            <Map
              mapId="simulator-map"
              coords={getCoords(savedHouse)}
              panelsLayout={panelsLayout}
              style={{ height: 250 }}
            />
          </Stack>
        </Group>
      )}
    </Section>
  )
}
