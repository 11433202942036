import { Alert, MantineColor } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

type Props = {
  title?: string
  message: string
  color?: MantineColor
}

export const InfoBox = ({ title, message, color = "gray" }: Props) => (
  <Alert color={color} title={title} icon={<IconAlertCircle />}>
    {message}
  </Alert>
)
