import { Group, Badge, MantineStyleProps } from "@mantine/core"

import {
  getInstallationInsight,
  InstallationInsightType,
} from "@ensol/shared/entities/installations/insights"

type Props = {
  insights: InstallationInsightType[]
} & MantineStyleProps

export const InsightsBadges = ({ insights, ...props }: Props) => (
  <Group gap="4" {...props}>
    {insights.length > 0
      ? insights.map((insight) => {
          const { name } = getInstallationInsight(insight)

          return (
            <Badge key={insight} color="blue" variant="outline">
              {name}
            </Badge>
          )
        })
      : "-"}
  </Group>
)
