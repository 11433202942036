import { Group, Button, ActionIcon, TitleOrder } from "@mantine/core"
import { IconTrash, IconPlus, TablerIcon } from "@tabler/icons-react"

import { Section } from "@ensol/entool/components/Section"

type Props = {
  type: "Photovoltaic" | "Panels" | "Battery" | "EvCharger"
  installationHasProduct: boolean
  addProduct: () => void
  deleteProduct: () => void
  title: string
  titleOrder?: TitleOrder
  Icon: TablerIcon
  Actions?: React.ReactNode
  addButtonLabel: string
  children: React.ReactNode
}

export const ProductWrapper = ({
  type,
  installationHasProduct,
  addProduct,
  deleteProduct,
  title,
  titleOrder,
  Icon,
  Actions,
  addButtonLabel,
  children,
}: Props) => (
  <Section
    title={title}
    icon={Icon}
    titleOrder={titleOrder}
    isCollapsible={installationHasProduct}
    Actions={
      installationHasProduct && (
        <Group>
          {Actions}
          <ActionIcon
            aria-label="Supprimer"
            variant="light"
            p="4"
            color="red"
            onClick={deleteProduct}
          >
            <IconTrash color="red" />
          </ActionIcon>
        </Group>
      )
    }
  >
    {installationHasProduct ? (
      children
    ) : (
      <Button
        variant="outline"
        h="60"
        leftSection={<IconPlus />}
        onClick={addProduct}
        data-test={`add${type}Button`}
      >
        {addButtonLabel}
      </Button>
    )}
  </Section>
)
