import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { CetelemForm } from "./components/CetelemForm"

export const RequestSent = ({ project, processId }: StepComponentProps) => {
  return (
    <CetelemForm
      initialValues={getInitialValues(cetelemFormSchema, project)}
      schema={cetelemFormSchema}
      processId={processId}
      project={project}
      actions={[
        {
          action: "validate",
          label: "Demande signée à envoyer",
          confirmationMessage:
            "Cette étape devrait être passée automatiquement lorsque que la demande est signée, êtes-vous sûr de vouloir la passer manuellement ?",
        },
      ]}
    />
  )
}
