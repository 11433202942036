import { IconX } from "@tabler/icons-react"

import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { RejectActionModal } from "./components/RejectActionModal"
import { TechnicalVisitForm } from "./components/TechnicalVisitForm"
import { ValidateActionModal } from "./components/ValidateActionModal"

export const BeingProcessed = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[
      {
        label: "Rejeter",
        color: "red",
        Icon: IconX,
        CustomActionModal: RejectActionModal,
      },
      {
        label: "Valider",
        CustomActionModal: ValidateActionModal,
      },
    ]}
    {...props}
  />
)
