import _ from "lodash"

export const getHousePhotoFileName = (
  houseStreetAddress: string,
  fileNameExtension: string,
) => {
  const houseSlug = _.kebabCase(houseStreetAddress)

  return `${houseSlug}-${fileNameExtension}`
}
