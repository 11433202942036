import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { CetelemForm } from "./components/CetelemForm"

export const FinalPaid = ({ project, processId }: StepComponentProps) => {
  return (
    <CetelemForm
      schema={cetelemFormSchema}
      initialValues={getInitialValues(cetelemFormSchema, project)}
      project={project}
      processId={processId}
      actions={[]}
    />
  )
}
