import { Stack, Loader, Text, Image, ThemeIcon } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import { IconCameraPlus, IconFileAlert, IconUpload } from "@tabler/icons-react"
import { useState } from "react"

import { ColorGroup } from "@ensol/shared/styles/colors"
import { getExtension } from "@ensol/shared/utils/files"

import { FILE_THUMBNAIL_HEIGHT_IN_PX } from "@ensol/entool/components/entities/File/constants"
import { useUploadFileMutation } from "@ensol/entool/queries/files"
import {
  compressImage,
  convertHeicToJpeg,
  isImage,
} from "@ensol/entool/utils/files/images"

import { FileUploaderProps } from "./types"

export const FileUploader = ({
  accept,
  capture,
  width,
  onSuccess,
  uploadUrl = "/files",
  error,
  placeholderImage,
  fileUploadedCount,
}: FileUploaderProps) => {
  const [isFileProcessing, setIsFileProcessing] = useState(false)
  const { mutateAsync: uploadFile, isPending } = useUploadFileMutation(
    uploadUrl,
    onSuccess,
  )

  return (
    <Dropzone
      onDrop={async ([file]: File[]) => {
        if (isImage(getExtension(file.name))) {
          let image = file
          setIsFileProcessing(true)
          if (file.type === "image/heic") {
            image = await convertHeicToJpeg(file)
          }
          compressImage(image, async (compressedFile) => {
            await uploadFile(compressedFile)
            setIsFileProcessing(false)
          })
        } else {
          await uploadFile(file)
        }
      }}
      accept={accept}
      inputProps={{ capture }}
      w={width}
      style={{ cursor: "pointer" }}
    >
      <Stack gap="4">
        <DropzoneContent
          Component={Dropzone.Idle}
          color={error ? "red" : "gray"}
        >
          {isPending || isFileProcessing ? (
            <Loader color="gray.4" size={32} />
          ) : capture === "environment" ? (
            fileUploadedCount === 0 && placeholderImage ? (
              <>
                <ThemeIcon
                  c={error ? "red.4" : "gray.4"}
                  style={{
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                    zIndex: 1,
                  }}
                >
                  <IconCameraPlus />
                </ThemeIcon>
                <Image
                  src={placeholderImage}
                  w="100%"
                  h="100%"
                  fit="contain"
                  opacity={0.3}
                />
              </>
            ) : (
              <ThemeIcon c={error ? "red.4" : "gray.4"}>
                <IconCameraPlus />
              </ThemeIcon>
            )
          ) : (
            <ThemeIcon c={error ? "red.4" : "gray.4"}>
              <IconUpload />
            </ThemeIcon>
          )}
        </DropzoneContent>
        <DropzoneContent Component={Dropzone.Accept} color="green">
          <ThemeIcon c="green.4">
            <IconUpload />
          </ThemeIcon>
        </DropzoneContent>
        <DropzoneContent Component={Dropzone.Reject} color="red">
          <ThemeIcon c={error ? "red.4" : "gray.4"}>
            <IconFileAlert />
          </ThemeIcon>
          <Text c="red" size="xs">
            Type de fichier invalide
          </Text>
        </DropzoneContent>
        {error && (
          <Text c="red" size="xs">
            {error}
          </Text>
        )}
      </Stack>
    </Dropzone>
  )
}

type DropzoneContentProps = {
  Component: React.ComponentType<{ children: React.ReactNode }>
  color: ColorGroup
  children: React.ReactNode
}

const DropzoneContent = ({
  Component,
  color,
  children,
}: DropzoneContentProps) => (
  <Component>
    <Stack
      h={FILE_THUMBNAIL_HEIGHT_IN_PX}
      align="center"
      justify="center"
      pos="relative"
      style={({ colors }) => ({
        borderRadius: 8,
        border: `1px solid ${colors[color][4]}`,
      })}
    >
      {children}
    </Stack>
  </Component>
)
