import * as z from "zod"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { typedKeys } from "@ensol/types/utils"

// Ideally this could be called directly in useProcessForm but
// it's pretty hard to make typescript manage type constraints correctly
export const getInitialValues = <K extends keyof ProjectResponses.Project>(
  schema: z.ZodObject<Record<K, z.ZodType>>,
  project: ProjectResponses.Project,
) =>
  typedKeys(schema.shape).reduce(
    (initialValues, field) => {
      return {
        ...initialValues,
        [field]: project[field],
      }
    },
    {} as Pick<ProjectResponses.Project, K>,
  )
