import { Group, Text, Stack, List, ThemeIcon } from "@mantine/core"
import {
  IconAlertCircle,
  IconCircleDotted,
  IconInfoCircle,
  IconTools,
  IconGavel,
  IconCashBanknote,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

import { Tooltip } from "@ensol/entool/components/Tooltip"
import { FundingSourceInfo } from "@ensol/entool/components/entities/Project/FundingSourceInfo"
import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/projects/fieldsLabels"

export const ProjectIndicators = ({
  project: {
    technicalVisitRequiredChanges,
    isActionNeeded,
    technicalVisitRequiredWorks,
    technicalVisitEndOfWorksDate,
    fundingSource,
    fundingSourceStatus,
  },
}: {
  project: ProjectResponses.ProjectOverview
}) => {
  return (
    <>
      <ThemeIcon color={isActionNeeded ? "red" : "gray"}>
        {isActionNeeded ? <IconAlertCircle /> : <IconCircleDotted />}
      </ThemeIcon>

      {technicalVisitRequiredChanges.length > 0 && (
        <Tooltip
          label={
            <Stack gap={4}>
              <Group gap={4}>
                <IconInfoCircle size={18} />
                <Text>Changement requis</Text>
              </Group>
              <List pl={8}>
                {technicalVisitRequiredChanges.map((change) => (
                  <List.Item key={change}>{change}</List.Item>
                ))}
              </List>
            </Stack>
          }
        >
          <ThemeIcon color="yellow.4">
            <IconTools />
          </ThemeIcon>
        </Tooltip>
      )}
      {technicalVisitRequiredWorks.length > 0 && (
        <Tooltip
          label={
            <Stack gap={4}>
              <Group gap={4}>
                <IconInfoCircle size={18} />
                <Text>Travaux requis</Text>
              </Group>
              <List pl={8}>
                {technicalVisitRequiredWorks.map((change) => (
                  <List.Item key={change}>{change}</List.Item>
                ))}
              </List>
              <Group gap={4}>
                <Text>
                  {PROJECT_FIELDS_LABELS.technicalVisitEndOfWorksDate} :
                </Text>
                <Text fw="bold">
                  {technicalVisitEndOfWorksDate
                    ? formatDate(
                        technicalVisitEndOfWorksDate,
                        SHORT_DISPLAY_DATE_FORMAT,
                      )
                    : "-"}
                </Text>
              </Group>
            </Stack>
          }
        >
          <ThemeIcon c="red.4">
            <IconGavel />
          </ThemeIcon>
        </Tooltip>
      )}
      {fundingSource && fundingSource !== FundingSource.EQUITY && (
        <Tooltip
          label={
            <FundingSourceInfo
              fundingSource={fundingSource}
              fundingSourceStatus={fundingSourceStatus}
            />
          }
        >
          <IconCashBanknote color="green" size={20} />
        </Tooltip>
      )}
    </>
  )
}
