import { queryClient } from "@ensol/entool/backend/queryClient"
import {
  ProspectHeader,
  ProspectHeaderProps,
} from "@ensol/entool/components/entities/Prospect/ProspectHeader"

type Props = ProspectHeaderProps & {
  projectId: string
}

export const ProjectHeader = ({ projectId, ...props }: Props) => (
  <ProspectHeader
    {...props}
    onProspectEditSuccess={async () =>
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      })
    }
  />
)
