import { Stack, Table, Title } from "@mantine/core"

import { formatDate } from "@ensol/shared/utils/format"

type Props = {
  transactions: { date: string; amount: number; currency: string }[]
}

export const TransactionsTable = ({ transactions }: Props) => {
  if (transactions.length === 0) {
    return null
  }

  return (
    <Stack gap="8" px="24" py="24">
      <Title order={6}>Transactions associées</Title>
      <Table
        striped
        data={{
          head: ["Date", "Montant"],
          body: transactions.map(({ date, amount, currency }) => [
            formatDate(date),
            `${amount} ${currency}`,
          ]),
        }}
      />
    </Stack>
  )
}
