import { useLocalStorage } from "@uidotdev/usehooks"
import { useState } from "react"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

export const useEventsMenu = () => {
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false)

  const [eventTypes, setEventTypes] = useLocalStorage(
    "eventsTypesFilter",
    Object.values(ProjectEventType),
  )
  const [installers, setInstallers] = useLocalStorage<string[]>(
    "eventsInstallerFilter",
    [],
  )
  const [coverageZones, setCoverageZones] = useLocalStorage<string[]>(
    "eventsCoverageZonesFilter",
    [],
  )
  const [showInstallerEvents, setShowInstallerEvents] = useLocalStorage(
    "showInstallerEvents",
    false,
  )
  const [showEventsWithoutInstaller, setShowEventsWithoutInstaller] =
    useLocalStorage("showEventsWithoutInstaller", false)
  const [showWeekEnds, setShowWeekEnds] = useLocalStorage("showWeekEnds", false)
  const [showPublicHolidays, setShowPublicHolidays] = useLocalStorage(
    "showPublicHolidays",
    false,
  )

  return {
    isMenuDrawerOpen,
    openMenuDrawer: () => setIsMenuDrawerOpen(true),
    closeMenuDrawer: () => setIsMenuDrawerOpen(false),
    eventTypes,
    setEventTypes,
    installers,
    setInstallers,
    coverageZones,
    setCoverageZones,
    showInstallerEvents,
    setShowInstallerEvents,
    showEventsWithoutInstaller,
    setShowEventsWithoutInstaller,
    showWeekEnds,
    setShowWeekEnds,
    showPublicHolidays,
    setShowPublicHolidays,
  }
}
