import { Drawer, Tabs, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"

import { Details } from "./Details"
import { ProjectDrawerTab } from "./useProjectDrawer"

type Props = {
  project: ProjectResponses.ProjectOverview | null
  activeTab: ProjectDrawerTab
  setActiveTab: (tab: ProjectDrawerTab) => void
  onClose: () => void
}

export const ProjectDrawer = ({
  project,
  activeTab,
  setActiveTab,
  onClose,
}: Props) => {
  return (
    <Drawer
      styles={{
        content: { display: "flex", flexDirection: "column" },
        body: { padding: 0, display: "flex", minHeight: 0, flex: 1 },
      }}
      opened={!!project}
      onClose={onClose}
      position="right"
      title={
        project && (
          <Text size="xl" h={44}>
            {getFullName(project.prospect)}
          </Text>
        )
      }
    >
      <Tabs
        color="black"
        p={16}
        value={activeTab}
        onChange={(tab) => setActiveTab(tab as ProjectDrawerTab)}
        styles={{
          root: {
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            flex: 1,
          },
          panel: {
            display: "flex",
            minHeight: 0,
            flex: 1,
          },
        }}
      >
        <Tabs.List grow>
          <Tabs.Tab value={ProjectDrawerTab.DETAILS}>
            <Text size="md">Détails</Text>
          </Tabs.Tab>
          <Tabs.Tab value={ProjectDrawerTab.NOTES}>
            <Text size="md">Notes</Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={ProjectDrawerTab.DETAILS}>
          {activeTab === ProjectDrawerTab.DETAILS && project && (
            <Details project={project} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value={ProjectDrawerTab.NOTES}>
          {activeTab === ProjectDrawerTab.NOTES && project && (
            <ProjectNotes projectId={project.id} justify="flex-end" />
          )}
        </Tabs.Panel>
      </Tabs>
    </Drawer>
  )
}
