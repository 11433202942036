import _ from "lodash"
import React from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import {
  ProcessId,
  ProcessStep,
} from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { FollowUpInfo } from "@ensol/entool/pages/OpsProjectsByProcess/infos/FollowUp"
import {
  PROJECTS_PROCESS_VIEWS,
  ProjectProcessView,
} from "@ensol/entool/utils/projects/views"

import { ConsuelInfo } from "./infos/Consuel"
import { EnedisInfo } from "./infos/Enedis"
import { HardwareInfo } from "./infos/Hardware"
import { InstallationInfo } from "./infos/Installation"
import { PaymentInfo } from "./infos/Payment"
import { PreliminaryRequestInfo } from "./infos/PreliminaryRequest"
import { TechnicalVisitInfo } from "./infos/TechnicalVisit"

export type OrderByOptions = [
  Array<keyof UnpackArray<ProjectResponses.ProjectsList>>,
  ("asc" | "desc")[],
]

export type InfoComponentProps = {
  project: UnpackArray<ProjectResponses.ProjectsList>
  milestoneId: ProcessStep | ProjectStatus
}

const PROCESS_VIEW_BY_ID = _.keyBy(PROJECTS_PROCESS_VIEWS, ({ id }) => id)

export const PROJECT_PROCESS_LIST_VIEWS: Record<
  ProcessId,
  ProjectProcessView<ProcessId> & {
    Component?: React.ComponentType<InfoComponentProps>
    orderBy: OrderByOptions
  }
> = {
  technicalVisitStep: {
    ...PROCESS_VIEW_BY_ID["technicalVisitStep"],
    Component: TechnicalVisitInfo,
    orderBy: [
      ["technicalVisitStartDate", "technicalVisitSchedulingHoldEndDate"],
      ["asc", "asc"],
    ],
  },
  preliminaryRequestStep: {
    ...PROCESS_VIEW_BY_ID["preliminaryRequestStep"],
    Component: PreliminaryRequestInfo,
    orderBy: [
      [
        "preliminaryRequestExpectedValidationDate",
        "preliminaryRequestLastContactDate",
      ],
      ["asc", "desc"],
    ],
  },

  greendealStep: {
    ...PROCESS_VIEW_BY_ID["greendealStep"],
    orderBy: [[], []],
  },
  installationStep: {
    ...PROCESS_VIEW_BY_ID["installationStep"],
    Component: InstallationInfo,
    orderBy: [
      [
        "installationStartDate",
        "installationSchedulingHoldEndDate",
        "preliminaryRequestExpectedValidationDate",
      ],
      ["asc", "asc", "asc"],
    ],
  },
  paymentStep: {
    ...PROCESS_VIEW_BY_ID["paymentStep"],
    Component: PaymentInfo,
    orderBy: [
      ["installationStartDate", "paymentHoldEndDate"],
      ["asc", "asc"],
    ],
  },
  hardwareStep: {
    ...PROCESS_VIEW_BY_ID["hardwareStep"],
    Component: HardwareInfo,
    orderBy: [[], []],
  },
  enedisStep: {
    ...PROCESS_VIEW_BY_ID["enedisStep"],
    Component: EnedisInfo,
    orderBy: [[], []],
  },
  consuelStep: {
    ...PROCESS_VIEW_BY_ID["consuelStep"],
    Component: ConsuelInfo,
    orderBy: [[], []],
  },
  edfOaStep: {
    ...PROCESS_VIEW_BY_ID["edfOaStep"],
    orderBy: [[], []],
  },
  followUpStep: {
    ...PROCESS_VIEW_BY_ID["followUpStep"],
    Component: FollowUpInfo,
    orderBy: [["followUpProductionStartDate"], ["asc"]],
  },
}
