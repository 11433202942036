import { EnedisStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const EnedisInfo = ({
  project: { enedisStep, enedisIncompleteFileReasons, enedisActivationDate },
}: InfoComponentProps) => (
  <ProcessInfos
    infos={[
      {
        name: "Incomplet",
        dataType: "component",
        value: <IssuesBadges issues={enedisIncompleteFileReasons} />,
        isHidden:
          enedisIncompleteFileReasons.length === 0 ||
          (enedisStep !== EnedisStep.INCOMPLETE &&
            enedisStep !== EnedisStep.SUBMITTED),
      },
      {
        name: "Mise en service",
        value: formatDate(enedisActivationDate, SHORT_DISPLAY_DATE_FORMAT),
        color:
          enedisActivationDate && enedisActivationDate <= new Date()
            ? "green.8"
            : "black",
        isHidden:
          enedisStep !== EnedisStep.ACTIVATION_PENDING &&
          enedisStep !== EnedisStep.ACTIVATED,
      },
    ]}
  />
)
