import { Button, Stack, ThemeIcon } from "@mantine/core"
import {
  IconCalendarMonth,
  IconUsers,
  IconHomeCog,
  IconChevronDown,
  IconChevronUp,
  TablerIcon,
  IconBriefcase,
  IconAlertCircle,
  IconHomeMove,
} from "@tabler/icons-react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

import classes from "./Menu.module.css"

const MENU_ITEMS = [
  { to: "/projects", name: "Projets", Icon: IconBriefcase },
  {
    name: "Opérations",
    Icon: IconHomeCog,
    subItems: [
      ...PROJECTS_PROCESS_VIEWS.map(({ id, name, color, Icon }) => ({
        to: `/projects/processes/${id}`,
        name,
        color,
        Icon,
      })),
      {
        to: "/projects/alerts",
        name: "Alertes",
        color: "red",
        Icon: IconAlertCircle,
      },
    ],
  },
  { to: "/events", name: "Calendrier", Icon: IconCalendarMonth },
  { to: "/prospects", name: "Prospects", Icon: IconUsers },
  { to: "/sales", name: "Tâches AE", Icon: IconHomeMove },
]

type MenuProps = {
  closeNavbar?: () => void
  toggleDesktop?: () => void
  desktopOpened?: boolean
}

const COMMON_MENU_BUTTON_PROPS = {
  variant: "subtle",
  color: "dark.4",
  w: "100%",
  fw: 400,
}

const CLOSED_NAVBAR_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  p: 0,
  h: 60,
  size: "11",
  styles: {
    inner: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    section: {
      marginTop: 8,
      marginRight: 0,
    },
  },
} as const

const OPENED_NAVBAR_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  h: 32,
  mt: 12,
  w: "100%",
  justify: "flex-start",
  px: 12,
  size: "md",
  fw: 400,
}

export const Menu = ({
  desktopOpened,
  closeNavbar,
  toggleDesktop,
}: MenuProps) => {
  return MENU_ITEMS.map(({ to, name, Icon, subItems }) =>
    to !== undefined ? (
      <Button
        key={to}
        component={Link}
        onClick={closeNavbar}
        leftSection={<Icon size={16} />}
        to={to}
        {...(desktopOpened === false
          ? CLOSED_NAVBAR_BUTTON_PROPS
          : OPENED_NAVBAR_BUTTON_PROPS)}
      >
        {name}
      </Button>
    ) : (
      <SubMenu
        key={name}
        name={name}
        Icon={<Icon size={16} />}
        subItems={subItems}
        onClick={closeNavbar}
        desktopOpened={desktopOpened}
        toggleDesktop={toggleDesktop}
      />
    ),
  )
}

type SubMenuProps = {
  name: string
  Icon: ReactNode
  subItems: {
    to: string
    name: string
    color: string
    Icon: TablerIcon
  }[]
  onClick?: () => void
  toggleDesktop?: () => void
  desktopOpened?: boolean
}

const SubMenu = ({
  name,
  Icon,
  subItems,
  toggleDesktop,
  desktopOpened,
  onClick,
}: SubMenuProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useLocalStorage(
    `${name} : isSubMenuOpen`,
    true,
  )

  return (
    <>
      <Button
        classNames={classes}
        leftSection={Icon}
        onClick={() => {
          if (desktopOpened === false) {
            toggleDesktop && toggleDesktop()
            setIsSubMenuOpen(true)
          } else setIsSubMenuOpen(!isSubMenuOpen)
        }}
        {...(desktopOpened === false
          ? CLOSED_NAVBAR_BUTTON_PROPS
          : OPENED_NAVBAR_BUTTON_PROPS)}
        rightSection={
          desktopOpened === false ? null : isSubMenuOpen ? (
            <IconChevronUp size={16} />
          ) : (
            <IconChevronDown size={16} />
          )
        }
      >
        {name}
      </Button>
      {isSubMenuOpen && desktopOpened !== false && (
        <Stack pl={4} gap={0}>
          {subItems.map(({ to, name, Icon, color }) => (
            <Button
              key={to}
              component={Link}
              to={to}
              {...OPENED_NAVBAR_BUTTON_PROPS}
              leftSection={
                <ThemeIcon color={color} variant="light" p={2}>
                  <Icon />
                </ThemeIcon>
              }
              onClick={() => {
                onClick?.()
              }}
            >
              {name}
            </Button>
          ))}
        </Stack>
      )}
    </>
  )
}
