import { Button } from "@mantine/core"
import { IconSend } from "@tabler/icons-react"
import React from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  EmailEvent,
  getProjectEmailData,
} from "@ensol/shared/entities/projects/emails"

import { useSendEmail } from "@ensol/entool/components/entities/Project/StepActions/useSendEmail"
import { getFileUrl } from "@ensol/entool/utils/files"

type Props = {
  eventName: EmailEvent
  project: ProjectResponses.Project
  children: React.ReactNode
}

export const EmailSender = ({ eventName, project, children }: Props) => {
  const { sendEmail, isEmailSending, areEventsLoading } = useSendEmail(
    project.id,
    project.installation.prospectId,
    eventName,
  )

  try {
    const emailData = getProjectEmailData(eventName, project, getFileUrl)

    return (
      <Button
        variant="light"
        disabled={areEventsLoading}
        loading={isEmailSending}
        leftSection={<IconSend size={20} />}
        onClick={() => sendEmail(emailData)}
      >
        {children}
      </Button>
    )
  } catch (error) {
    return null
  }
}
