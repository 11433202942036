import _ from "lodash"

import { FinancialData, MonthlyEnergySavings } from "@ensol/types/simulation"

import {
  computeMonthlyEnergyUsage,
  estimateEnergyPrice,
  YEARLY_MATERIAL_EFFICIENCY_LOSS,
  ELECTRICITY_PRICE_ANNUAL_RAISE,
} from "@ensol/shared/entities/installations/energy"
import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const REFERRAL_DISCOUNT = 250

type EnergyResellPriceInput = {
  subsidyType?: PhotovoltaicSubsidyType | null
  installedCapacity: number
  installationAge: number
}

// cf https://www.enerplan.asso.fr/tarif-achat-prix-installation-photovoltaique-100-kw
export const computeEnergyResellPrice = ({
  subsidyType,
  installedCapacity,
  installationAge,
}: EnergyResellPriceInput): number => {
  if (installationAge >= 20) {
    return 0
  }

  if (subsidyType === PhotovoltaicSubsidyType.AUTO_CONSUMPTION) {
    if (installedCapacity <= 9) {
      return 0.1269
    }
    return 0.0761
  }

  return 0
}

type MonthlyEnergySavingsInput = {
  year: number
  installationDate: Date
  monthlyBill: number
  yearlyConsumption: number
  installedCapacity: number
  autoConsumptionPercent: number
  estimatedYearlyProduction: number
  subsidyType?: PhotovoltaicSubsidyType
}

export const computeMonthlyEnergySavings = ({
  year,
  installationDate,
  monthlyBill,
  yearlyConsumption,
  installedCapacity,
  autoConsumptionPercent,
  estimatedYearlyProduction,
  subsidyType,
}: MonthlyEnergySavingsInput): MonthlyEnergySavings => {
  const { autoConsumedEnergy, resellableEnergy } = computeMonthlyEnergyUsage({
    year,
    installationDate,
    autoConsumptionPercent,
    estimatedYearlyProduction,
  })

  const autoConsumedAmount = roundDecimalNumber(
    autoConsumedEnergy *
      estimateEnergyPrice({
        year,
        installationDate,
        monthlyBill,
        yearlyConsumption,
      }),
  )

  const energyResellPrice = computeEnergyResellPrice({
    subsidyType,
    installedCapacity,
    installationAge: year - installationDate.getFullYear(),
  })

  const resellAmount = roundDecimalNumber(resellableEnergy * energyResellPrice)

  const autoConsumptionSavingsPartPercent = Math.round(
    (autoConsumedAmount / (autoConsumedAmount + resellAmount)) * 100,
  )
  const resellSavingsPartPercent = 100 - autoConsumptionSavingsPartPercent

  return {
    resellAmount,
    autoConsumedAmount,
    autoConsumptionSavingsPartPercent,
    resellSavingsPartPercent,
  }
}

type BreakEvenDateInput = {
  installationDate: Date
  monthlyBill: number
  yearlyConsumption: number
  installedCapacity: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  finalCostForPhotovoltaicAndBatteryWithSubsidies: number
  subsidyType?: PhotovoltaicSubsidyType
}

export const computeMonthsBeforeBreakEvenDate = ({
  installationDate,
  monthlyBill,
  yearlyConsumption,
  installedCapacity,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  finalCostForPhotovoltaicAndBatteryWithSubsidies,
  subsidyType,
}: BreakEvenDateInput): number => {
  const loopDate = new Date(installationDate)

  let monthsBeforeBreakEvenDate = 0
  let currentGain = 0

  while (currentGain < finalCostForPhotovoltaicAndBatteryWithSubsidies) {
    monthsBeforeBreakEvenDate++
    loopDate.setMonth(loopDate.getMonth() + 1)

    const loopYear = loopDate.getFullYear()

    const { autoConsumedAmount, resellAmount } = computeMonthlyEnergySavings({
      year: loopYear,
      installationDate,
      monthlyBill,
      yearlyConsumption,
      installedCapacity,
      autoConsumptionPercent,
      estimatedYearlyProduction,
      subsidyType,
    })

    currentGain += autoConsumedAmount + resellAmount
  }

  return monthsBeforeBreakEvenDate
}

type FinancialDataInput = {
  installationDate: Date
  monthlyBill: number
  yearlyConsumption: number
  installedCapacity: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  finalCostWithSubsidies: number
  subsidyType?: PhotovoltaicSubsidyType
}

export const computeFinancialData = ({
  installationDate,
  monthlyBill,
  yearlyConsumption,
  installedCapacity,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  finalCostWithSubsidies,
  subsidyType,
}: FinancialDataInput): FinancialData => {
  const year = installationDate.getFullYear()

  const costPerInstalledCapacity =
    finalCostWithSubsidies / (installedCapacity * 1000)

  const twentyFiveYearsEnergyProduction = _.sum(
    _.times(
      25,
      (i) => estimatedYearlyProduction * YEARLY_MATERIAL_EFFICIENCY_LOSS ** i,
    ),
  )
  const panelsEnergyProductionCost = roundDecimalNumber(
    finalCostWithSubsidies / twentyFiveYearsEnergyProduction,
  )

  const monthlyCostsWithoutPanels = _.times(31, (i) => ({
    year: year + i,
    cost: monthlyBill * ELECTRICITY_PRICE_ANNUAL_RAISE ** i,
  }))

  const monthlyCostsWithPanels = _.times(31, (i) => {
    const { autoConsumedAmount, resellAmount } = computeMonthlyEnergySavings({
      year: year + i,
      installationDate,
      monthlyBill,
      yearlyConsumption,
      installedCapacity,
      autoConsumptionPercent,
      estimatedYearlyProduction,
      subsidyType,
    })

    return {
      year: year + i,
      cost:
        monthlyBill * ELECTRICITY_PRICE_ANNUAL_RAISE ** i -
        (autoConsumedAmount + resellAmount),
    }
  })

  const billReductionRatio =
    1 - Math.min(monthlyCostsWithPanels[0].cost / monthlyBill, 1)

  const savingsPerYear = _.times(31, (i) => {
    return {
      year: year + i,
      cost:
        _.sum(
          _.times(i, (j) => {
            return (
              12 *
              (monthlyCostsWithoutPanels[j].cost -
                monthlyCostsWithPanels[j].cost)
            )
          }),
        ) - finalCostWithSubsidies,
    }
  })

  const firstYearMonthlyEnergySavings = computeMonthlyEnergySavings({
    year,
    installationDate,
    monthlyBill,
    yearlyConsumption,
    installedCapacity,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    subsidyType,
  })

  return {
    billReductionRatio,
    costPerInstalledCapacity,
    panelsEnergyProductionCost,
    oneYearSavings: savingsPerYear[1].cost + finalCostWithSubsidies,
    tenYearsSavings: savingsPerYear[10].cost + finalCostWithSubsidies,
    thirtyYearsSavings: savingsPerYear[30].cost + finalCostWithSubsidies,
    firstYearMonthlyEnergySavings,
    graphs: {
      savingsPerYear,
      monthlyCostsWithoutPanels,
      monthlyCostsWithPanels,
    },
  }
}

export const computeTotalDiscount = (discount?: number | null): number =>
  discount ?? 0
