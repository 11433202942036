import { waitingForStakeholderStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const IncompleteToSubmit = ({
  project,
  ...props
}: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={waitingForStakeholderStepFormSchema}
    initialValues={getInitialValues(
      waitingForStakeholderStepFormSchema,
      project,
    )}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Envoyé",
        confirmationMessage:
          "Avez-vous bien pensé à mettre à jour les dates d'échéance de la validation et de validation prévue ?",
      },
    ]}
    {...props}
  />
)
