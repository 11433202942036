import { Stack, Button, Modal, ScrollArea, Group, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconHomePlus, IconHome } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"

import { HouseForm } from "@ensol/entool/components/form/House/HouseForm"
import { useCreateProspectHouseMutation } from "@ensol/entool/queries/prospects"

type Props = {
  prospectId: string
}

export const CreateHouseButton = ({ prospectId }: Props) => {
  const navigate = useNavigate()
  const [isModalOpen, { open, close }] = useDisclosure(false)

  const { mutateAsync: createHouse, isPending: isCreatingHouse } =
    useCreateProspectHouseMutation(prospectId, (house) => {
      close()
      navigate(`/installations/new/${house.id}`)
    })

  return (
    <>
      <Stack align="center" my={24}>
        <Button
          variant="outline"
          leftSection={<IconHomePlus />}
          data-test="createHouseButton"
          onClick={open}
        >
          Créer une nouvelle maison
        </Button>
      </Stack>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={close}
        title={
          <Group gap={8}>
            <IconHome size={26} />
            <Title order={2}>Maison</Title>
          </Group>
        }
      >
        <HouseForm onSave={createHouse} isLoading={isCreatingHouse} />
      </Modal>
    </>
  )
}
