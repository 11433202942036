import { Modal, Stack, Button, ActionIcon, Select } from "@mantine/core"
import { IconBellOff } from "@tabler/icons-react"

import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { DateInput } from "@ensol/entool/components/form/DateInput"
import { useIgnoreAlert } from "@ensol/entool/pages/OpsProjectsAlerts/IgnoreAlert/useIgnoreAlert"
import { IGNORE_ALERT_REASON_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  alertId: string
}

export const IgnoreAlertButton = ({ alertId }: Props) => {
  const {
    ignoreForm,
    isIgnoreFormOpen,
    openIgnoreForm,
    closeIgnoreForm,
    onSubmit,
  } = useIgnoreAlert(alertId)

  return (
    <>
      <ActionIcon variant="outline" aria-label="Ignorer l'alerte">
        <IconBellOff onClick={openIgnoreForm} />
      </ActionIcon>
      <Modal
        title="Ignorer l'alerte"
        opened={isIgnoreFormOpen}
        onClose={closeIgnoreForm}
        centered
      >
        <Stack gap="8">
          <Select
            label="Raison"
            data={IGNORE_ALERT_REASON_OPTIONS}
            {...ignoreForm.getInputProps("ignoreReason")}
          />
          <DateInput
            valueFormat={DISPLAY_DATE_FORMAT}
            label="Jusqu'au"
            minDate={new Date()}
            {...ignoreForm.getInputProps("ignoredUntil")}
          />
          <Button
            fullWidth
            onClick={onSubmit}
            disabled={!ignoreForm.isValid()}
            mt="12"
          >
            Valider
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
