import * as z from "zod"
import _ from "lodash"

// This method transforms a schema to be used by the api:
// we need to allow null, undefined and 0-length values in order to regularly save the form
// we need to coerce date values that are sent as string through HTTP
export const transformSchemaForApi = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) => {
  return z.object(
    _.mapValues(schema.shape, (field) => {
      const subFields = getSubFields(field)

      if (subFields.some((field) => field instanceof z.ZodDate)) {
        return z.coerce.date().nullable().optional()
      }

      if (
        subFields.some(
          (field) => field instanceof z.ZodString && field.minLength !== null,
        )
      ) {
        return z.string().nullable().optional()
      }

      if (field instanceof z.ZodArray) {
        return z.array(field.element).nullable().optional()
      }

      if (field instanceof z.ZodLiteral) {
        return field
      }

      return field.nullable().optional()
    }),
  )
}

// This function is necessary because the type of field object will
// be the last operator applied to it, so for example z.date().nullable()
// will give a ZodNullable field whereas we want to identify it's a date
const getSubFields = (field: z.ZodTypeAny) => {
  const types = new Array<z.ZodTypeAny>(field)

  let lastType = field
  while (lastType !== undefined) {
    const nextType = lastType._def?.innerType
    if (nextType !== undefined) {
      types.push(lastType._def.innerType)
    }
    lastType = nextType
  }

  return types
}
