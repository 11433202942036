import dayjs from "dayjs"
import React from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { ProjectTask } from "@ensol/types/project"

import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { SchedulingIssueReasonsInfo } from "./Row/ProjectTaskDetails"

export type TaskDetails = {
  label: string
  color: string
  ExtraInfoComponent?: React.FC<{ project: ProjectResponses.ProjectOverview }>
}

export const PROJECT_TASK_CONFIG: Record<ProjectTask, TaskDetails> = {
  [ProjectTask.VT_CANNOT_SCHEDULE]: {
    label: "VT - Non planifiable",
    color: "red",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectTask.VT_TO_CALL_BACK]: {
    label: "VT - À rappeler",
    color: "orange",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectTask.VT_WAITING_FOR_INSTALLER]: {
    label: "VT - En attente Installateur",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_INFO]: {
    label: "En attente info",
    color: "blue",
  },
  [ProjectTask.VT_TO_SCHEDULE]: {
    label: "VT - À planifier",
    color: "orange",
  },
  [ProjectTask.WAITING_FOR_VT]: { label: "En attente VT", color: "blue" },
  [ProjectTask.INSTALLATION_CANNOT_SCHEDULE]: {
    label: "Installation - Non planifiable",
    color: "red",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectTask.INSTALLATION_TO_CALL_BACK]: {
    label: "Installation - À rappeler",
    color: "orange",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectTask.INSTALLATION_WAITING_FOR_INSTALLER]: {
    label: "Installation - En attente Installateur",
    color: "blue",
  },
  [ProjectTask.INSTALLATION_TO_SCHEDULE]: {
    label: "Installation - À planifier",
    color: "orange",
  },
  [ProjectTask.WAITING_FOR_VT_VALIDATION]: {
    label: "En attente validation VT",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_DEPOSIT_PAYMENT]: {
    label: "En attente paiement acompte",
    color: "orange",
  },
  [ProjectTask.INSTALLATION_IN_PROGRESS]: {
    label: "Installation en cours",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_INSTALLATION]: {
    label: "En attente installation",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_SITE_PREPARATION]: {
    label: "En attente préparation chantier",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_FINAL_PAYMENT]: {
    label: "En attente paiement final",
    color: "orange",
  },
  [ProjectTask.WAITING_FOR_CONSUEL]: {
    label: "En attente consuel",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_CONSUEL_VISIT]: {
    label: "En attente visite consuel",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_CONSUEL_VALIDATION]: {
    label: "En attente validation consuel",
    color: "blue",
  },
  [ProjectTask.WAITING_FOR_SERVICE_ACTIVATION]: {
    label: "En attente mise en service",
    color: "blue",
  },
  [ProjectTask.FOLLOW_UP]: { label: "Suivi", color: "yellow" },
}

export type ProjectStatusConfig = {
  processes: ProcessId[]
  referenceDateLabel?: string
  getReferenceDates: (project: ProjectResponses.ProjectOverview) => {
    date: Date | null
    label: string
    status: "success" | "info" | "warning"
  }[]
}

const computePreliminaryRequestReferenceDate = (
  project: ProjectResponses.ProjectOverview,
) => {
  if (project.preliminaryRequestApprovalDate !== null) {
    return {
      label: "Date validation DP",
      date: project.preliminaryRequestApprovalDate,
      status: "success",
    } as const
  }

  return {
    label: "Valid. prévue DP",
    date: project.preliminaryRequestExpectedValidationDate,
    status: dayjs().isAfter(project.preliminaryRequestExpectedValidationDate)
      ? "warning"
      : "info",
  } as const
}

export const STATUS_SECTION_CONFIG: Record<ProjectStatus, ProjectStatusConfig> =
  {
    [ProjectStatus.CREATED]: { processes: [], getReferenceDates: () => [] },
    [ProjectStatus.SIGNED]: {
      processes: ["preliminaryRequestStep", "technicalVisitStep"],
      getReferenceDates: () => [],
    },
    [ProjectStatus.TECHNICAL_VISIT_SCHEDULED]: {
      processes: ["preliminaryRequestStep", "technicalVisitStep"],
      getReferenceDates: (project) => [
        {
          label: "Date VT",
          date: project.technicalVisitStartDate,
          status: "info",
        },
        computePreliminaryRequestReferenceDate(project),
      ],
    },
    [ProjectStatus.TECHNICAL_VISIT_COMPLETED]: {
      processes: [
        "preliminaryRequestStep",
        "technicalVisitStep",
        "enedisStep",
        "installationStep",
      ],
      getReferenceDates: (project) => [
        computePreliminaryRequestReferenceDate(project),
        {
          label: "Date début travaux",
          date: project.technicalVisitStartOfWorksDate,
          status: "info",
        },
      ],
    },
    [ProjectStatus.INSTALLATION_SCHEDULED]: {
      processes: [
        "preliminaryRequestStep",
        "enedisStep",
        "installationStep",
        "paymentStep",
        "hardwareStep",
      ],
      getReferenceDates: (project) => [
        {
          label: "Date installation",
          date: project.installationStartDate,
          status:
            project.preliminaryRequestApprovalDate === null &&
            dayjs(project.preliminaryRequestExpectedValidationDate).isAfter(
              project.installationStartDate,
            )
              ? "warning"
              : "info",
        },
        computePreliminaryRequestReferenceDate(project),
      ],
    },
    [ProjectStatus.INSTALLATION_COMPLETED]: {
      processes: [
        "enedisStep",
        "installationStep",
        "paymentStep",
        "consuelStep",
        "edfOaStep",
        "followUpStep",
      ],
      getReferenceDates: ({ consuelSummonsDate }) => [
        {
          label: "Date visite Consuel",
          date: consuelSummonsDate,
          status: "info",
        },
      ],
    },
    [ProjectStatus.CONSUEL_VALIDATED]: {
      getReferenceDates: ({ enedisActivationDate }) => [
        {
          label: "Mise en service",
          date: enedisActivationDate,
          status: "info",
        },
      ],
      processes: [
        "enedisStep",
        "paymentStep",
        "consuelStep",
        "edfOaStep",
        "followUpStep",
      ],
    },
    [ProjectStatus.DONE]: {
      processes: ["followUpStep", "edfOaStep", "greendealStep"],
      getReferenceDates: () => [],
    },
    [ProjectStatus.ABORTED]: {
      processes: [
        "preliminaryRequestStep",
        "technicalVisitStep",
        "installationStep",
      ],
      getReferenceDates: () => [],
    },
  }
