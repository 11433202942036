import { useMemo } from "react"

import { typedKeys } from "@ensol/types/utils"

import {
  MOUNTING_TYPE_LABELS,
  MountingType,
} from "@ensol/shared/entities/installations/batteries"
import { BATTERIES, BatteryType } from "@ensol/shared/material/batteries"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { formatNumber } from "@ensol/shared/utils/format"

import { Option } from "@ensol/entool/utils/form/radio"

const BATTERY_OPTIONS: Option<BatteryType>[] = typedKeys(BATTERIES).map(
  (batteryType) => {
    const { name, maxOutput } = BATTERIES[batteryType]

    return {
      label: name,
      value: batteryType,
      subtitle: `Max output: ${formatNumber(maxOutput)} kW`,
    }
  },
)

type BatteryMaterialOptionsInput = {
  inverterType?: InverterType
  batteryType: BatteryType | null
}

type BatteryMaterialOptions = {
  batteryOptions: Option<
    BatteryType,
    { incompatible: boolean; mountingTypeOptions: Option<MountingType>[] }
  >[]
}

export const useBatteryMaterialOptions = ({
  inverterType,
  batteryType,
}: BatteryMaterialOptionsInput): BatteryMaterialOptions => {
  const batteryOptions = useMemo(() => {
    return BATTERY_OPTIONS.map((battery) => {
      const inverter = inverterType && getInverter(inverterType)
      const batteryInfo = BATTERIES[battery.value]
      const isWrongBrand =
        inverter !== undefined && batteryInfo.brand !== inverter.brand

      const disabled = isWrongBrand || batteryInfo.disabled
      const mountingTypeOptions: Option<MountingType>[] =
        batteryInfo.mountingTypes.map((mountingType) => ({
          label: MOUNTING_TYPE_LABELS[mountingType],
          value: mountingType,
        }))
      return {
        ...battery,
        disabled,
        metadata: { incompatible: isWrongBrand, mountingTypeOptions },
        subtitle: isWrongBrand
          ? `Compatible avec les onduleurs de la marque ${batteryInfo.brand} uniquement`
          : batteryInfo.disabled
            ? "Cette batterie n'est plus disponible"
            : battery.subtitle,
      }
    }).filter((battery) => !battery.disabled || battery.value === batteryType)
  }, [batteryType, inverterType])

  return { batteryOptions }
}
