import { ExtraWorkType } from "@ensol/shared/material/extraWorks"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

export enum InstallationUpdateType {
  SALES = "sales",
  TECHNICAL_VISIT = "technicalVisit",
}

export type ExtraWorks = {
  type: ExtraWorkType
  name: string
  category: string
  description: string
  price: number
}[]

export type HouseSignedInstallations = {
  photovoltaicInstallation: {
    installationId: string
  } & InstallationsResponses.PhotovoltaicInstallation
  extraPanelsInstallations: InstallationsResponses.ExtraPanelsInstallation[]
  batteryInstallations: InstallationsResponses.BatteryInstallation[]
  evChargerInstallations: InstallationsResponses.EvChargerInstallation[]
}
