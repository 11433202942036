import { Box } from "@mantine/core"

export type PanelOrientation = "portrait" | "landscape"

type PanelProps = {
  dimensionsRatio: number
  orientation: PanelOrientation
}
export const Panel = ({ orientation, dimensionsRatio }: PanelProps) => (
  <Box
    w={orientation === "portrait" ? 30 : "auto"}
    h={orientation === "portrait" ? "auto" : 30}
    bg="black"
    style={(theme) => ({
      aspectRatio:
        orientation === "portrait" ? dimensionsRatio : 1 / dimensionsRatio,
      border: `1px solid ${theme.colors.gray[3]}`,
    })}
  />
)
