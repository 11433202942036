import { useForm, zodResolver } from "@mantine/form"
import { z } from "zod"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { Nullable } from "@ensol/types/utils"

import {
  useAbortProjectMutation,
  useUpdateProcessDataMutation,
} from "@ensol/entool/queries/projects"
import { ABORT_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

const schema = z.object({
  schedulingIssueMainReason: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  schedulingIssueSubReason: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

type AbortProjectReasonsModalInput = z.infer<typeof schema>

export const useAbortProjectReasonsModal = (
  project: ProjectResponses.Project,
  onClose: () => void,
) => {
  const initialValues = {
    schedulingIssueMainReason: ABORT_REASONS_OPTIONS.some(({ items }) =>
      items.includes(project.schedulingIssueSubReason ?? ""),
    )
      ? project.schedulingIssueMainReason
      : null,
    schedulingIssueSubReason: project.schedulingIssueSubReason,
  }

  const form = useForm<Nullable<AbortProjectReasonsModalInput>>({
    validate: zodResolver(schema),
    initialValues,
  })

  const { mutateAsync: abortProject, isPending: isProjectAborting } =
    useAbortProjectMutation(project.id)

  const { mutateAsync: updateProjectAsync, isPending: isUpdatingProject } =
    useUpdateProcessDataMutation<AbortProjectReasonsModalInput>(
      "installation",
      project.id,
    )

  const handleSubmit = async (
    values: Nullable<AbortProjectReasonsModalInput>,
  ) => {
    const validateData = schema.parse(values)
    await updateProjectAsync(validateData)
    onClose()
    await abortProject()
  }

  const isLoading = isProjectAborting || isUpdatingProject

  return { isLoading, handleSubmit, form, initialValues }
}
