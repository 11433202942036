import { ProjectResponses } from "@ensol/types/endpoints/projects"

export const CONSUEL_PROPERTIES: (keyof ProjectResponses.Project)[] = [
  "consuelNotes",
  "consuelRequestForConnectionPath",
  "electricityBillPath",
  "consuelReference",
  "consuelSummonsWeek",
  "consuelSummonsDate",
  "consuelFirstNoticeOfVisitPath",
  "consuelComplianceReportPath",
  "consuelCertificatePath",
  "consuelSecondNoticeOfVisitPath",
  "consuelNonComplianceReportPaths",
  "consuelWorksDate",
  "consuelWorksLocations",
  "consuelWorksReasons",
  "consuelWorksInstallerId",
  "isValidationVisitNecessary",
]
