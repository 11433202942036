import { Button, ButtonProps, MantineStyleProps } from "@mantine/core"
import { IconExternalLink } from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  label: string
  href: string
  Icon?: ReactNode
  isExternal?: boolean
} & MantineStyleProps &
  ButtonProps

export const Link = ({
  label,
  href,
  Icon,
  isExternal = false,
  ...props
}: Props) => (
  <Button
    component="a"
    leftSection={Icon}
    rightSection={isExternal && <IconExternalLink size={14} />}
    variant="outline"
    href={href}
    target={isExternal ? "_blank" : undefined}
    role="link"
    {...props}
  >
    {label}
  </Button>
)
