import { Badge, Stack, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  ProcessId,
  ProcessStep,
} from "@ensol/shared/entities/projects/processes"

import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

type ProcessesMilestoneReportProps = {
  processes: ProcessId[]
  project: ProjectResponses.ProjectOverview
}

export const ProcessesReport = ({
  processes,
  project,
}: ProcessesMilestoneReportProps) => {
  return (
    <Stack h="100%" align="flex-start">
      {PROJECTS_PROCESS_VIEWS.filter(({ id }) => processes.includes(id)).map(
        ({ id, name, milestones }) => {
          const milestone: ProcessStep | null = project[id]

          if (milestone === null) {
            return (
              <Report
                key={id}
                processName={name}
                category="Non commencé"
                color="grey"
              />
            )
          }

          const { category, color } = milestones.find(
            ({ id }) => id === milestone,
          )!
          return (
            <Report
              key={id}
              processName={name}
              category={category}
              color={color}
            />
          )
        },
      )}
    </Stack>
  )
}

type ReportProps = {
  processName: string
  category: string
  color: string
}

const Report = ({ processName, color, category }: ReportProps) => (
  <Stack align="flex-start" gap="4">
    <Text size="sm" fw="500">
      {processName}
    </Text>
    <Badge variant="light" color={color} size="sm">
      {category}
    </Badge>
  </Stack>
)
