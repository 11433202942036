import {
  SimpleGrid,
  Textarea,
  Input,
  Stack,
  Select,
  MultiSelect,
} from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import {
  IconBarrierBlock,
  IconInfoCircle,
  IconMessageCheck,
  IconMessageX,
  IconSend,
} from "@tabler/icons-react"
import dayjs from "dayjs"

import { ConsuelStepInput } from "@ensol/types/forms/projects/consuel"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { ConsuelStep } from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
  WEEK_FORMAT,
} from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  CONSUEL_WORKS_LOCATION_OPTIONS,
  CONSUEL_WORKS_REASON_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const ConsuelForm = <Input extends ConsuelStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })
  const installersOptions = useInstallersOptions()

  const currentStep = project[props.processId]

  const { house, activeQuote } = project.installation
  const { prospect } = house

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {children}
      <SimpleGrid cols={2} spacing="32" mt="16">
        <FormField initialValues={initialValues} propertyName="consuelNotes">
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("consuelNotes")}
          />
        </FormField>
      </SimpleGrid>
      <Section title="Informations" icon={IconInfoCircle} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Field name="Devis" noBorder>
            {activeQuote !== null && activeQuote.quotePath !== null ? (
              <MultiFilePreview
                filesPaths={[activeQuote.quotePath]}
                fileName={getProspectFileName("devis", prospect)}
              />
            ) : (
              <NotFound type="file" />
            )}
          </Field>
          <Field name="PDL" noBorder>
            {house.pdl !== null ? (
              <CopiableText text={house.pdl} w={FIELD_WIDTH} />
            ) : (
              <NotFound type="text" />
            )}
          </Field>
        </SimpleGrid>
      </Section>
      <Section title="Demande" icon={IconSend} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              propertyName="consuelReference"
              initialValues={initialValues}
              isRequired
            >
              <Input
                w={FIELD_WIDTH}
                {...form.getInputProps("consuelReference")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="consuelOtherDocumentsPaths"
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName(
                    "autres-documents-consuel",
                    prospect,
                  ),
                }}
                inputProps={{
                  accept: ["application/pdf"],
                }}
                {...form.getInputProps("consuelOtherDocumentsPaths")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="consuelRequestForConnectionPath"
              isRequired={project.fundingSource === FundingSource.CETELEM}
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName(
                    "demande-raccordement-enedis",
                    prospect,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("consuelRequestForConnectionPath")}
              />
            </FormField>
            <FormField
              propertyName="electricityBillPath"
              initialValues={initialValues}
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName(
                    "facture-électricité",
                    prospect,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("electricityBillPath")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {"consuelComplianceReportPath" in initialValues &&
        "consuelCertificatePath" in initialValues && (
          <Section icon={IconMessageCheck} title="Approuvé" titleOrder={4}>
            <SimpleGrid cols={2} spacing="32" mt="16">
              <Stack gap="24">
                <FormField
                  initialValues={initialValues}
                  propertyName="consuelApprovalDate"
                >
                  <DateInput
                    w={FIELD_WIDTH}
                    clearable
                    valueFormat={DISPLAY_DATE_FORMAT}
                    {...form.getInputProps("consuelApprovalDate")}
                  />
                </FormField>
                <FormField
                  propertyName="consuelCertificatePath"
                  initialValues={initialValues}
                >
                  <FileField
                    previewProps={{
                      fileName: getProspectFileName(
                        "attestation-consuel",
                        prospect,
                      ),
                    }}
                    inputProps={{ accept: ["application/pdf"] }}
                    {...form.getInputProps("consuelCertificatePath")}
                  />
                </FormField>
              </Stack>
              <FormField
                propertyName="consuelComplianceReportPath"
                initialValues={initialValues}
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "rapport-conformité-consuel",
                      prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("consuelComplianceReportPath")}
                />
              </FormField>
            </SimpleGrid>
          </Section>
        )}
      {("consuelSummonsDate" in initialValues ||
        "consuelFirstNoticeOfVisitPath" in initialValues) && (
        <Section icon={IconMessageX} title="Visite nécessaire" titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="consuelSummonsWeek"
                isRequired
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={WEEK_FORMAT}
                  clearable
                  {...form.getInputProps("consuelSummonsWeek")}
                />
              </FormField>
              {project.consuelSummonsWeek !== null && (
                <FormField
                  initialValues={initialValues}
                  propertyName="consuelSummonsDate"
                  isRequired
                >
                  <DateTimePicker
                    w={FIELD_WIDTH}
                    valueFormat={DISPLAY_DATE_TIME_FORMAT}
                    minDate={dayjs(project.consuelSummonsWeek)
                      .startOf("week")
                      .toDate()}
                    maxDate={dayjs(project.consuelSummonsWeek)
                      .endOf("week")
                      .toDate()}
                    clearable
                    {...form.getInputProps("consuelSummonsDate")}
                  />
                </FormField>
              )}
              <FormField
                initialValues={initialValues}
                propertyName="consuelNonComplianceReportPaths"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "rapport-non-conformité-consuel",
                      prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("consuelNonComplianceReportPaths")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="consuelFirstNoticeOfVisitPath"
                isRequired={currentStep !== ConsuelStep.SUBMITTED}
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "avis-de-visite-consuel-1",
                      prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("consuelFirstNoticeOfVisitPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="consuelSecondNoticeOfVisitPath"
                isRequired
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "avis-de-visite-consuel-2",
                      prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("consuelSecondNoticeOfVisitPath")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      {("isValidationVisitNecessary" in initialValues ||
        "consuelWorksDate" in initialValues ||
        "consuelWorksInstallerId" in initialValues) && (
        <Section
          icon={IconBarrierBlock}
          title="Travaux nécessaires"
          titleOrder={4}
        >
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="consuelWorksDate"
                isRequired
              >
                <DateTimePicker
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_TIME_FORMAT}
                  clearable
                  {...form.getInputProps("consuelWorksDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="consuelWorksLocations"
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={CONSUEL_WORKS_LOCATION_OPTIONS}
                  {...form.getInputProps("consuelWorksLocations")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="consuelWorksReasons"
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={CONSUEL_WORKS_REASON_OPTIONS}
                  {...form.getInputProps("consuelWorksReasons")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="consuelWorksInstallerId"
                isRequired
              >
                <Select
                  w={FIELD_WIDTH}
                  data={installersOptions}
                  {...form.getInputProps("consuelWorksInstallerId")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="isValidationVisitNecessary"
                isRequired
              >
                <RadioGroup
                  options={booleanOptions}
                  {...form.getInputProps("isValidationVisitNecessary")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
    </StepActions>
  )
}
