import { Stack } from "@mantine/core"
import dayjs from "dayjs"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getProspectFileName } from "@ensol/shared/utils/files"
import { formatDate } from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { Field } from "@ensol/entool/components/form/Field"

type Props = {
  project: ProjectResponses.Project
}

export const HardwareInfo = ({ project }: Props) => {
  const { prospect } = project.installation.house

  return (
    <Stack gap="24">
      <Field name="Date d'installation prévue" noBorder>
        <TextInfo
          value={formatDate(
            project.installationStartDate ??
              project.installationExpectedStartDate,
          )}
        />
      </Field>
      <Field name="Date de livraison souhaitée" noBorder>
        <TextInfo
          value={
            project.installationStartDate !== null
              ? formatDate(
                  dayjs(project.installationStartDate).subtract(7, "days"),
                )
              : null
          }
        />
      </Field>
      <Field name="Installateur" noBorder>
        <TextInfo value={project.installer?.name ?? null} />
      </Field>
      <Field name="Calepinage visite technique" noBorder>
        {project.technicalVisitPanelsLayoutPath !== null ? (
          <MultiFilePreview
            filesPaths={[project.technicalVisitPanelsLayoutPath]}
            fileName={getProspectFileName("calepinage-VT", prospect)}
          />
        ) : (
          <NotFound type="file" />
        )}
      </Field>
      <Field name="Rapport K2 / ESDEC visite technique" noBorder>
        {project.technicalVisitK2ReportsPath.length > 0 ? (
          <MultiFilePreview
            filesPaths={project.technicalVisitK2ReportsPath}
            fileName={getProspectFileName("rapport-k2-VT", prospect)}
          />
        ) : (
          <NotFound type="file" />
        )}
      </Field>
    </Stack>
  )
}
