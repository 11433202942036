import { IconArrowBackUp } from "@tabler/icons-react"

import { preliminaryValidationStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { PRELIMINARY_REQUEST_PROPERTIES } from "./config"

export const SubmissionPending = ({
  project,
  ...props
}: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={preliminaryValidationStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(PRELIMINARY_REQUEST_PROPERTIES, project),
      ...getInitialValues(preliminaryValidationStepFormSchema, project),
    }}
    project={project}
    actions={[
      {
        action: "reject",
        label: "Annuler la validation du projet",
        color: "orange",
        Icon: IconArrowBackUp,
      },
      { action: "validate", label: "En attente de Solteo" },
    ]}
    {...props}
  />
)
