import { ExtraWorks } from "@ensol/types/installation"
import { QuoteDetail } from "@ensol/types/quote"

export const computeExtraWorksQuoteDetails = (
  extraWorks: ExtraWorks,
): QuoteDetail[] =>
  extraWorks.map(({ category, description, price }) => ({
    name: category,
    description,
    quantity: 1,
    price,
  }))
