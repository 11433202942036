import { Checkbox, Divider, Stack, Text } from "@mantine/core"

import { Option } from "@ensol/entool/utils/form/radio"

type Props<Metadata> = {
  options: Option<string, Metadata>[]
  value?: string[]
  onChange: (value: string[]) => void
  withSelectAll?: boolean
  nullable?: boolean
  error?: string
}

export const CheckboxGroup = <Metadata,>({
  options,
  value = [],
  onChange,
  withSelectAll,
  error,
}: Props<Metadata>) => (
  <Stack>
    {error && (
      <Text c="red" size="xs">
        {error}
      </Text>
    )}
    {withSelectAll && (
      <>
        <Checkbox
          label="Tout sélectionner"
          checked={value.length === options.length}
          indeterminate={value.length !== 0 && value.length !== options.length}
          onChange={() => {
            if (value.length !== options.length) {
              onChange(options.map((option) => option.value))
            } else {
              onChange([])
            }
          }}
        />
        <Divider />
      </>
    )}
    <Checkbox.Group value={value} onChange={onChange}>
      <Stack gap="8">
        {options.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </Stack>
    </Checkbox.Group>
  </Stack>
)
