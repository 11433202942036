export const downloadFile = (
  fileData: string | Blob,
  filename: string,
  mimeType: string,
) => {
  let blob
  if (typeof fileData === "string") {
    blob = new Blob([fileData], { type: mimeType })
  } else {
    blob = fileData
  }
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.download = filename
  a.href = url
  a.target = "_self"
  a.click()
}
