import { Checkbox, Drawer, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import _ from "lodash"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { useCalendarView } from "@ensol/entool/pages/ProjectsEvents/useCalendarView"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"
import { PROJECT_EVENT_TYPES_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  isOpen: boolean
  onClose: () => void
  eventTypes: ProjectEventType[]
  setEventTypes: (eventTypes: ProjectEventType[]) => void
  installers: string[]
  setInstallers: (installers: string[]) => void
  coverageZones: string[]
  setCoverageZones: (coverageZones: string[]) => void
  showInstallerEvents: boolean
  setShowInstallerEvents: (showInstallerEvents: boolean) => void
  showEventsWithoutInstaller: boolean
  setShowEventsWithoutInstaller: (
    setShowEventsWithoutInstaller: boolean,
  ) => void
  showWeekEnds: boolean
  setShowWeekEnds: (showWeekEnds: boolean) => void
  showPublicHolidays: boolean
  setShowPublicHolidays: (showPublicHolidays: boolean) => void
}

const MENU_WIDTH_IN_PX = 230

export const EventsMenu = (props: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (isMobile) {
    return (
      <Drawer
        opened={props.isOpen}
        onClose={props.onClose}
        position="right"
        size="75%"
        title={
          <Text size="lg" fw="bold">
            Paramètres du calendrier
          </Text>
        }
      >
        <EventsMenuContent {...props} />
      </Drawer>
    )
  }

  return (
    <Stack
      h="100%"
      w={MENU_WIDTH_IN_PX}
      pl="16"
      pr={CONTENT_PADDING_IN_PX}
      style={({ colors }) => ({
        overflow: "auto",
        borderLeft: `1px solid ${colors.gray[3]}`,
      })}
    >
      <EventsMenuContent {...props} />
    </Stack>
  )
}

const EventsMenuContent = ({
  eventTypes,
  setEventTypes,
  installers,
  setInstallers,
  coverageZones,
  setCoverageZones,
  showInstallerEvents,
  setShowInstallerEvents,
  showEventsWithoutInstaller,
  setShowEventsWithoutInstaller,
  showWeekEnds,
  setShowWeekEnds,
  showPublicHolidays,
  setShowPublicHolidays,
}: Props) => {
  const installersOptions = useInstallersOptions()
  const coverageZonesOptions = _.chain(installersOptions)
    .map(({ metadata }) => metadata?.coverageZones ?? [])
    .flatten()
    .uniq()
    .map((coverageZone) => ({
      label: coverageZone,
      value: coverageZone,
    }))
    .value()

  const { currentCalendarView } = useCalendarView()

  return (
    <Stack gap="32">
      <Stack gap="8">
        <Text fz="sm" fw="bold">
          Options
        </Text>
        <Checkbox
          label="Afficher les calendriers des installateurs"
          checked={showInstallerEvents}
          onChange={() => setShowInstallerEvents(!showInstallerEvents)}
        />
        <Checkbox
          label="Afficher les évènements sans installateur"
          checked={showEventsWithoutInstaller}
          onChange={() =>
            setShowEventsWithoutInstaller(!showEventsWithoutInstaller)
          }
        />
        <Checkbox
          label="Afficher les week-ends"
          checked={showWeekEnds}
          onChange={() => setShowWeekEnds(!showWeekEnds)}
        />
        <Checkbox
          label="Afficher les jours fériés"
          checked={showPublicHolidays}
          onChange={() => setShowPublicHolidays(!showPublicHolidays)}
        />
      </Stack>
      <Stack>
        <Text fz="sm" fw="bold">
          Types d&apos;évènements
        </Text>
        <CheckboxGroup
          options={PROJECT_EVENT_TYPES_OPTIONS}
          value={eventTypes}
          onChange={(value) => setEventTypes(value as ProjectEventType[])}
        />
      </Stack>
      {currentCalendarView !== "resourceTimelineMonth" ? (
        <Stack>
          <Text fz="sm" fw="bold">
            Installateurs
          </Text>
          <CheckboxGroup
            options={installersOptions}
            value={installers}
            onChange={setInstallers}
            withSelectAll
          />
        </Stack>
      ) : (
        <Stack>
          <Text fz="sm" fw="bold">
            Zones couvertes
          </Text>
          <CheckboxGroup
            options={coverageZonesOptions}
            value={coverageZones}
            onChange={setCoverageZones}
            withSelectAll
          />
        </Stack>
      )}
    </Stack>
  )
}
