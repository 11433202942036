export type Orientation = "N" | "NE" | "E" | "SE" | "S" | "SW" | "W" | "NW"

type OrientationInfo = {
  name: string
  numericValue: number
}

export const getOrientation = (azimuthDegrees: number): Orientation => {
  if (azimuthDegrees > 337 || azimuthDegrees <= 22) {
    return "N"
  } else if (azimuthDegrees > 22 && azimuthDegrees <= 67) {
    return "NE"
  } else if (azimuthDegrees > 67 && azimuthDegrees <= 112) {
    return "E"
  } else if (azimuthDegrees > 112 && azimuthDegrees <= 157) {
    return "SE"
  } else if (azimuthDegrees > 157 && azimuthDegrees <= 202) {
    return "S"
  } else if (azimuthDegrees > 202 && azimuthDegrees <= 247) {
    return "SW"
  } else if (azimuthDegrees > 247 && azimuthDegrees <= 292) {
    return "W"
  } else {
    // if (azimuthDegrees > 292 && azimuthDegrees <= 337) (but not needed, since we made a full 360° circle)
    return "NW"
  }
}

export const ORIENTATIONS: Record<Orientation, OrientationInfo> = {
  S: { name: "Sud", numericValue: 180 },
  SE: { name: "Sud-Est", numericValue: 135 },
  E: { name: "Est", numericValue: 90 },
  SW: { name: "Sud-Ouest", numericValue: 225 },
  W: { name: "Ouest ", numericValue: 270 },
  N: { name: "Nord", numericValue: 0 },
  NE: { name: "Nord-Est", numericValue: 45 },
  NW: { name: "Nord-Ouest", numericValue: 315 },
}
