import { orderDeliveryPendingStepFormSchema } from "@ensol/types/forms/projects/hardware"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HardwareForm } from "@ensol/entool/pages/OpsProject/processes/Hardware/components/HardwareForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const DeliveryPending = ({ project, ...props }: StepComponentProps) => (
  <HardwareForm
    schema={orderDeliveryPendingStepFormSchema}
    initialValues={getInitialValues(
      orderDeliveryPendingStepFormSchema,
      project,
    )}
    project={project}
    actions={[{ action: "validate", label: "Commande livrée" }]}
    {...props}
  />
)
