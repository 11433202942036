import { Button, Group, Modal, Text } from "@mantine/core"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { TECHNICAL_VISIT_REJECTED_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

import { useRejectActionModal } from "./useRejectActionModal"

export const RejectActionModal = (props: CustomActionModalProps) => {
  const { opened, onClose } = props

  const { form, isLoading, handleSubmit, initialValues } =
    useRejectActionModal(props)

  return (
    <Modal
      centered
      opened={opened}
      size="lg"
      padding="xl"
      onClose={isLoading ? () => {} : onClose}
      title={
        <Text size="lg" fw="bold">
          Rejeter la visite technique
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitRejectedReasons"
          isRequired
          align="flext-start"
        >
          <CheckboxGroup
            options={TECHNICAL_VISIT_REJECTED_REASONS_OPTIONS}
            {...form.getInputProps("technicalVisitRejectedReasons")}
          />
        </FormField>
        <Group justify="flex-end" mt={24}>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            Annuler
          </Button>
          <Button type="submit" loading={isLoading}>
            Valider
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
