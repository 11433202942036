import { ProjectStatus as PrismaProjectStatus } from "@ensol/types/prisma-client"

export enum ProjectStatus {
  CREATED = "CREATED",
  SIGNED = "SIGNED",
  TECHNICAL_VISIT_SCHEDULED = "TECHNICAL_VISIT_SCHEDULED",
  TECHNICAL_VISIT_COMPLETED = "TECHNICAL_VISIT_COMPLETED",
  INSTALLATION_SCHEDULED = "INSTALLATION_SCHEDULED",
  INSTALLATION_COMPLETED = "INSTALLATION_COMPLETED",
  CONSUEL_VALIDATED = "CONSUEL_VALIDATED",
  DONE = "DONE",
  ABORTED = "ABORTED",
}

export const getStatusIndex = (status: PrismaProjectStatus) =>
  Object.values(ProjectStatus).findIndex(
    (projectStatus) => projectStatus === status,
  )
