import { Stack, Group, Text, Loader, Button, ThemeIcon } from "@mantine/core"
import { IconCheckbox } from "@tabler/icons-react"

import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"

import { InfoBox } from "@ensol/entool/components/InfoBox"
import { useCreateOrderMutation } from "@ensol/entool/queries/switchgrid"

type Props = {
  houseId: string
  switchgridOrder: SwitchgridResponses.SwitchgridOrder | null
}

export const CreateOrder = ({ switchgridOrder, houseId }: Props) => {
  const { mutateAsync: createOrder, isPending: isOrderCreating } =
    useCreateOrderMutation(houseId)

  if (switchgridOrder === null) {
    return (
      <Button
        onClick={() => createOrder()}
        loading={isOrderCreating}
        variant="outline"
        w="300"
      >
        Demander les données
      </Button>
    )
  }

  const status = switchgridOrder.orderStatus

  if (status === OrderStatus.PENDING) {
    return (
      <Group gap="8" w="300">
        <Loader color="gray" size="xs" />
        <Text size="sm">La récupération des données est en cours</Text>
      </Group>
    )
  }

  if (status === OrderStatus.SOME_REQUESTS_SUCCEEDED) {
    return (
      <Stack w="300">
        <InfoBox
          message="Certaines données sont encore en chargement mais vous pouvez commencer à utiliser la source de données Enedis."
          color="orange"
        />
      </Stack>
    )
  }

  if (status === OrderStatus.RETRY_LATER) {
    return (
      <Stack align="flex-end" maw="500">
        <InfoBox
          message="Certaines données sont manquantes mais vous pouvez commencer à utiliser la source de données Enedis. Renouvelez la demande dans environ
          24h pour essayer de récupérer les données manquantes"
          color="orange"
        />
        <Button
          onClick={() => createOrder()}
          loading={isOrderCreating}
          variant="outline"
        >
          Demander à nouveau les données
        </Button>
      </Stack>
    )
  }

  if (status === OrderStatus.FAILED) {
    return (
      <Stack align="flex-end" maw="500">
        <InfoBox
          message="La demande des données n'a pas pu aboutir, contactez
            l'équipe énergie pour plus d'informations ou réessayez plus tard"
          color="red"
        />
        <Button
          onClick={() => createOrder()}
          loading={isOrderCreating}
          variant="outline"
        >
          Demander à nouveau les données
        </Button>
      </Stack>
    )
  }

  if (status === OrderStatus.SUCCEEDED) {
    return (
      <Stack w="300">
        <Group gap="4">
          <ThemeIcon color="green">
            <IconCheckbox />
          </ThemeIcon>
          <Text size="sm">Les données du client ont été récupérées</Text>
        </Group>
        <Button
          onClick={() => createOrder()}
          loading={isOrderCreating}
          variant="outline"
        >
          Rafraîchir les données
        </Button>
      </Stack>
    )
  }
}
