import { Accordion, Badge, Group, Stack, Title } from "@mantine/core"
import { useEffect, useState } from "react"

type AccordionItemData<T> = {
  id: string
  title: string
  items: T[]
}

type Props<T> = {
  data: AccordionItemData<T>[]
  render: (items: T[], id: string) => React.ReactNode
}

export const ProjectAccordion = <T,>({ data, render }: Props<T>) => {
  const [openedAccordion, setOpenedAccordion] = useState<
    string | null | undefined
  >()

  useEffect(() => {
    if (
      openedAccordion === undefined ||
      (openedAccordion !== null &&
        !data.map(({ id }) => id).includes(openedAccordion))
    ) {
      setOpenedAccordion(data[0]?.id ?? null)
    }
  }, [openedAccordion, data])

  return (
    <Accordion
      defaultValue={data.length > 0 ? data[0].id : null}
      value={openedAccordion}
      onChange={setOpenedAccordion}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
        paddingBottom: 32,
      }}
      styles={{ content: { paddingTop: 16, paddingBottom: 16 } }}
    >
      {data.map(({ id, title, items }) => {
        return (
          <Accordion.Item
            value={id}
            styles={{ item: { borderBottom: 0 } }}
            key={id}
          >
            <Accordion.Control bg="gray.0" h={40}>
              <Group gap="8" px="12" py="8" style={{ borderRadius: 4 }}>
                <Title order={4}>{title}</Title>
                <Badge variant="outline" color="black">
                  {items.length}
                </Badge>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Stack>{openedAccordion === id ? render(items, id) : null}</Stack>
            </Accordion.Panel>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}
