import { Title, Group } from "@mantine/core"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getFormalName } from "@ensol/shared/entities/prospects"

import { Header, HeaderProps } from "@ensol/entool/components/Header"
import { EditProspectButton } from "@ensol/entool/components/form/Prospect/EditProspectButton"

export type ProspectHeaderProps = HeaderProps & {
  prospect: ProspectResponses.Prospect
  onProspectEditSuccess?: () => void
}

export const ProspectHeader = ({
  prospect,
  onProspectEditSuccess,
  LeftComponent,
  ...props
}: ProspectHeaderProps) => (
  <Header
    {...props}
    LeftComponent={
      <>
        <Group gap="8">
          <Title data-test="headerTitle" order={1}>
            {getFormalName(prospect)}
          </Title>
          <EditProspectButton
            prospect={prospect}
            onSuccess={onProspectEditSuccess}
          />
        </Group>
        {LeftComponent}
      </>
    }
  />
)
