import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"

import { INTERCOM_INBOX_URL } from "@ensol/entool/config"
import { useUpdateInstallationAlertMutation } from "@ensol/entool/queries/installations"

export const INTERCOM_LINK_REGEX = new RegExp(
  `${INTERCOM_INBOX_URL}.*/conversation/([0-9]+)`,
)

export const useLinkIntercomConversation = (alertId: string) => {
  const [isLinkFormOpen, { open: openLinkForm, close: closeLinkForm }] =
    useDisclosure(false)

  const { mutateAsync: updateAlert } =
    useUpdateInstallationAlertMutation(alertId)

  return {
    isLinkFormOpen,
    openLinkForm,
    closeLinkForm,
    onSubmit: async (conversationLink: string) => {
      const match = conversationLink.match(INTERCOM_LINK_REGEX)
      const intercomConversationId = match ? Number(match[1]) : null

      if (intercomConversationId === null) {
        notifications.show({
          title: "Une erreur est survenue",
          message:
            "L'id de la conversation Intercom n'a pas pu être trouvé, veuillez contactez l'équipe technique",
          color: "red",
        })
        return
      }

      await updateAlert({ intercomConversationId })
    },
  }
}
