import { validatedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const Validated = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={validatedStepFormSchema}
    initialValues={getInitialValues(validatedStepFormSchema, project)}
    project={project}
    actions={[]}
    {...props}
  />
)
