import {
  DateInputProps,
  DateValue,
  DateInput as MantineDateInput,
} from "@mantine/dates"
import dayjs from "dayjs"

export const DateInput = (props: DateInputProps) => {
  const { onChange } = props

  return (
    <MantineDateInput
      {...props}
      onChange={(value: DateValue) => {
        // This transformation allows to avoid timezone issues with dates without time
        const midDayValue =
          value !== null ? dayjs(value).add(12, "hour").toDate() : null

        onChange?.(midDayValue)
      }}
      preserveTime={false}
    />
  )
}
