import { UseFormReturnType } from "@mantine/form"

export const getSubformActions = <Values, Key extends keyof Values>(
  form: UseFormReturnType<Values>,
  subformKey: Key,
) => ({
  onChange: form.getInputProps(subformKey).onChange,
  setError: (error: string) => form.setFieldError(subformKey, error),
  clearError: () => form.clearFieldError(subformKey),
})
