import {
  ColorSwatch,
  Group,
  SimpleGrid,
  Text,
  UnstyledButton,
} from "@mantine/core"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { ProcessId } from "@ensol/shared/entities/projects/processes"

import { ProjectMilestonesConfig } from "@ensol/entool/utils/projects/types"
import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

import styles from "./MilestoneReport.module.css"

type ProcessesMilestoneReportProps = {
  processes: ProcessId[]
  project: Pick<ProjectResponses.Project, "id" | ProcessId>
  isCompactDisplay?: boolean
}

export const ProcessesMilestoneReport = ({
  processes,
  project,
  isCompactDisplay = false,
}: ProcessesMilestoneReportProps) =>
  PROJECTS_PROCESS_VIEWS.filter(({ id }) => processes.includes(id)).map(
    ({ id, name, milestones }) => (
      <MilestoneReport
        key={id}
        link={`/projects/${project.id}?tab=${id}`}
        processName={name}
        milestone={project[id]}
        milestonesConfig={milestones}
        isCompactDisplay={isCompactDisplay}
      />
    ),
  )

type Props<MilestoneType> = {
  link: string
  processName: string
  milestone: MilestoneType | null
  milestonesConfig: ProjectMilestonesConfig<MilestoneType>
  isCompactDisplay: boolean
}

const MilestoneReport = <MilestoneType extends string>({
  link,
  processName,
  milestone,
  milestonesConfig,
  isCompactDisplay,
}: Props<MilestoneType>) => {
  if (milestone === null) {
    return (
      <Report
        category={processName}
        value="Non commencé"
        color="grey"
        isCompactDisplay={isCompactDisplay}
      />
    )
  }

  const { label, color } = milestonesConfig.find(({ id }) => id === milestone)!

  return isCompactDisplay ? (
    <Report
      category={processName}
      value={label}
      color={color}
      isCompactDisplay={isCompactDisplay}
    />
  ) : (
    <UnstyledButton component={Link} to={link} className={styles.reportLink}>
      <Report
        category={processName}
        value={label}
        color={color}
        isCompactDisplay={isCompactDisplay}
      />
    </UnstyledButton>
  )
}

type ReportProps = {
  category: string
  value: string
  color: string
  isCompactDisplay: boolean
}

const Report = ({ category, color, value, isCompactDisplay }: ReportProps) => (
  <SimpleGrid
    cols={isCompactDisplay ? 1 : 2}
    spacing={isCompactDisplay ? 4 : 8}
  >
    <Group gap="8" wrap="nowrap">
      <ColorSwatch color={color} size={10} />
      <Text
        size={isCompactDisplay ? "sm" : "md"}
        fw={isCompactDisplay ? 500 : 600}
      >
        {category}
      </Text>
    </Group>
    <Text
      size={isCompactDisplay ? "sm" : "md"}
      c={`${color}.8`}
      style={{ verticalAlign: "center" }}
    >
      {value}
    </Text>
  </SimpleGrid>
)
