import { MantineStyleProps } from "@mantine/core"

import pennylaneLogo from "@ensol/entool/assets/pennylaneLogo.png"
import { Link } from "@ensol/entool/components/Link"

type Props = {
  href: string
  label: string
} & MantineStyleProps

export const PennylaneLink = ({ href, ...props }: Props) => {
  return (
    <Link
      href={href}
      w="fit-content"
      color="rgb(0, 102, 102)"
      Icon={<img src={pennylaneLogo} height={20} />}
      isExternal
      {...props}
    />
  )
}
