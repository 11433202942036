import { Divider, Grid, Group, Stack, StackProps, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"

import { devices } from "@ensol/entool/styles/theme"

type Props = StackProps & {
  name: string
  subtitle?: string
  helpText?: string
  noBorder?: boolean
  withAsterisk?: boolean
  children: ReactNode
}

export const LegacyField = ({
  name,
  subtitle,
  align,
  helpText,
  children,
  noBorder = false,
  withAsterisk = false,
  ...props
}: Props) => {
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <Stack w="100%">
      <Grid
        h="100%"
        align="stretch"
        justify="space-between"
        columns={24}
        gutter={{ base: 4, md: 0 }}
      >
        <Grid.Col span={{ base: 24, md: 8, lg: 5, xl: 8 }}>
          <Stack gap={8}>
            <Group gap={4} style={{ flexWrap: "nowrap" }}>
              <Text fw={500} c="gray.7">
                {name}
                {withAsterisk && " *"}
              </Text>
            </Group>
            {subtitle && (
              <Text size="sm" c="gray.6">
                {subtitle}
              </Text>
            )}
          </Stack>
          {helpText && (
            <Text size="sm" c="gray.6">
              {helpText}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 24, md: 16, lg: 19, xl: 16 }}>
          <Stack
            align={align ? align : isSmallScreen ? "flex-start" : "flex-end"}
            {...props}
          >
            {children}
          </Stack>
        </Grid.Col>
      </Grid>
      {!noBorder && <Divider mt={12} mb={24} />}
    </Stack>
  )
}
