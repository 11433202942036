import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { CetelemForm } from "./components/CetelemForm"

export const RequestToSend = ({ project, processId }: StepComponentProps) => {
  return (
    <CetelemForm
      project={project}
      schema={cetelemFormSchema}
      initialValues={getInitialValues(cetelemFormSchema, project)}
      processId={processId}
      actions={[
        {
          action: "validate",
          label: "Demande Cetelem envoyée",
          confirmationMessage:
            "Cette action va envoyer un lien de signature de la demande de financement Cetelem au client, êtes-vous sûr de vouloir continuer ?",
        },
      ]}
    />
  )
}
