import { DatesSetArg } from "@fullcalendar/core"
import dayjs, { extend } from "dayjs"
import objectSupport from "dayjs/plugin/objectSupport"
import { useCallback, useState } from "react"

import {
  useCalendarView,
  CalendarView,
} from "@ensol/entool/pages/ProjectsEvents/useCalendarView"

extend(objectSupport)

export const useCalendar = () => {
  const [openedEventId, setOpenedEventId] = useState<string | null>(null)
  const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().month())
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year())

  const { currentCalendarView, setCurrentCalendarView } = useCalendarView()

  const changeCalendarView = useCallback(
    (view: DatesSetArg["view"]) => {
      // If tab is inactive we don't want to change the view as it will
      // create ping-pong loop of view change between active and inactive tabs
      if (!document.hidden) {
        setCurrentCalendarView(view.type as CalendarView)
      }
    },
    [setCurrentCalendarView],
  )
  return {
    currentCalendarView,
    onDatesSet: ({ start, view }: DatesSetArg) => {
      setSelectedMonth(dayjs(start).month())
      setSelectedYear(dayjs(start).year())
      changeCalendarView(view)
    },
    openedEventId,
    openEvent: ({ event }: { event: { id: string } }) =>
      setOpenedEventId(event.id),
    closeEvent: () => setOpenedEventId(null),
    period: {
      from: dayjs({
        month: selectedMonth,
        year: selectedYear,
      })
        .subtract(2, "month")
        .toDate(),
      to: dayjs({
        month: selectedMonth,
        year: selectedYear,
      })
        .add(3, "month")
        .toDate(),
    },
  }
}
