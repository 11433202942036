import { emailSentStepFormSchema } from "@ensol/types/forms/projects/edfOa"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EdfOaForm } from "@ensol/entool/pages/OpsProject/processes/EdfOa/components/EdfOaForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const EmailSent = ({ project, ...props }: StepComponentProps) => {
  return (
    <EdfOaForm
      schema={emailSentStepFormSchema}
      initialValues={getInitialValues(emailSentStepFormSchema, project)}
      project={project}
      actions={[{ action: "validate", label: "Attestation BTA à envoyer" }]}
      {...props}
    />
  )
}
