import * as Sentry from "@sentry/react"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import React from "react"

type Props = {
  children: React.ReactNode
}

export const AutoReload = ({ children }: Props) => {
  const { data, error } = useQuery<string>({
    queryKey: ["latestIndexContent"],
    queryFn: async () => {
      const response = await axios.get("/index.html", {
        headers: { "Cache-Control": "no-cache" },
      })

      return response.data
    },
    refetchInterval: 1000 * 60 * 30, // 30 minutes,
    refetchIntervalInBackground: true,
  })

  if (data) {
    reloadOnNewVersionAvailable(data)
  }

  if (error) {
    Sentry.captureException(new Error("Could not check new version"), {
      extra: { error },
    })
  }

  return children
}

const reloadOnNewVersionAvailable = async (latestIndexContent: string) => {
  const isNewVersion = await isNewVersionAvailable(latestIndexContent)
  if (!isNewVersion) {
    return
  }
  currentIndexContent = latestIndexContent
  window.location.reload()
}

// Based on https://marmelab.com/blog/2016/08/29/auto-reload-spa-on-mobile-setinterval.html
let currentIndexContent: string | null = null
const isNewVersionAvailable = async (latestIndexContent: string) => {
  if (currentIndexContent === null) {
    currentIndexContent = latestIndexContent
  }

  const isNewVersion = currentIndexContent !== latestIndexContent

  return isNewVersion
}
