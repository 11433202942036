import { Button, Group, Modal, ModalBaseProps, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { SchedulingIssueFormField } from "@ensol/entool/components/form/Project/SchedulingIssueFormField"
import { ABORT_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

import { useAbortProjectReasonsModal } from "./useAbortProjectReasonsModal"

type Props = {
  project: ProjectResponses.Project
} & ModalBaseProps

export const AbortProjectReasonsModal = ({
  project,
  opened,
  onClose,
}: Props) => {
  const { form, handleSubmit, isLoading } = useAbortProjectReasonsModal(
    project,
    onClose,
  )

  return (
    <Modal
      opened={opened}
      size="lg"
      padding="xl"
      centered
      onClose={onClose}
      title={
        <Text size="lg" fw="bold">
          Selectionner la raison de l&apos;abandon du projet
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SchedulingIssueFormField
          options={ABORT_REASONS_OPTIONS}
          form={form}
          initialValues={{
            schedulingIssueMainReason: project.schedulingIssueMainReason,
            schedulingIssueSubReason: project.schedulingIssueSubReason,
          }}
        />
        <Group justify="flex-end" mt={24}>
          <Button variant="outline" onClick={onClose} loading={isLoading}>
            Annuler
          </Button>
          <Button type="submit" loading={isLoading} disabled={!form.isValid}>
            Valider
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
