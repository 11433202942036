import dayjs from "dayjs"

import { formatDate } from "@ensol/shared/utils/format"

export const getSummaryUrl = (installationSlug: string) =>
  `${import.meta.env.VITE_LANDING_URL}/summary/${installationSlug}`

export const getMonthlyReportUrl = ({
  installationSlug,
  productionStartDate,
  isSimulated,
}: {
  installationSlug: string
  productionStartDate: Date | null
  isSimulated: boolean
}) => {
  const baseUrl = `${import.meta.env.VITE_LANDING_URL}/reports/monthly/${installationSlug}`

  const firstProductionMonth = formatDate(
    dayjs(productionStartDate).startOf("month"),
    "YYYY-MM-DD",
  )

  return `${baseUrl}?date=${firstProductionMonth}&isSimulated=${isSimulated}`
}
