import { Button, Group, Stack, Text } from "@mantine/core"
import {
  IconAt,
  IconMap,
  IconPhone,
  IconSolarPanel,
  IconUser,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import { MapLink } from "@ensol/entool/components/MapLink"
import { Section } from "@ensol/entool/components/Section"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

import { ProjectOwners } from "./ProjectOwners"

type Props = {
  project: ProjectResponses.Project
}

export const MobileOverview = ({ project }: Props) => {
  const { installation } = project
  const { house } = installation
  const { prospect } = house

  const installers = getProjectInstallers(project)

  return (
    <PageLayout
      title={getFullName(prospect)}
      goBackPath="/projects"
      contentContainerProps={{ px: 0 }}
    >
      <Stack
        gap="12"
        align="flex-start"
        px="16"
        pb="24"
        flex="1"
        style={{ overflowY: "auto" }}
      >
        <Section title="Client" titleOrder={4} icon={IconUser}>
          <Stack gap="0">
            <MapLink address={house.address} />
            <Button
              href={`mailto:${prospect.email}`}
              component="a"
              variant="transparent"
              p="0"
              justify="flex-start"
            >
              <Group gap="4">
                <IconAt size={16} />
                <Text size="sm">{prospect.email}</Text>
              </Group>
            </Button>
            <Button
              href={`tel:${prospect.phone}`}
              component="a"
              variant="transparent"
              p="0"
              justify="flex-start"
            >
              <Group gap="4">
                <IconPhone size={16} />
                <Text size="sm">{prospect.phone}</Text>
              </Group>
            </Button>
          </Stack>
        </Section>
        <Section title="Installation" titleOrder={4} icon={IconSolarPanel}>
          <InstallationCharacteristics
            installation={installation}
            installers={installers}
          />
        </Section>
        <Section title="Projet" titleOrder={4} icon={IconMap}>
          <ProjectOwners project={project} background="white" />
        </Section>
      </Stack>
    </PageLayout>
  )
}
