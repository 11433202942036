import { useLocalStorage } from "@uidotdev/usehooks"
import { useContext } from "react"

import { ProjectSearchContext } from "@ensol/entool/components/entities/Project/ProjectSearchProvider"

export const PROJECT_SEARCHABLE_PROPS = {
  search: "search",
  installerId: "installerId",
  supplierId: "supplierId",
  operationsOwnerId: "operationsOwnerId",
  salesOwnerId: "salesOwnerId",
  adminOwnerId: "adminOwnerId",
} as const

export const useProjectsSearch = () => {
  const { search, setSearch } = useContext(ProjectSearchContext)
  const [installerId, setInstallerId] = useLocalStorage<string | null>(
    PROJECT_SEARCHABLE_PROPS.installerId,
  )
  const [supplierId, setSupplierId] = useLocalStorage<string | null>(
    PROJECT_SEARCHABLE_PROPS.supplierId,
  )
  const [salesOwnerId, setSalesOwnerId] = useLocalStorage<string | null>(
    PROJECT_SEARCHABLE_PROPS.salesOwnerId,
  )
  const [operationsOwnerId, setOperationsOwnerId] = useLocalStorage<
    string | null
  >(PROJECT_SEARCHABLE_PROPS.operationsOwnerId)
  const [adminOwnerId, setAdminOwnerId] = useLocalStorage<string | null>(
    PROJECT_SEARCHABLE_PROPS.adminOwnerId,
  )

  return {
    search,
    setSearch,
    installerId,
    setInstallerId,
    supplierId,
    setSupplierId,
    salesOwnerId,
    setSalesOwnerId,
    operationsOwnerId,
    setOperationsOwnerId,
    adminOwnerId,
    setAdminOwnerId,
  }
}
