import { Modal, ScrollArea, Group, Title, ActionIcon } from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { IconPencil, IconUser } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { ProspectForm } from "@ensol/entool/components/form/Prospect/ProspectForm"
import { devices } from "@ensol/entool/styles/theme"

type Props = {
  prospect: ProspectResponses.Prospect
  onSuccess?: () => void
}

export const EditProspectButton = ({ prospect, onSuccess }: Props) => {
  const [isModalOpen, { open, close }] = useDisclosure(false)
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <>
      <ActionIcon
        size={isSmallScreen ? "sm" : "md"}
        color="black"
        onClick={open}
        mt={{ base: 0, md: 4 }}
        mb={{ base: 4, md: 0 }}
      >
        <IconPencil />
      </ActionIcon>
      <Modal
        size={700}
        radius="lg"
        padding={isSmallScreen ? "md" : "xl"}
        opened={isModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={close}
        title={
          <Group gap={8}>
            <IconUser size={26} />
            <Title order={2}>Client</Title>
          </Group>
        }
      >
        <ProspectForm
          prospect={prospect}
          onSuccess={() => {
            onSuccess?.()
            notifications.show({ message: "Client mise à jour !" })
            close()
          }}
        />
      </Modal>
    </>
  )
}
