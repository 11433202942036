import { SignedOut, RedirectToSignIn, SignedIn } from "@clerk/clerk-react"
import { Outlet } from "react-router-dom"

export const AuthGuard = () => (
  <>
    <SignedIn>
      <Outlet />
    </SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
  </>
)
