import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { transformSchemaForApi } from "@ensol/types/forms/utils"
import * as z from "zod"

export const depositToSendStepFormSchema = z.object({
  paymentNotes: z.string().optional(),
  paymentHoldEndDate: z.date().nullable().optional(),
  fundingSource: z.nativeEnum(FundingSource, {
    invalid_type_error: "Champ obligatoire",
  }),
})

export type DepositToSendStepInput = z.infer<typeof depositToSendStepFormSchema>

export const postDepositStepsFormSchema = z.object({
  paymentNotes: z.string().optional(),
  fundingSource: z.nativeEnum(FundingSource, {
    invalid_type_error: "Champ obligatoire",
  }),
})

export type PostDepositStepInput = z.infer<typeof postDepositStepsFormSchema>

export type PaymentStepInput = DepositToSendStepInput | PostDepositStepInput

export const cetelemFormSchema = z.object({
  cetelemFinancingRequestPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export const paymentFormSchema = transformSchemaForApi(
  depositToSendStepFormSchema
    .merge(postDepositStepsFormSchema)
    .merge(cetelemFormSchema),
)

export type CetelemFormInput = z.infer<typeof cetelemFormSchema>
