import { SimpleGrid } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconAt,
  IconMap2,
  IconPhone,
  IconSolarPanel,
} from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import k2Logo from "@ensol/entool/assets/k2Logo.webp"
import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { Link } from "@ensol/entool/components/Link"
import { ProjectHeader } from "@ensol/entool/components/entities/Project/Header"
import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import { K2_LOGIN_URL } from "@ensol/entool/config"
import { devices } from "@ensol/entool/styles/theme"

type Props = {
  prospect: ProspectResponses.Prospect
  projectId: string
  address: string
  installationId?: string
  children?: React.ReactNode
}

export const Header = ({
  prospect,
  projectId,
  address,
  installationId,
  children,
}: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const { hubspotContactId } = prospect

  return (
    <ProjectHeader
      projectId={projectId}
      prospect={prospect}
      backPath="/sales"
      LeftComponent={
        <>
          <HeaderInfos
            infos={[
              { Icon: IconMap2, text: address },
              ...(!isMobile
                ? [
                    { Icon: IconAt, text: prospect.email },
                    { Icon: IconPhone, text: prospect.phone },
                  ]
                : []),
            ]}
          />
          {children}
        </>
      }
      RightComponent={
        <SimpleGrid cols={2} spacing={8}>
          <Link
            label="Raccourci K2"
            href={K2_LOGIN_URL}
            Icon={<img src={k2Logo} height={20} />}
            color="red"
            isExternal
          />
          <HubspotLink hubspotContactId={hubspotContactId} variant="outline" />
          {installationId && (
            <Link
              label="Installation"
              href={`/installations/${installationId}`}
              Icon={<IconSolarPanel />}
            />
          )}
        </SimpleGrid>
      }
    />
  )
}
