import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { BatteryQuoteDetails } from "@ensol/types/quote"

import { MountingType } from "@ensol/shared/entities/installations/batteries"
import {
  BATTERY_ADMIN_FEE_COST_IN_EURO,
  BATTERY_VAT_RATE,
  computeAllBatteryCosts,
  computeBatteryAccessoriesPrice,
  computeBatteryUnitPrice,
  computeBatteryWorkforceCost,
} from "@ensol/shared/entities/installations/costs/battery"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getBattery } from "@ensol/shared/material/batteries"

export const computeBatteryQuoteDetails = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): BatteryQuoteDetails => {
  const {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
  } = computeAllBatteryCosts({
    batteryInstallation,
  })

  const battery = getBattery(batteryInstallation.type)
  return {
    material: {
      battery: {
        name: "Batterie",
        description: `${battery.name} - Capacité ${battery.capacity} kWh\nGarantie ${battery.warranty} ans`,
        quantity: 1,
        price: computeBatteryUnitPrice(batteryInstallation.type),
      },
      accessories: {
        name: "Accessoires",
        description:
          battery.brand === "Enphase"
            ? batteryInstallation.mountingType === MountingType.ON_WALL
              ? "Kit de communication et câbles"
              : "Kit de communication, câbles et support au sol"
            : battery.brand === "Huawei"
              ? "Câbles et support mural"
              : "",
        quantity: 1,
        price: computeBatteryAccessoriesPrice(
          batteryInstallation.type,
          batteryInstallation.mountingType,
        ),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(batteryInstallation.extraWorks),
    workforce: {
      installation: {
        name: "Installation batterie",
        description: "Forfait pose et mise en service de la batterie",
        quantity: 1,
        price: computeBatteryWorkforceCost(batteryInstallation),
      },
      adminFees: {
        name: "Forfait administratif batterie",
        description: "Attestation de conformité Consuel",
        quantity: 1,
        price: BATTERY_ADMIN_FEE_COST_IN_EURO,
      },
    },
    vatRate: BATTERY_VAT_RATE,
    totals: {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
    },
  }
}
