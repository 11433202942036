import { useState } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

export enum ProjectDrawerTab {
  NOTES = "NOTES",
  DETAILS = "DETAILS",
}

export const useProjectDrawer = () => {
  const [selectedProject, setSelectedProject] =
    useState<ProjectResponses.ProjectOverview | null>(null)
  const [activeDrawerTab, setActiveDrawerTab] = useState<ProjectDrawerTab>(
    ProjectDrawerTab.NOTES,
  )

  return {
    selectedProject,
    activeDrawerTab,
    setActiveDrawerTab,
    openDrawer: (
      project: ProjectResponses.ProjectOverview,
      tab: ProjectDrawerTab,
    ) => {
      setSelectedProject(project)
      setActiveDrawerTab(tab)
    },
    closeDrawer: () => setSelectedProject(null),
  }
}
