import React from "react"

export type OptionType = string | number | boolean

export type Option<T extends OptionType, Metadata = null> = {
  label: string
  value: T
  subtitle?: string | React.ReactNode
  disabled?: boolean
  metadata?: Metadata
}

export const findSelectedOption = <T extends OptionType, Metadata = null>(
  selectedValue: T | null,
  options: Option<T, Metadata>[],
) => options?.find((option) => selectedValue === option.value)

export type GroupedOptions<T extends OptionType> = {
  group: string
  items: T[]
}
