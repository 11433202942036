import { Paper, Group, Text } from "@mantine/core"
import { IconFileDollar } from "@tabler/icons-react"

import { QuoteStatusBadge } from "@ensol/entool/components/entities/Installation/QuoteStatusBadge"
import { useGetInstallationQuotesQuery } from "@ensol/entool/queries/installations"

import { ActiveQuoteActions } from "./ActiveQuoteActions"
import { ActiveQuoteDocument } from "./ActiveQuoteDocument"
import { GenerateQuoteButton } from "./GenerateQuoteButton"
import { PreviousQuotes } from "./PreviousQuotes"

type Props = {
  installationId: string
  installationDate: Date
  quoteFileName: string
  isInstallationValid: boolean
}

export const Quote = ({
  installationId,
  installationDate,
  quoteFileName,
  isInstallationValid,
}: Props) => {
  const { data: installationQuotes, isPending } =
    useGetInstallationQuotesQuery(installationId)

  if (isPending || installationQuotes === undefined) {
    return null
  }

  const { activeQuote, previousQuotes } = installationQuotes

  return (
    <Paper withBorder shadow="none" w={400} px={24} py={16}>
      <Group justify="space-between">
        <Group gap={6}>
          <IconFileDollar />
          <Text fw="bold">Devis</Text>
          <QuoteStatusBadge status={activeQuote?.status} />
        </Group>
        <GenerateQuoteButton
          activeQuote={activeQuote}
          installationId={installationId}
          installationDate={installationDate}
          isInstallationValid={isInstallationValid}
        />
      </Group>
      {activeQuote && (
        <>
          <ActiveQuoteActions
            activeQuote={activeQuote}
            installationId={installationId}
            installationDate={installationDate}
          />
          <ActiveQuoteDocument
            activeQuote={activeQuote}
            quoteFileName={quoteFileName}
          />
        </>
      )}
      {previousQuotes.length > 0 && (
        <PreviousQuotes
          previousQuotes={previousQuotes}
          quoteFileName={quoteFileName}
        />
      )}
    </Paper>
  )
}
