import { ButtonProps } from "@mantine/core"

import { InverterType } from "@ensol/types/prisma"

import {
  getEnphaseUrl,
  FOLLOW_UP_APPLICATIONS,
} from "@ensol/shared/material/photovoltaic/applications"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"

import enphaseLogo from "@ensol/entool/assets/enphaseLogo.png"
import solarFusionLogo from "@ensol/entool/assets/solarFusionLogo.png"
import { Link } from "@ensol/entool/components/Link"

type Props = {
  inverterType: InverterType
  externalPlantIdentifier: string
} & ButtonProps

export const ConstructorAppLink = ({
  inverterType,
  externalPlantIdentifier,
  ...props
}: Props) => {
  const inverter = getInverter(inverterType)

  if (inverter.brand === "Enphase") {
    return (
      <Link
        label="Enlighten"
        href={getEnphaseUrl(externalPlantIdentifier)}
        color="orange"
        Icon={<img src={enphaseLogo} height={16} />}
        isExternal
        {...props}
      />
    )
  }

  if (inverter.brand === "Huawei") {
    return (
      <Link
        label="FusionSolar"
        href={FOLLOW_UP_APPLICATIONS.Huawei.url}
        color="indigo"
        Icon={<img src={solarFusionLogo} height={16} />}
        isExternal
        {...props}
      />
    )
  }

  return null
}
