import { ConsuelStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { Pending } from "./1-Pending"
import { QualiPvVisitSubmitted } from "./10-QualiPvVisitSubmitted"
import { WorkNeeded } from "./11-WorkNeeded"
import { WorkScheduled } from "./12-WorkScheduled"
import { WorkDone } from "./13-WorkDone"
import { Validated } from "./14-Validated"
import { WaitingForSolteo } from "./2-WaitingForSolteo"
import { Submitted } from "./3-Submitted"
import { VisitNeeded } from "./4-VisitNeeded"
import { VisitScheduled } from "./5-VisitScheduled"
import { VisitSubmitted } from "./6-VisitSubmitted"
import { QualiPvVisitNeeded } from "./7-QualiPvVisitNeeded"
import { QualiPvVisitScheduled } from "./8-QualiPvVisitScheduled"
import { QualiPvDocToSend } from "./9-QualiPvDocToSend"

export const getConsuelStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case ConsuelStep.PENDING:
      return { StepComponent: Pending, isCancellable: false }
    case ConsuelStep.WAITING_FOR_SOLTEO:
      return { StepComponent: WaitingForSolteo }
    case ConsuelStep.SUBMITTED:
      return { StepComponent: Submitted }
    case ConsuelStep.VISIT_NEEDED:
      return { StepComponent: VisitNeeded }
    case ConsuelStep.VISIT_SCHEDULED:
      return { StepComponent: VisitScheduled }
    case ConsuelStep.VISIT_WAITING_VALIDATION:
      return { StepComponent: VisitSubmitted }
    case ConsuelStep.QUALITY_PV_VISIT_NEEDED:
      return { StepComponent: QualiPvVisitNeeded }
    case ConsuelStep.QUALITY_PV_VISIT_SCHEDULED:
      return { StepComponent: QualiPvVisitScheduled }
    case ConsuelStep.QUALITY_PV_DOC_TO_SEND:
      return { StepComponent: QualiPvDocToSend }
    case ConsuelStep.QUALITY_PV_WAITING_VALIDATION:
      return { StepComponent: QualiPvVisitSubmitted }
    case ConsuelStep.WORK_NEEDED:
      return { StepComponent: WorkNeeded }
    case ConsuelStep.WORK_SCHEDULED:
      return { StepComponent: WorkScheduled }
    case ConsuelStep.WORK_DONE:
      return { StepComponent: WorkDone }

    case ConsuelStep.VALIDATED:
      return { StepComponent: Validated }
    default:
      return { StepComponent: null }
  }
}
