import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { House, InverterType, Prisma, Prospect } from "@ensol/types/prisma"

export type ProjectWithAlerts = {
  id: string
  inverterType: InverterType | null
  externalPlantIdentifier: string
  installer: Prisma.InstallerGetPayload<null> | null
  technicalVisitInstaller: Prisma.InstallerGetPayload<null> | null
  prospect: Pick<Prospect, "firstName" | "lastName">
  house: Pick<House, "postcode" | "city">
  alerts: Prisma.InstallationAlertGetPayload<null>[]
}

export enum ProjectAlertCategory {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
}

export type TaskDueDateProperty =
  | "technicalVisitSchedulingHoldEndDate"
  | "installationSchedulingHoldEndDate"

export type ProjectTaskDetails = {
  task: ProjectTask
  type: ProjectTaskType
  dueDate?: {
    property: TaskDueDateProperty
    processId: ProcessId
  }
}

export enum ProjectTask {
  WAITING_FOR_INFO = "Waiting for Info",
  VT_TO_SCHEDULE = "VT - To Schedule",
  VT_WAITING_FOR_INSTALLER = "VT - Waiting for Installer",
  VT_TO_CALL_BACK = "VT - To Call Back",
  VT_CANNOT_SCHEDULE = "VT - Cannot Schedule",
  WAITING_FOR_VT = "Waiting for VT",
  WAITING_FOR_VT_VALIDATION = "Waiting for VT Validation",
  INSTALLATION_TO_SCHEDULE = "Installation - To Schedule",
  INSTALLATION_WAITING_FOR_INSTALLER = "Installation - Waiting for Installer",
  INSTALLATION_TO_CALL_BACK = "Installation - To Call Back",
  INSTALLATION_CANNOT_SCHEDULE = "Installation - Cannot Schedule",
  WAITING_FOR_DEPOSIT_PAYMENT = "Waiting for Deposit Payment",
  WAITING_FOR_SITE_PREPARATION = "Waiting for Site Preparation",
  WAITING_FOR_INSTALLATION = "Waiting for Installation",
  INSTALLATION_IN_PROGRESS = "Installation in Progress",
  WAITING_FOR_FINAL_PAYMENT = "Waiting for Final Payment",
  WAITING_FOR_CONSUEL = "Waiting for Consuel",
  WAITING_FOR_CONSUEL_VISIT = "Waiting for Consuel Visit",
  WAITING_FOR_CONSUEL_VALIDATION = "Waiting for Consuel Validation",
  WAITING_FOR_SERVICE_ACTIVATION = "Waiting for Service Activation",
  FOLLOW_UP = "Follow Up",
}

export enum ProjectTaskType {
  MONITORING = "monitoring",
  TODO = "todo",
  REMINDER = "reminder",
}

export enum ProjectDocumentType {
  INSTALLATION_CERTIFICATE = "installation-certificate",
  EDF_OA_CERTIFICATE = "edf-oa-certificate",
  CETELEM_FINANCING_REQUEST = "cetelem-financing-request",
}
