import { IconArrowBackUp } from "@tabler/icons-react"
import * as z from "zod"

import { scheduledStepFormSchema } from "@ensol/types/forms/projects/installation"
import { checkField } from "@ensol/types/forms/projects/shared"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { InstallationForm } from "./components/InstallationForm"

export const Scheduled = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={scheduledStepFormSchema}
    initialValues={getInitialValues(scheduledStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reschedule",
        label: "Repasser en prêt à planifier",
        color: "orange",
        Icon: IconArrowBackUp,
        confirmationMessage:
          "Les évènements installateur et client seront supprimés. L’installateur seul sera averti. Continuer ?",
      },
      {
        action: "validate",
        label: "Chantier préparé",
        validateAction: validateAction(
          z.object({
            areInstallationsCommentsConsidered: checkField,
            isActivationPrepared: checkField,
            isClientAccountCreated: checkField,
            isTechnicalVisitReportSent: checkField,
            isInstallationReportPrepared: checkField,
          }),
        ),
      },
    ]}
    relatedProcesses={[
      "hardwareStep",
      "technicalVisitStep",
      "preliminaryRequestStep",
      "paymentStep",
    ]}
    {...props}
  />
)
