import { Avatar, Group, Select, SelectProps, ThemeIcon } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import _ from "lodash"
import { useEffect } from "react"

import { Team } from "@ensol/types/prisma-client/index.js"

import { useAuthenticatedUser, useListUsers } from "@ensol/entool/queries/users"
import { Option } from "@ensol/entool/utils/form/radio"

type UserSelectOption = Option<string> & { avatarUrl: string }

export const UserSelect = ({
  teams,
  withDefault,
  value,
  onChange,
  ...props
}: SelectProps & { teams: Team[]; withDefault: boolean }) => {
  const { users, error } = useListUsers()
  const { data: user } = useAuthenticatedUser()
  const options = users
    .filter((user) => _.intersection(user.teams, teams).length > 0)
    .map(({ id, avatarUrl, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
      avatarUrl,
    }))

  useEffect(() => {
    if (withDefault && !value && user !== undefined) {
      const defaultOption = options.find((option) => option.value === user.id)

      if (defaultOption) {
        onChange?.(user.id, defaultOption)
      }
    }
  }, [withDefault, user, value, onChange, options])

  if (error) {
    return null
  }

  return (
    <Select
      {...props}
      value={value}
      onChange={onChange}
      data={options}
      searchable
      renderOption={({ option, checked }) => (
        <Group flex="1" gap="xs" justify="space-between">
          <Group>
            <Avatar src={(option as UserSelectOption).avatarUrl} size="24" />
            {option.label}
          </Group>
          {checked && (
            <ThemeIcon c="gray">
              <IconCheck />
            </ThemeIcon>
          )}
        </Group>
      )}
    />
  )
}
