import { GreendealStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { EmailPending } from "./1-EmailPending"
import { SubmissionPending } from "./2-SubmissionPending"
import { Submitted } from "./3-Submitted"
import { DoneByClient } from "./4-DoneByClient"
import { FileCompleted } from "./5-FileCompleted"
import { Validated } from "./6-Validated"

export const getGreendealStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case GreendealStep.EMAIL_PENDING:
      return { StepComponent: EmailPending, isCancellable: false }
    case GreendealStep.SUBMISSION_PENDING:
      return { StepComponent: SubmissionPending }
    case GreendealStep.SUBMITTED:
      return { StepComponent: Submitted }
    case GreendealStep.DONE_BY_CLIENT:
      return { StepComponent: DoneByClient }
    case GreendealStep.FILE_COMPLETED:
      return { StepComponent: FileCompleted }
    case GreendealStep.VALIDATED:
      return { StepComponent: Validated }
    default:
      return { StepComponent: null }
  }
}
