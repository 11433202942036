import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { CreateInstallationInput } from "@ensol/types/forms/installations"
import { UpdateInstallationAlertInput } from "@ensol/types/forms/installations/alerts"
import { PanelsLayout } from "@ensol/types/prisma-client"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

const fetchInstallation = async (
  id: string,
): Promise<InstallationsResponses.Installation> => {
  const response = await httpClient.get(`/installations/${id}`)
  return response.data
}

export const useGetInstallationQuery = (id: string) => {
  return useQuery<InstallationsResponses.Installation>({
    queryKey: ["installations", id],
    queryFn: async () => fetchInstallation(id),
  })
}

export const useCreateInstallationMutation = () => {
  const navigate = useNavigate()
  return useMutation<
    InstallationsResponses.Installation,
    unknown,
    CreateInstallationInput
  >({
    mutationFn: async (installation) => {
      const response = await httpClient.post("/installations", installation)
      return response.data
    },

    onSuccess: async (data) => {
      notifications.show({ message: "Installation enregistrée !" })
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["installations", data.id],
        }),
        queryClient.invalidateQueries({
          queryKey: ["prospects", data.prospectId],
        }),
      ])
      navigate(`/installations/${data.id}`)
    },
  })
}

export const useUpdateInstallationMutation = <T>(
  id: string,
  onSuccess?: () => Promise<void>,
) =>
  useMutation<InstallationsResponses.Installation, unknown, T>({
    mutationFn: async (installation) => {
      const response = await httpClient.put(
        `/installations/${id}`,
        installation,
      )
      return response.data
    },
    onSuccess: async (data) => {
      notifications.show({ message: "Installation mise à jour !" })
      await Promise.all([
        onSuccess?.(),
        queryClient.invalidateQueries({
          queryKey: ["installations", id],
        }),
        queryClient.invalidateQueries({
          queryKey: ["prospects", data.prospectId],
        }),
      ])
    },
  })

type PanelsLayoutInput = {
  panelsGrids: google.maps.Polygon[]
  screenshotBase64: string
  withOrthorectifiedMap: boolean
}

export const useSavePanelsLayoutMutation = (installationId: string) => {
  return useMutation<PanelsLayout, unknown, PanelsLayoutInput>({
    mutationFn: async ({
      panelsGrids,
      screenshotBase64,
      withOrthorectifiedMap,
    }) => {
      const jsonPaths = panelsGrids.map((panelsGrid) =>
        panelsGrid
          .getPaths()
          .getArray()
          .map((path) => path.getArray().map((point) => point.toJSON())),
      )

      const response = await httpClient.post(
        `/installations/${installationId}/panels-layout`,
        { paths: jsonPaths, screenshotBase64, withOrthorectifiedMap },
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["installations", installationId],
      })
      notifications.show({ message: "Calepinage sauvegardé !" })
    },
  })
}

export const useDuplicateInstallation = (
  prospectId: string,
  installationId: string,
) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(
        `/installations/${installationId}/duplicate`,
      )
      return response.data
    },

    onSuccess: async (installation) => {
      await queryClient.invalidateQueries({
        queryKey: ["prospects", prospectId],
      })
      notifications.show({ message: "Installation dupliquée !" })
      navigate(`/installations/${installation.id}`)
    },
  })
}

export const useDeleteInstallation = (
  prospectId: string,
  installationId: string,
) => {
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.delete(
        `/installations/${installationId}`,
      )
      return response.data
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["prospects", prospectId],
      })
      notifications.show({ message: "Installation supprimée !" })
    },
  })
}

export const useGetInstallationQuotesQuery = (installationId: string) => {
  return useQuery<InstallationsResponses.Quotes>({
    queryKey: ["installation", installationId, "quotes"],
    queryFn: async () => {
      const response = await httpClient.get(
        `/installations/${installationId}/quotes`,
      )
      return response.data
    },
  })
}

export const useListExternalPlantsQuery = (
  installationId: string,
  isQueryEnabled: boolean,
  setIsQueryEnabled: (isQueryEnabled: boolean) => void,
) =>
  useQuery<InstallationsResponses.ExternalPlant[]>({
    queryKey: ["installations", installationId, "external-plants"],
    queryFn: async () => {
      try {
        const response = await httpClient.get(
          `/installations/${installationId}/external-plants`,
        )

        return response.data
      } catch (error) {
        notifications.show({
          title: "Une erreur est survenue",
          message: error?.toString(),
          color: "red",
        })
        setIsQueryEnabled(false)
        throw error
      }
    },
    retry: false,
    enabled: isQueryEnabled,
  })

export const useUpdateInstallationAlertMutation = (alertId: string) => {
  return useMutation<void, unknown, UpdateInstallationAlertInput>({
    mutationFn: async (data) => {
      const response = await httpClient.put(
        `/installations/alerts/${alertId}`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["projects", "alerts"] })
      notifications.show({ message: "Alerte mise à jour !" })
    },
  })
}
