import { submittedStepFormSchema } from "@ensol/types/forms/projects/greendeal"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { GreendealForm } from "@ensol/entool/pages/OpsProject/processes/Greendeal/components/GreendealForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const Submitted = ({ project, ...props }: StepComponentProps) => {
  return (
    <GreendealForm
      schema={submittedStepFormSchema}
      initialValues={getInitialValues(submittedStepFormSchema, project)}
      project={project}
      actions={[{ action: "validate", label: "Dossier complété" }]}
      {...props}
    />
  )
}
