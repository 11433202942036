import { Button, Group, Text } from "@mantine/core"
import { IconMap2 } from "@tabler/icons-react"

type Props = {
  address: string
}

export const MapLink = ({ address }: Props) => (
  <Button
    href={`http://maps.google.com/?q=${address}`}
    target="_blank"
    component="a"
    variant="transparent"
    p="0"
    justify="flex-start"
  >
    <Group gap="4">
      <IconMap2 size={16} />
      <Text size="sm">{address}</Text>
    </Group>
  </Button>
)
