import { Group, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { TablerIcon } from "@tabler/icons-react"

import { devices } from "@ensol/entool/styles/theme"

type Props = {
  infos: {
    Icon: TablerIcon
    text: string
  }[]
}

export const HeaderInfos = ({ infos }: Props) => {
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <Group>
      {infos.map(({ Icon, text }) => (
        <Group gap="4" key={text}>
          <Icon size={16} />
          <Text size={isSmallScreen ? "xs" : "sm"}>{text}</Text>
        </Group>
      ))}
    </Group>
  )
}
