import { Tooltip as MantineTooltip, TooltipProps } from "@mantine/core"

export const Tooltip = ({
  children,
  ...rest
}: Omit<
  TooltipProps,
  "withArrow" | "radius" | "arrowPosition" | "arrowSize" | "color"
>) => {
  return (
    <MantineTooltip
      withArrow
      radius="md"
      arrowPosition="center"
      arrowSize={6}
      color="blue.8"
      {...rest}
    >
      {children}
    </MantineTooltip>
  )
}
