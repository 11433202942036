import { Divider, MantineStyleProps, Stack, Title } from "@mantine/core"
import { ReactNode } from "react"

import { BackButton } from "@ensol/entool/components/BackButton"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

import { ContentContainer } from "./ContentContainer"

type Props = {
  title: string | ReactNode
  goBackPath?: string
  contentContainerProps?: MantineStyleProps
  children: ReactNode
}

export const PageLayout = ({
  title,
  goBackPath,
  contentContainerProps,
  children,
}: Props) => {
  return (
    <Stack flex="1" miw="0" p="0" gap={8}>
      <Stack bg="blue.0" pt="16" align="left" gap={8}>
        <Stack px={CONTENT_PADDING_IN_PX} gap="sm">
          {goBackPath !== undefined && (
            <BackButton to={goBackPath}>Retour</BackButton>
          )}
          {typeof title === "string" ? <Title order={1}>{title}</Title> : title}
        </Stack>
        <Divider w="100%" />
      </Stack>
      <ContentContainer
        flex="1"
        mih="0"
        display="flex"
        {...contentContainerProps}
      >
        {children}
      </ContentContainer>
    </Stack>
  )
}
