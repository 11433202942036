import { Text } from "@mantine/core"

import { NotFound } from "@ensol/entool/components/NotFound"

type Props = {
  value: string | null
}

export const TextInfo = ({ value }: Props) =>
  value !== null ? (
    <Text size="md" fw="bold">
      {value}
    </Text>
  ) : (
    <NotFound type="text" />
  )
