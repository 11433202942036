import { Button } from "@mantine/core"
import { IconLinkPlus } from "@tabler/icons-react"

import { Link } from "@ensol/entool/components/Link"
import { INTERCOM_INBOX_URL } from "@ensol/entool/config"

import { LinkForm } from "./LinkForm"
import { useLinkIntercomConversation } from "./useLinkIntercomConversation"

type Props = {
  alertId: string
  intercomConversationId: number | null
}

const INTERCOM_BUTTON_PROPS = {
  p: "4px 8px",
  h: "24",
  w: "fit-content",
  variant: "light",
}

export const IntercomConversation = ({
  alertId,
  intercomConversationId,
}: Props) => {
  const { isLinkFormOpen, openLinkForm, closeLinkForm, onSubmit } =
    useLinkIntercomConversation(alertId)

  if (intercomConversationId === null) {
    return (
      <>
        <Button
          {...INTERCOM_BUTTON_PROPS}
          leftSection={<IconLinkPlus size={18} />}
          onClick={openLinkForm}
        >
          Lier à une conversation Intercom
        </Button>
        <LinkForm
          isOpen={isLinkFormOpen}
          close={closeLinkForm}
          onSubmit={onSubmit}
        />
      </>
    )
  }

  return (
    <Link
      {...INTERCOM_BUTTON_PROPS}
      label="Ouvrir la conversation Intercom"
      href={`${INTERCOM_INBOX_URL}/conversation/${intercomConversationId}`}
      isExternal
    />
  )
}
