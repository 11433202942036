import { ActionIcon, Group, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconSettings } from "@tabler/icons-react"
import { Helmet } from "react-helmet-async"

import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

import { Calendar } from "./Calendar"
import { EventsMenu } from "./Menu/EventsMenu"
import { useEventsMenu } from "./Menu/useEventsMenu"

export const ProjectsEventsPage = () => {
  const isSmallScreen = useMediaQuery(devices.md)
  const {
    isMenuDrawerOpen,
    openMenuDrawer,
    closeMenuDrawer,
    eventTypes,
    setEventTypes,
    installers,
    setInstallers,
    coverageZones,
    setCoverageZones,
    showInstallerEvents,
    setShowInstallerEvents,
    showEventsWithoutInstaller,
    setShowEventsWithoutInstaller,
    showWeekEnds,
    setShowWeekEnds,
    showPublicHolidays,
    setShowPublicHolidays,
  } = useEventsMenu()

  return (
    <PageLayout
      title={
        <Group w="100%" justify="space-between">
          <Title order={1}>Calendrier</Title>
          {isSmallScreen && (
            <ActionIcon color="black" onClick={openMenuDrawer}>
              <IconSettings />
            </ActionIcon>
          )}
        </Group>
      }
      contentContainerProps={{ px: 0 }}
    >
      <Helmet>
        <title>Calendrier | Entool</title>
      </Helmet>
      <Group flex="1" pb={CONTENT_PADDING_IN_PX} gap="32">
        <Calendar
          eventTypes={eventTypes}
          installers={installers}
          coverageZones={coverageZones}
          showInstallerEvents={showInstallerEvents}
          showEventsWithoutInstaller={showEventsWithoutInstaller}
          showWeekEnds={showWeekEnds}
          showPublicHolidays={showPublicHolidays}
        />
        <EventsMenu
          showPublicHolidays={showPublicHolidays}
          setShowPublicHolidays={setShowPublicHolidays}
          isOpen={isMenuDrawerOpen}
          onClose={closeMenuDrawer}
          eventTypes={eventTypes}
          setEventTypes={setEventTypes}
          installers={installers}
          setInstallers={setInstallers}
          coverageZones={coverageZones}
          setCoverageZones={setCoverageZones}
          showInstallerEvents={showInstallerEvents}
          setShowInstallerEvents={setShowInstallerEvents}
          showEventsWithoutInstaller={showEventsWithoutInstaller}
          setShowEventsWithoutInstaller={setShowEventsWithoutInstaller}
          showWeekEnds={showWeekEnds}
          setShowWeekEnds={setShowWeekEnds}
        />
      </Group>
    </PageLayout>
  )
}
