import {
  Stack,
  Select,
  Button,
  ActionIcon,
  TextInput,
  NumberInput,
  Group,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconPlus, IconTrash } from "@tabler/icons-react"

import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { ExtraWorks } from "@ensol/types/installation"

import { ExtraWorkType, getExtraWork } from "@ensol/shared/material/extraWorks"

import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { EXTRA_WORKS_OPTIONS } from "@ensol/entool/utils/installations/extraWorks"

type Props = {
  value?: ExtraWorks
  onChange: (value: ExtraWorks) => void
}

export const ExtraWorksSelect = ({ value, onChange }: Props) => {
  const form = useForm({
    onValuesChange: (values) => {
      form.validate()
      onChange(values.extraWorks)
    },
    validateInputOnChange: true,
    validate: zodResolver(extraWorksSchema),
    initialValues: {
      extraWorks: value ?? [],
    },
  })

  return (
    <Stack align="flex-end" gap="20">
      {form.values.extraWorks.map((extraWork, index) => {
        return (
          <Group
            key={`extraWork_${extraWork.type}_${index}`}
            align="flex-start"
          >
            <Stack gap="8">
              <Select
                label="Type de travaux"
                w={FIELD_WIDTH}
                data={EXTRA_WORKS_OPTIONS}
                searchable
                data-test="extraWorkTypeSelect"
                {...form.getInputProps(`extraWorks.${index}.type`)}
                onChange={(type) => {
                  const extraWork = getExtraWork(type as ExtraWorkType)
                  form
                    .getInputProps(`extraWorks.${index}`)
                    .onChange({ type, ...extraWork })
                }}
              />
              {extraWork.type === ExtraWorkType.CUSTOM && (
                <Group>
                  <TextInput
                    flex="1"
                    placeholder="Catégorie"
                    {...form.getInputProps(`extraWorks.${index}.category`)}
                  />
                  <TextInput
                    flex="1"
                    placeholder="Description"
                    {...form.getInputProps(`extraWorks.${index}.description`)}
                  />
                </Group>
              )}
            </Stack>
            <Stack w="80" align="center">
              <NumberInput
                label="Prix HT"
                suffix="€"
                min={0}
                hideControls
                {...form.getInputProps(`extraWorks.${index}.price`)}
              />
            </Stack>
            <ActionIcon
              aria-label="Supprimer"
              p="4"
              mt="24"
              color="red"
              onClick={() => form.removeListItem("extraWorks", index)}
            >
              <IconTrash size={16} color="red" />
            </ActionIcon>
          </Group>
        )
      })}
      <Button
        size="sm"
        variant="transparent"
        p="0"
        leftSection={<IconPlus size={18} />}
        data-test="addExtraWorksButton"
        onClick={() =>
          form.insertListItem("extraWorks", {
            type: null,
            name: "",
            category: "",
            description: "",
            price: 0,
          })
        }
      >
        Ajouter des travaux
      </Button>
    </Stack>
  )
}
