import { Stack, Textarea, SimpleGrid } from "@mantine/core"
import { IconHomeCheck, IconListCheck } from "@tabler/icons-react"

import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"
import { Nullable } from "@ensol/types/utils"

import {
  FollowUpStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Link } from "@ensol/entool/components/Link"
import { Section } from "@ensol/entool/components/Section"
import { ConstructorAppLink } from "@ensol/entool/components/entities/Installation/ConstructorAppLink"
import { InsightsBadges } from "@ensol/entool/components/entities/Installation/InsightsBadges"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { UpdateEquipments } from "@ensol/entool/components/form/House/UpdateEquipments"
import { ExternalPlantSelect } from "@ensol/entool/components/form/Installation/ExternalPlantSelect"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { getMonthlyReportUrl } from "@ensol/entool/utils/projects/reports"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const FollowUpForm = <Input extends Nullable<FollowUpStepInput>>({
  project,
  schema,
  initialValues,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })

  const { followUpProductionStartDate, installation } = project
  const { slug, photovoltaicInstallation } = installation
  const currentStep = project[props.processId]

  const inverter =
    photovoltaicInstallation !== null
      ? getInverter(photovoltaicInstallation.inverterType)
      : null

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {currentStep === FollowUpStep.INSTALLATION_TO_VALIDATE && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isPanelsLayoutValid"
                {...form.getInputProps("isPanelsLayoutValid")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="areClampsWorking"
                {...form.getInputProps("areClampsWorking")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="hasNoAlerts"
                {...form.getInputProps("hasNoAlerts")}
              />
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Suivi" icon={IconHomeCheck} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="followUpNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("followUpNotes")}
              />
            </FormField>
            <Field name="Conseil énergétique" noBorder>
              <InsightsBadges insights={project.installation.insights} />
            </Field>

            {inverter && (
              <FormField
                initialValues={initialValues}
                propertyName="followUpExternalPlantIdentifier"
                isRequired
              >
                <ExternalPlantSelect
                  w="400"
                  installationId={project.installationId}
                  externalProviderName={inverter.brand}
                  {...form.getInputProps("followUpExternalPlantIdentifier")}
                />
              </FormField>
            )}
            {project.followUpExternalPlantIdentifier &&
              photovoltaicInstallation && (
                <Field name="Application constructeur" noBorder>
                  <ConstructorAppLink
                    inverterType={photovoltaicInstallation.inverterType}
                    externalPlantIdentifier={
                      project.followUpExternalPlantIdentifier
                    }
                  />
                </Field>
              )}
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="followUpProductionStartDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                minDate={project.installationEndDate ?? undefined}
                {...form.getInputProps("followUpProductionStartDate")}
              />
            </FormField>

            <FormField
              initialValues={initialValues}
              propertyName="followUpMonthlyReportsPath"
            >
              <Link
                w={FIELD_WIDTH}
                label="Accéder au rapport"
                href={getMonthlyReportUrl({
                  installationSlug: slug,
                  productionStartDate: followUpProductionStartDate,
                  isSimulated: false,
                })}
                isExternal
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="shouldSendMonthlyReports"
                {...form.getInputProps("shouldSendMonthlyReports")}
              />
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName(
                    "rapport-mensuel",
                    project.installation.house.prospect,
                  ),
                }}
                inputProps={{
                  accept: ["application/pdf"],
                }}
                {...form.getInputProps("followUpMonthlyReportsPath")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {getStepIndex(currentStep, "followUpStep") >=
        getStepIndex(FollowUpStep.ONBOARDING_PENDING, "followUpStep") && (
        <UpdateEquipments house={project.installation.house} titleOrder={4} />
      )}
    </StepActions>
  )
}
