import * as z from "zod"
import { coordsSchema } from "@ensol/types/forms/houses"

/**
 * Panels Layout is:
 * - a list of panels grids, which are
 * - a list of panels, which are
 * - a list of coordinates (supposedly 4, to describe a rectangle)
 */
export const panelsLayoutSchema = z.array(z.array(z.array(coordsSchema)))

export const createPanelsLayoutSchema = z.object({
  paths: panelsLayoutSchema,
  screenshotBase64: z.string(),
  withOrthorectifiedMap: z.boolean(),
})
