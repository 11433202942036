import { useQuery } from "@tanstack/react-query"
import { AxiosInstance } from "axios"

import { GoogleResponses } from "@ensol/types/endpoints/google"
import { Location } from "@ensol/types/forms/houses"
import { Pick } from "@ensol/types/prisma-client/runtime/library"

export const useLocationData = (
  httpClient: AxiosInstance,
  coords?: Pick<Location, "lat" | "lng">,
) => {
  return useQuery({
    queryKey: ["google", "location", coords],
    queryFn: async () => {
      const response = await httpClient.post<GoogleResponses.Location>(
        "/google/location",
        { coords },
      )
      return response.data
    },
    enabled: !!coords,
  })
}
