import { ProcessId } from "@ensol/shared/entities/projects/processes"

import {
  ProcessView,
  ViewProps,
} from "@ensol/entool/components/entities/Project/ProcessView"
import { getFollowUpStepComponent } from "@ensol/entool/pages/OpsProject/processes/FollowUp"
import {
  PROJECTS_PROCESS_VIEWS,
  ProjectProcessView,
} from "@ensol/entool/utils/projects/views"

import { getConsuelStepComponent } from "./processes/Consuel"
import { getEdfOaStepComponent } from "./processes/EdfOa"
import { getEnedisStepComponent } from "./processes/Enedis"
import { getGreendealStepComponent } from "./processes/Greendeal"
import { getHardwareStepComponent } from "./processes/Hardware"
import { getInstallationStepComponent } from "./processes/Installation"
import { getPaymentStepComponent } from "./processes/Payment"
import { getPreliminaryRequestStepComponent } from "./processes/PreliminaryRequest"
import { getTechnicalVisitStepComponent } from "./processes/TechnicalVisit"

const PROCESS_COMPONENTS = {
  preliminaryRequestStep: (props: ViewProps) => {
    return (
      <ProcessView
        {...props}
        stepperHeight={240}
        getStepComponent={getPreliminaryRequestStepComponent}
      />
    )
  },
  technicalVisitStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={230}
      getStepComponent={getTechnicalVisitStepComponent}
    />
  ),
  greendealStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={150}
      getStepComponent={getGreendealStepComponent}
    />
  ),
  installationStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={180}
      getStepComponent={getInstallationStepComponent}
    />
  ),
  paymentStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={140}
      getStepComponent={getPaymentStepComponent}
    />
  ),
  hardwareStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={120}
      getStepComponent={getHardwareStepComponent}
    />
  ),
  enedisStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={180}
      getStepComponent={getEnedisStepComponent}
    />
  ),
  consuelStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={220}
      getStepComponent={getConsuelStepComponent}
    />
  ),
  edfOaStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={150}
      getStepComponent={getEdfOaStepComponent}
    />
  ),
  followUpStep: (props: ViewProps) => (
    <ProcessView
      {...props}
      stepperHeight={150}
      getStepComponent={getFollowUpStepComponent}
    />
  ),
}

export const PROJECT_PROCESS_DETAILS_VIEWS: (ProjectProcessView<ProcessId> & {
  Component: React.ComponentType<ViewProps>
})[] = PROJECTS_PROCESS_VIEWS.map((props) => ({
  ...props,
  Component: PROCESS_COMPONENTS[props.id],
}))
