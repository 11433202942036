type InstallationAlertInfo = {
  name: string
  shortName: string
}

export const InstallationAlertsType = {
  MANUFACTURER_CRITICAL: "MANUFACTURER_CRITICAL",
  MANUFACTURER_RECURRENT: "MANUFACTURER_RECURRENT",
  MANUFACTURER_CLAMPS: "MANUFACTURER_CLAMPS",
  LOW_PRODUCTION: "LOW_PRODUCTION",
  INTERNAL_CLAMPS: "INTERNAL_CLAMPS",
  MISSING_DATA: "MISSING_DATA",
} as const

export type InstallationAlertsType = keyof typeof InstallationAlertsType

export const INSTALLATION_ALERTS: Record<
  InstallationAlertsType,
  InstallationAlertInfo
> = {
  [InstallationAlertsType.MANUFACTURER_CRITICAL]: {
    name: "Alerte constructeur critique",
    shortName: "Critique",
  },
  [InstallationAlertsType.MANUFACTURER_RECURRENT]: {
    name: "Alerte communication récurrente",
    shortName: "Communication",
  },
  [InstallationAlertsType.MANUFACTURER_CLAMPS]: {
    name: "Problème de pinces (constructeur)",
    shortName: "Pinces (constructeur)",
  },
  [InstallationAlertsType.LOW_PRODUCTION]: {
    name: "Production faible",
    shortName: "Production faible",
  },
  [InstallationAlertsType.INTERNAL_CLAMPS]: {
    name: "Problème de pinces (interne)",
    shortName: "Pinces (interne)",
  },
  [InstallationAlertsType.MISSING_DATA]: {
    name: "Données manquantes",
    shortName: "Données manquantes",
  },
}

export const getInstallationAlert = (type: InstallationAlertsType) =>
  INSTALLATION_ALERTS[type]
