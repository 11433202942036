import { Group, Badge, MantineStyleProps } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"

type Props = {
  issues: string[]
} & MantineStyleProps

export const IssuesBadges = ({ issues, ...props }: Props) => (
  <Group gap="4" {...props}>
    {issues.length > 0 ? (
      issues.map((issue) => (
        <Badge key={issue} color="red" variant="outline">
          {issue}
        </Badge>
      ))
    ) : (
      <Badge
        color="green"
        variant="outline"
        leftSection={<IconCheck size="14" />}
      >
        ok
      </Badge>
    )}
  </Group>
)
