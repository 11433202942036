import { Stack, Badge, ScrollAreaAutosize, Flex } from "@mantine/core"

import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

import { COLUMN_WIDTH_IN_PX, COLUMN_PADDING_IN_PX } from "./config"

type ColumnWrapperProps = {
  children: React.ReactNode
}

export const ColumnWrapper = ({ children }: ColumnWrapperProps) => (
  <Flex
    gap="lg"
    flex="1"
    px={CONTENT_PADDING_IN_PX}
    pb="12"
    style={{ overflowX: "auto" }}
  >
    {children}
  </Flex>
)

type ColumnProps = {
  backgroundColor: string
  title: string
  titleColor: string
  count: number
  children: React.ReactNode
}

export const Column = ({
  backgroundColor,
  title,
  titleColor,
  count,
  children,
}: ColumnProps) => (
  <Stack
    w={COLUMN_WIDTH_IN_PX}
    p={`${COLUMN_PADDING_IN_PX}px 0`}
    bg={`${backgroundColor}.0`}
    style={{ borderRadius: 8, flexShrink: 0 }}
  >
    <Badge m="0 16px" color={titleColor} style={{ flexShrink: 0 }}>
      {title} ({count})
    </Badge>
    <ScrollAreaAutosize>
      <Stack gap="sm" p="0 16px">
        {children}
      </Stack>
    </ScrollAreaAutosize>
  </Stack>
)
