import { Flex, Loader, Stack } from "@mantine/core"
import { CSSProperties } from "react"

type Props = {
  mapId: string
  style: CSSProperties
  isLoading: boolean
}

export const MapWrapper = ({ mapId, style, isLoading }: Props) => (
  <Stack h="100%" pos="relative">
    {isLoading && (
      <Flex
        pos="absolute"
        direction="column"
        justify="center"
        align="center"
        style={style}
      >
        <Loader size="sm" />
      </Flex>
    )}
    <div
      id={mapId}
      style={{
        ...style,
        visibility: isLoading ? "hidden" : "visible",
      }}
    />
  </Stack>
)
