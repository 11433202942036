import { useQuery } from "@tanstack/react-query"
import { AxiosInstance } from "axios"
import { type ReadRasterResult } from "geotiff"

import { GoogleResponses } from "@ensol/types/endpoints/google"
import { Coords } from "@ensol/types/forms/houses"

import { parseGeoTiff } from "./geoTiff"

export interface DataLayers {
  imageryDate: Date
  annualFluxTiffRaster: ReadRasterResult
  monthlyFluxTiffRaster: ReadRasterResult
  baseImageTiffRGB: ReadRasterResult
}

export type FetchDataLayersConfig = {
  httpClient: AxiosInstance
  radiusMeters: number
}

export const useDataLayers = (
  googleApiKey: string,
  coords: Coords,
  config?: FetchDataLayersConfig,
) =>
  useQuery({
    queryKey: ["data-layers", coords, config?.radiusMeters],
    queryFn: async (): Promise<DataLayers> => {
      if (config === undefined) {
        throw new Error(
          "/google/data-layers should not be called without a config",
        )
      }

      const response = await config.httpClient.post<GoogleResponses.DataLayers>(
        "/google/data-layers",
        { coords, radiusMeters: config.radiusMeters },
      )

      const [monthlyFluxTiffRaster, annualFluxTiffRaster, baseImageTiffRGB] =
        await Promise.all([
          parseGeoTiff(response.data.monthlyFluxUrl, googleApiKey, "raster"),
          parseGeoTiff(response.data.annualFluxUrl, googleApiKey, "raster"),
          parseGeoTiff(response.data.baseImageUrl, googleApiKey, "rgb"),
        ])

      return {
        imageryDate: response.data.imageryDate,
        monthlyFluxTiffRaster,
        annualFluxTiffRaster,
        baseImageTiffRGB,
      }
    },
    enabled: config !== undefined,
    retry: false,
  })
