import { SimpleGrid } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconTools } from "@tabler/icons-react"

import { Section } from "@ensol/entool/components/Section"
import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { TECHNICAL_VISIT_REQUIRED_CHANGES_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  initialValues: { technicalVisitRequiredChanges: string[] }
}

export const RequiredChangesForm = <Values,>({
  form,
  initialValues,
}: Props<Values>) => (
  <Section
    title="Modifications"
    icon={IconTools}
    iconColor="yellow.5"
    titleOrder={4}
  >
    <SimpleGrid cols={2} spacing="32" mt="16">
      <FormField
        align="flex-start"
        initialValues={initialValues}
        propertyName="technicalVisitRequiredChanges"
      >
        <CheckboxGroup
          options={TECHNICAL_VISIT_REQUIRED_CHANGES_OPTIONS}
          {...form.getInputProps("technicalVisitRequiredChanges")}
        />
      </FormField>
    </SimpleGrid>
  </Section>
)
