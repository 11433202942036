export type Nullable<T> =
  T extends Record<string, unknown>
    ? {
        [K in keyof T]: T[K] extends Record<string, unknown>
          ? Nullable<T[K]>
          : T[K] | null
      }
    : never

export type UnpackArray<T> = T extends (infer U)[] ? U : never

export const typedKeys = <T extends Record<string, unknown>>(
  object: T,
): (keyof T)[] => Object.keys(object)

export const hasKey = <T extends Record<string, unknown>>(
  obj: T,
  key: string | number | symbol,
): key is keyof T => {
  return key in obj
}
