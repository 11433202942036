import { IconPencil } from "@tabler/icons-react"

import { completedStepFormSchema } from "@ensol/types/forms/projects/installation"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { InstallationForm } from "./components/InstallationForm"
import { INSTALLATION_PROPERTIES } from "./config"

export const Completed = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={completedStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(INSTALLATION_PROPERTIES, project),
      ...getInitialValues(completedStepFormSchema, project),
    }}
    project={project}
    actions={[
      {
        action: "completedWithChanges",
        label: "Mettre à jour l'installation",
        color: "orange",
        Icon: IconPencil,
      },
      {
        action: "validate",
        label: "Envoyer le bon de fin de chantier",
        confirmationMessage:
          "Cette action va envoyer un lien de signature du bon de fin chantier au client, êtes-vous sûr de vouloir continuer ?",
      },
    ]}
    emails={[
      {
        eventName: EmailEvent.INSTALLATION_DONE,
        label: "Envoyer mail installation terminée",
      },
    ]}
    {...props}
  />
)
