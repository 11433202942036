type EvChargerBrand = "Wallbox"

type EvChargerInfo = {
  name: string
  longName: string
  power: number
  brand: EvChargerBrand
  price: number
  warranty: number
  techDocUrl: string
}

export const EvChargerType = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: "WALLBOX_PULSAR_PLUS_SOCKET_MONO",
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: "WALLBOX_PULSAR_PLUS_SOCKET_TRI",
  WALLBOX_COPPER_SB_MONO: "WALLBOX_COPPER_SB_MONO",
  WALLBOX_COPPER_SB_TRI: "WALLBOX_COPPER_SB_TRI",
} as const

export type EvChargerType = keyof typeof EvChargerType

export const EV_CHARGERS: Record<EvChargerType, EvChargerInfo> = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: {
    name: "Wallbox Monophasée (sans pied)",
    longName: "Wallbox Pulsar Plus Socket 1,4 à 7,4kW 1P - IP55",
    power: 7.4,
    brand: "Wallbox",
    price: 609.59,
    warranty: 2,
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/2021/12/FR_Pulsar_Plus_Fiches_techniques_Francais.pdf",
  },
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: {
    name: "Wallbox Triphasé (sans pied)",
    longName: "Wallbox Pulsar Plus Socket 11 à 22 kW 1P - IP55",
    power: 22,
    brand: "Wallbox",
    price: 691.37,
    warranty: 2,
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/2021/12/FR_Pulsar_Plus_Fiches_techniques_Francais.pdf",
  },
  WALLBOX_COPPER_SB_MONO: {
    name: "Wallbox Monophasée (avec pied)",
    longName: "Wallbox borne Copper SB 1,4 à 7,4kW 1P - IP55",
    power: 7.4,
    brand: "Wallbox",
    price: 772.74,
    warranty: 2,
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/ht_kb/2021/05/Copper-SB_RevC_Fiche_technique_Francais.pdf",
  },
  WALLBOX_COPPER_SB_TRI: {
    name: "Wallbox Triphasé (avec pied)",
    longName: "Wallbox borne Copper SB 11 à 22 kW 1P - IP55",
    power: 22,
    brand: "Wallbox",
    price: 854.52,
    warranty: 2,
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/ht_kb/2021/05/Copper-SB_RevC_Fiche_technique_Francais.pdf",
  },
}

export const getEvCharger = (type: EvChargerType): EvChargerInfo =>
  EV_CHARGERS[type]

type SmartMeterInfo = {
  name: string
  price: number
}

const SMART_METER_MONO: SmartMeterInfo = {
  name: "Monophasé - pour délestage / charge solaire",
  price: 88.8,
}

const SMART_METER_TRI: SmartMeterInfo = {
  name: "Triphasé - pour délestage / charge solaire",
  price: 172.32,
}

export const getEvChargerSmartMeter = (
  evChargerType: EvChargerType,
): SmartMeterInfo =>
  evChargerType === EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO
    ? SMART_METER_MONO
    : SMART_METER_TRI
