import { Table, Text } from "@mantine/core"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { formatDate } from "@ensol/shared/utils/format"

import { FoldableBox } from "@ensol/entool/components/FoldableBox"
import { DownloadFileButton } from "@ensol/entool/components/entities/File/DownloadFileButton"
import { QuoteStatusBadge } from "@ensol/entool/components/entities/Installation/QuoteStatusBadge"

type Props = {
  previousQuotes: InstallationsResponses.Quotes["previousQuotes"]
  quoteFileName: string
}

export const PreviousQuotes = ({ previousQuotes, quoteFileName }: Props) => (
  <FoldableBox name="Historique des devis" isOpened={false}>
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Créé le</Table.Th>
          <Table.Th>Statut</Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {previousQuotes.map((quote) => (
          <Table.Tr key={quote.id} p="0">
            <Table.Td w="140">
              <Text size="sm">
                {formatDate(quote.createdAt, "DD/MM/YYYY à HH[h]mm")}
              </Text>
            </Table.Td>
            <Table.Td>
              <QuoteStatusBadge size="sm" status={quote?.status} />
            </Table.Td>
            <Table.Td w="40">
              {quote.quotePath !== null && (
                <DownloadFileButton
                  filePath={quote.quotePath}
                  fileName={quoteFileName}
                  type="pdf"
                />
              )}
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  </FoldableBox>
)
