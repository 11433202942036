import { Divider, Group, Stack, StackProps, Text } from "@mantine/core"
import { ReactNode } from "react"

type Props = StackProps & {
  name: ReactNode
  subtitle?: string
  helpText?: string
  noBorder?: boolean
  withAsterisk?: boolean
  children: ReactNode
}

export const Field = ({
  name,
  subtitle,
  helpText,
  children,
  noBorder = false,
  withAsterisk = false,
  ...props
}: Props) => {
  return (
    <Stack gap={8} {...props}>
      <Group gap={4}>
        {typeof name === "string" ? (
          <Text fw={500} c="gray.7">
            {name}
          </Text>
        ) : (
          name
        )}
        {withAsterisk && " *"}
      </Group>
      {subtitle && (
        <Text size="sm" c="gray.6">
          {subtitle}
        </Text>
      )}
      {helpText && (
        <Text size="sm" c="gray.6">
          {helpText}
        </Text>
      )}
      {children}
      {!noBorder && <Divider mt={12} mb={24} />}
    </Stack>
  )
}
