import { useMediaQuery } from "@mantine/hooks"
import { useParams } from "react-router-dom"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { MobileOverview } from "@ensol/entool/pages/OpsProject/MobileOverview"
import { useGetProjectQuery } from "@ensol/entool/queries/projects"
import { devices } from "@ensol/entool/styles/theme"

import { Project } from "./Project"

export const OpsProjectPage = () => {
  const { projectId } = useParams()
  const isMobile = useMediaQuery(devices.sm)

  const projectQuery = useGetProjectQuery(projectId!)

  return (
    <QueryWrapper query={projectQuery}>
      {({ data }) =>
        isMobile ? (
          <MobileOverview project={data} />
        ) : (
          <Project project={data} />
        )
      }
    </QueryWrapper>
  )
}
