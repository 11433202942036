import { Button, Collapse, Stack, StackProps } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  name: string
  isOpened: boolean
  children: ReactNode
} & StackProps

export const FoldableBox = ({ name, isOpened, children, ...props }: Props) => {
  const [opened, { toggle }] = useDisclosure(isOpened)

  return (
    <Stack mt="8" align="flex-start" gap="4" {...props}>
      <Button
        variant="transparent"
        color="black"
        p="0"
        size="sm"
        onClick={toggle}
      >
        {name}{" "}
        {opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
      </Button>
      <Collapse in={opened} w="100%">
        {children}
      </Collapse>
    </Stack>
  )
}
