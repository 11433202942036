import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { PanelsQuoteDetails } from "@ensol/types/quote"

import {
  computeAllExtraPanelsCosts,
  computeBuildKitUnitPrice,
  computeExtraPanelsWorkforceCost,
  computeExtraPanelUnitPrice,
  computeInverterUnitPrice,
  EXTRA_PANELS_VAT_RATE,
} from "@ensol/shared/entities/installations/costs/panels"
import { computeInvertersCount } from "@ensol/shared/entities/installations/energy"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

type PanelsQuoteDetailsInput = {
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation
  house: InstallationsResponses.Installation["house"]
}

export const computePanelsQuoteDetails = ({
  extraPanelsInstallation,
  house,
}: PanelsQuoteDetailsInput): PanelsQuoteDetails => {
  const { panelType, panelsCount, inverterType } = extraPanelsInstallation

  const {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
  } = computeAllExtraPanelsCosts({
    extraPanelsInstallation,
    house,
  })

  const panel = getPanel(panelType)
  const inverter = getInverter(inverterType)

  return {
    material: {
      panels: {
        name: "Panneaux Solaires",
        description: `${panel.reference}\nGarantie ${panel.warranty} ans`,
        quantity: panelsCount,
        price: computeExtraPanelUnitPrice(panelType),
      },
      // Only if micro-inverters are already installed. Otherwise we keep the current central inverter
      ...(!inverter.isCentralInverter
        ? {
            inverters: {
              name: "Micro-onduleurs",
              description: `${inverter.name}\nGarantie ${inverter.warranty} ans`,
              quantity: computeInvertersCount(inverterType, panelsCount),
              price: computeInverterUnitPrice(inverterType),
            },
          }
        : {}),
      buildKit: {
        name: "Système de montage",
        description: house.hasFlatRoof
          ? "Kit d’installation en toit terrasse, pose sur cadres aciers lestés"
          : "Kit d'implantation en toiture, pose en surimposition",
        quantity: 1,
        price: panelsCount * computeBuildKitUnitPrice(house.hasFlatRoof),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(
      extraPanelsInstallation.extraWorks,
    ),
    workforce: {
      installation: {
        name: "Installation Panneaux",
        description:
          "Forfait pose et mise en service de panneaux supplémentaires",
        quantity: 1,
        price: computeExtraPanelsWorkforceCost(panelsCount),
      },
    },
    support: {
      counseling: {
        name: "Suivi et conseils personnalisés",
        description:
          "Surveillance du système de production et rapports détaillés d’autoconsommation",
        quantity: 1,
        price: 0,
      },
      repair: {
        name: "Maintenance",
        description:
          "Intervention gratuite de dépannage en cas de défaillance d’un des modules pour le remplacer, sur la durée des garanties constructeurs",
        quantity: 1,
        price: 0,
      },
    },
    vatRate: EXTRA_PANELS_VAT_RATE,
    totals: {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
    },
  }
}
