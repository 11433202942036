import { activationPendingStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/OpsProject/processes/Enedis/components/EnedisForm"
import { ENEDIS_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Enedis/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

export const Activated = ({ project, processId }: StepComponentProps) => {
  const { photovoltaicInstallation } = project.installation

  return (
    <EnedisForm
      schema={activationPendingStepFormSchema}
      initialValues={{
        ...getInitialValues(activationPendingStepFormSchema, project),
        ...pickDefinedProperties(ENEDIS_PROPERTIES, project),
      }}
      project={project}
      processId={processId}
      emails={
        photovoltaicInstallation?.subsidyType !==
        PhotovoltaicSubsidyType.GREEN_DEAL
          ? [
              {
                eventName: EmailEvent.ENEDIS_ACTIVATED,
                label: "Mise en service effectuée",
              },
            ]
          : []
      }
      actions={[]}
    />
  )
}
