import { typedKeys } from "@ensol/types/utils"

import { EXTRA_WORKS, ExtraWorkType } from "@ensol/shared/material/extraWorks"

import { Option } from "@ensol/entool/utils/form/radio"

export const EXTRA_WORKS_OPTIONS: Option<ExtraWorkType>[] = typedKeys(
  EXTRA_WORKS,
).map((workType) => {
  const { name } = EXTRA_WORKS[workType]

  return {
    label: name,
    value: workType,
  }
})
