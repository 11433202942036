import { useQuery } from "@tanstack/react-query"

import { IntercomResponses } from "@ensol/types/endpoints/intercom"

import { httpClient } from "@ensol/entool/backend/axios"

export const useProspectEventsQuery = (prospectId: string) => {
  return useQuery<IntercomResponses.ProspectEvents>({
    queryKey: ["intercom", prospectId, "events"],
    queryFn: async () => {
      const response = await httpClient.get(`/intercom/${prospectId}/events`)
      return response.data
    },
    retry: false,
  })
}
