import { Rating, SimpleGrid, Stack, Textarea } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { feedbackFormSchema } from "@ensol/types/forms/projects/feedbacks"

import { Checkbox } from "@ensol/entool/components/form/Checkbox"
import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateProjectFeedbackMutation } from "@ensol/entool/queries/projects"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import {
  REVIEW_PLATFORM_OPTIONS,
  NPS_RATING_OPTIONS,
} from "@ensol/entool/utils/projects/options"

type Props = {
  projectId: string
  feedback: ProjectResponses.Feedback | null
}

export const FeedbackForm = ({ projectId, feedback }: Props) => {
  const { mutateAsync: updateFeedback } =
    useUpdateProjectFeedbackMutation(projectId)
  const debouncedUpdateFeedback = useDebounce(updateFeedback, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(feedbackFormSchema),
    onValuesChange: async (values) => {
      debouncedUpdateFeedback(values)

      if (values.npsRating === 10 && !values.goodExperienceReasons) {
        form.setFieldError("badExperienceReasons", "")
        form.setFieldError("goodExperienceReasons", "Champ obligatoire")
      } else if (!values.badExperienceReasons) {
        form.setFieldError("badExperienceReasons", "Champ obligatoire")
        form.setFieldError("goodExperienceReasons", "")
      }
    },
    initialValues: {
      npsRating: feedback?.npsRating,
      comment: feedback?.comment,
      goodExperienceReasons: feedback?.goodExperienceReasons,
      badExperienceReasons: feedback?.badExperienceReasons,
      reviewRating: feedback?.reviewRating,
      reviewPlatform: feedback?.reviewPlatform,
      willNotPostReview: feedback?.willNotPostReview ?? false,
    },
  })

  return (
    <SimpleGrid
      cols={{ base: 1, md: 2 }}
      spacing={{ base: 16, md: 62 }}
      flex="1"
      h="fit-content"
      pt="12"
      pb="24"
    >
      <Stack gap="24">
        <Field name="Score NPS" noBorder withAsterisk>
          <RadioGroup
            options={NPS_RATING_OPTIONS}
            {...form.getInputProps("npsRating")}
          />
        </Field>
        {(!form.values.npsRating || form.values.npsRating < 10) && (
          <Field
            name="Quelles raisons nous ont empêchées d'avoir 10 ?"
            noBorder
            withAsterisk
          >
            <Textarea
              autosize
              w={FIELD_WIDTH}
              minRows={4}
              {...form.getInputProps("badExperienceReasons")}
            />
          </Field>
        )}
        <Field
          name="Qu'est-ce qui en particulier a contribué à la top expérience ?"
          noBorder
          withAsterisk={
            form.values.npsRating && form.values.npsRating === 10 ? true : false
          }
        >
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={4}
            {...form.getInputProps("goodExperienceReasons")}
          />
        </Field>
        <Field name="Commentaire" noBorder>
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={4}
            {...form.getInputProps("comment")}
          />
        </Field>
      </Stack>
      <Stack gap="24">
        <Field name="Note avis" noBorder>
          <Rating
            size="lg"
            readOnly={form.values.willNotPostReview}
            {...form.getInputProps("reviewRating")}
          />
          <Checkbox
            checked={form.values.willNotPostReview}
            label="Ne peut pas laisser d'avis"
            onChange={(event) => {
              if (event.target.checked === true) {
                form.getInputProps("reviewRating").onChange(null)
              }
              form
                .getInputProps("willNotPostReview")
                .onChange(event.target.checked)
            }}
          />
        </Field>
        <Field name="Plateforme de l'avis" noBorder>
          <RadioGroup
            options={REVIEW_PLATFORM_OPTIONS}
            {...form.getInputProps("reviewPlatform")}
          />
        </Field>
      </Stack>
    </SimpleGrid>
  )
}
