import { Badge } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"

type Props = {
  isChecked: boolean | null
}

export const CheckBadge = ({ isChecked }: Props) => {
  return (
    <Badge
      color={isChecked ? "green" : "red"}
      variant="outline"
      leftSection={isChecked && <IconCheck size="14" />}
    >
      {isChecked ? "Oui" : "Non"}
    </Badge>
  )
}
