import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { PhotovoltaicQuoteDetails } from "@ensol/types/quote"

import {
  computeBuildKitUnitPrice,
  computeInverterUnitPrice,
} from "@ensol/shared/entities/installations/costs/panels"
import {
  computeOptimizerUnitPrice,
  computePhotovoltaicSmartMeterPrice,
  computeElectricalSafeGuardUnitPrice,
  computeAllPhotovoltaicCosts,
  PHOTOVOLTAIC_ADMIN_FEE_COST,
  computePanelUnitPrice,
  computePhotovoltaicWorkforceCost,
  computePhotovoltaicVAT,
} from "@ensol/shared/entities/installations/costs/photovoltaic"
import {
  computeInvertersCount,
  computePanelsCapacity,
} from "@ensol/shared/entities/installations/energy"
import { computeEnergyResellPrice } from "@ensol/shared/entities/installations/savings"
import { getSubsidy } from "@ensol/shared/entities/installations/subsidies"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getCompatibleElectricalSafeGuard } from "@ensol/shared/material/photovoltaic/electricalSafeGuards"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getOptimizer } from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"
import { getPhotovoltaicSmartMeter } from "@ensol/shared/material/photovoltaic/smartMeters"

type PhotovoltaicQuoteDetailsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: InstallationsResponses.Installation["house"]
}

export const computePhotovoltaicQuoteDetails = ({
  photovoltaicInstallation,
  house,
}: PhotovoltaicQuoteDetailsInput): PhotovoltaicQuoteDetails => {
  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    optimizerCount,
    subsidyType,
  } = photovoltaicInstallation

  const {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
    subsidy,
  } = computeAllPhotovoltaicCosts({
    photovoltaicInstallation,
    house,
    totalDiscount: 0,
  })

  const panel = getPanel(panelType)
  const inverter = getInverter(inverterType)
  const optimizer = getOptimizer(optimizerType)
  const smartMeter = getPhotovoltaicSmartMeter(inverterType)
  const electricalSafeGuard = getCompatibleElectricalSafeGuard(
    panelsCount,
    inverterType,
    house.currentType,
  )
  const subsidyInfo = subsidy !== null ? getSubsidy(subsidy.subsidyType) : null

  return {
    material: {
      panels: {
        name: "Panneaux Solaires",
        description: `${panel.reference}\nGarantie ${panel.warranty} ans`,
        quantity: panelsCount,
        price: computePanelUnitPrice(panelsCount, panelType),
      },
      inverters: {
        name: inverter.isCentralInverter ? "Onduleur" : "Micro-onduleurs",
        description: `${inverter.name}\nGarantie ${inverter.warranty} ans`,
        quantity: computeInvertersCount(inverterType, panelsCount),
        price: computeInverterUnitPrice(inverterType),
        isCentralInverter: inverter.isCentralInverter,
      },
      ...(optimizer && optimizerCount
        ? {
            optimizers: {
              name: "Optimiseurs",
              description: `${optimizer.name}\nGarantie ${optimizer.warranty} ans`,
              quantity: optimizerCount,
              price: computeOptimizerUnitPrice(optimizerType),
            },
          }
        : {}),
      smartMeter: {
        name: "Passerelle de communication",
        description: `${smartMeter.name}\rGarantie ${smartMeter.warranty} ans`,
        quantity: 1,
        price: computePhotovoltaicSmartMeterPrice(inverterType),
      },
      buildKit: {
        name: "Système de montage",
        description: house.hasFlatRoof
          ? "Kit d’installation en toit terrasse, pose sur cadres aciers lestés"
          : "Kit d'implantation en toiture, pose en surimposition",
        quantity: 1,
        price: panelsCount * computeBuildKitUnitPrice(house.hasFlatRoof),
      },
      electricalSafeGuard: {
        name: "Protection électrique",
        description: electricalSafeGuard.description,
        quantity: 1,
        price: computeElectricalSafeGuardUnitPrice(
          panelsCount,
          inverterType,
          house.currentType,
        ),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(
      photovoltaicInstallation.extraWorks,
    ),
    workforce: {
      installation: {
        name: "Installation PV",
        description:
          "Forfait pose et mise en service de l'installation photovoltaïque",
        quantity: 1,
        price: computePhotovoltaicWorkforceCost({
          photovoltaicInstallation,
          house,
        }),
      },
      adminFees: {
        name: "Forfait administratif PV",
        description:
          "Déclaration d’autorisation d’urbanisme\n" +
          "Contrat de Raccordement d’Accès au réseau ENEDIS\n" +
          "Attestation de conformité Consuel\n" +
          `${subsidyInfo ? subsidyInfo.adminFeeDescription : ""}`,
        quantity: 1,
        price: PHOTOVOLTAIC_ADMIN_FEE_COST,
      },
    },
    support: {
      counseling: {
        name: "Suivi et conseils personnalisés",
        description:
          "Surveillance du système de production et rapports détaillés d’autoconsommation",
        quantity: 1,
        price: 0,
      },
      repair: {
        name: "Maintenance",
        description:
          "Intervention gratuite de dépannage en cas de défaillance d’un des modules pour le remplacer, sur la durée des garanties constructeurs",
        quantity: 1,
        price: 0,
      },
    },
    subsidy: subsidy
      ? {
          amount: subsidy.subsidyAmount,
          type: subsidy.subsidyType,
        }
      : null,
    vatRate: computePhotovoltaicVAT({
      photovoltaicInstallation,
      house,
    }),
    electricitySellPrice: computeEnergyResellPrice({
      subsidyType,
      installedCapacity: computePanelsCapacity(photovoltaicInstallation),
      installationAge: 0,
    }),
    totals: {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
    },
  }
}
