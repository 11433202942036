import { Group, MantineColor, Text } from "@mantine/core"

type Props = {
  label: string
  value: string
  color?: MantineColor
}
export const CardRow = ({ label, value, color }: Props) => (
  <Group align="baseline" justify="space-between" w="100%" wrap="nowrap">
    <Text size="sm" fw="bold" c={color}>
      {value}
    </Text>
    <Text size="xs" c="gray.6">
      {label}
    </Text>
  </Group>
)
