// This method returns the list of house section panels with their associated panels count if some, 0 otherwise.

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

// They can differ from installation.roofSectionsWithPanels if some roof sections are empty, have been deleted or added.
export const computeHouseRoofSectionsWithPanels = (
  roofSections: HousesResponses.House<{
    include: { roofSections: true }
  }>["roofSections"],
  roofSectionsWithPanels: InstallationsResponses.PhotovoltaicInstallation["roofSectionsWithPanels"],
) => {
  return roofSections.map((roofSection) => {
    const relatedRoofSectionWithPanel = roofSectionsWithPanels.find(
      (roofSectionWithPanels) =>
        roofSection.id === roofSectionWithPanels.roofSection.id,
    )
    if (relatedRoofSectionWithPanel !== undefined) {
      return relatedRoofSectionWithPanel
    } else {
      return {
        roofSection,
        panelsCount: 0,
      }
    }
  })
}
