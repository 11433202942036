import { useQuery } from "@tanstack/react-query"

import { SupplierResponses } from "@ensol/types/endpoints/suppliers"

import { httpClient } from "@ensol/entool/backend/axios"
import { Option } from "@ensol/entool/utils/form/radio"

export const useSuppliersOptions = () => {
  const { data } = useQuery<Option<string>[]>({
    queryKey: ["suppliers"],
    queryFn: async () => {
      const response =
        await httpClient.get<SupplierResponses.SuppliersList>("/suppliers")
      return response.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    },
  })

  return data || []
}
