import { useUser } from "@clerk/clerk-react"
import {
  Burger,
  Drawer,
  Avatar,
  Group,
  Text,
  Image,
  ScrollArea,
  AppShell,
  ActionIcon,
} from "@mantine/core"
import { useMediaQuery, useDisclosure } from "@mantine/hooks"
import { IconChevronLeft } from "@tabler/icons-react"
import { useHover } from "@uidotdev/usehooks"
import { Link } from "react-router-dom"

import entoolLogo from "@ensol/entool/assets/entoolLogo.svg"
import { devices } from "@ensol/entool/styles/theme"

import { Menu } from "./Menu"

type Props = {
  toggleDesktop: () => void
  desktopOpened: boolean
}

export const Navbar = ({ desktopOpened, toggleDesktop }: Props) => {
  const isSmallScreen = useMediaQuery(devices.md)
  const [isNavbarOpen, { open: openNavBar, close: closeNavbar }] =
    useDisclosure(false)
  const { isLoaded, isSignedIn, user } = useUser()

  const backgroundColor =
    import.meta.env.VITE_ENVIRONMENT !== "production" ? "orange.0" : "white"

  const [ref, hovered] = useHover()

  if (isSmallScreen) {
    return (
      <AppShell.Header>
        <Group h="100%" px="lg" justify="space-between" bg={backgroundColor}>
          <Link to="/">
            <Image w="40" src={entoolLogo} alt="Logo Entool" />
          </Link>
          <Group h="100%" gap="0">
            <>
              <Burger mr="20" opened={isNavbarOpen} onClick={openNavBar} />
              <Drawer
                opened={isNavbarOpen}
                onClose={closeNavbar}
                size="230"
                position="right"
                styles={{ body: { padding: "0 0 8px" } }}
              >
                <Menu closeNavbar={closeNavbar} />
              </Drawer>
            </>
            {isLoaded && isSignedIn && <Avatar src={user.imageUrl} />}
          </Group>
        </Group>
      </AppShell.Header>
    )
  }
  return (
    <AppShell.Navbar
      style={{ transition: "width 300ms" }}
      ref={ref}
      bg={backgroundColor}
    >
      <ActionIcon
        pos="absolute"
        variant="default"
        aria-label="Collapse"
        h={0}
        w={0}
        size={28}
        radius={50}
        right={-14}
        top={46}
        onClick={toggleDesktop}
        style={{
          zIndex: 1,
          opacity: hovered ? 1 : 0,
          display: hovered ? "block" : "hidden",
          transition: "opacity 600ms",
        }}
      >
        <IconChevronLeft
          style={{
            transform: desktopOpened ? "rotate(0)" : "rotate(-0.50turn)",
            transition: "transform 300ms",
          }}
        />
      </ActionIcon>
      <ScrollArea pt="lg" pb={8} px={8} scrollbars="y">
        <Link to="/">
          <Image w="40" mb={12} ml={12} src={entoolLogo} alt="Logo Entool" />
        </Link>
        <Menu desktopOpened={desktopOpened} toggleDesktop={toggleDesktop} />
      </ScrollArea>
      {isLoaded && isSignedIn && (
        <Group mt="auto" pl={20} pb={24} pr={8} wrap="nowrap">
          <Avatar src={user.imageUrl} />
          <Text
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {user.firstName}{" "}
            {user.lastName ? `${user.lastName.at(0)?.toUpperCase()}.` : ""}
          </Text>
        </Group>
      )}
    </AppShell.Navbar>
  )
}
