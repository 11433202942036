import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

export const useBundleInstallationMutation = (
  prospectId: string,
  id: string,
) => {
  return useMutation<void, unknown, boolean>({
    mutationFn: async (bundled) => {
      const response = await httpClient.put(`/installations/${id}/bundle`, {
        bundled,
      })
      return response.data
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["prospects", prospectId],
      })
      notifications.show({ message: "Bundle mis à jour !" })
    },
  })
}
