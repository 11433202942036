import {
  Modal,
  ScrollArea,
  Group,
  Title,
  Button,
  ButtonProps,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { IconHome, IconHomeEdit } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { HouseUpdateType } from "@ensol/types/forms/houses"

import { HouseEquipment } from "@ensol/shared/entities/houses/equipments"
import { sortRoofSections } from "@ensol/shared/entities/houses/roofSection"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { HouseForm } from "@ensol/entool/components/form/House/HouseForm"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"

type Props = {
  house: ProspectResponses.HouseWithRoofSections
  onSuccess: (house: ProspectResponses.HouseWithRoofSections) => void
  buttonProps: ButtonProps
}

export const EditHouseButton = ({ house, onSuccess, buttonProps }: Props) => {
  const { mutateAsync: updateHouse, isPending: isUpdatingHouse } =
    useUpdateHouseMutation(house.id, async (house) => {
      onSuccess(house)
      notifications.show({ message: "Maison mise à jour !" })
      await queryClient.invalidateQueries({ queryKey: ["houses", house.id] })
      close()
    })
  const [isModalOpen, { open, close }] = useDisclosure(false)

  return (
    <>
      <Button onClick={open} leftSection={<IconHomeEdit />} {...buttonProps}>
        Éditer la maison
      </Button>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={close}
        title={
          <Group gap={8}>
            <IconHome size={26} />
            <Title order={2}>Maison</Title>
          </Group>
        }
      >
        <HouseForm
          initialValues={{
            updateType: HouseUpdateType.GENERAL,
            lat: house.lat,
            lng: house.lng,
            placeId: house.placeId,
            address: house.address,
            streetAddress: house.streetAddress,
            city: house.city,
            postcode: house.postcode,
            country: house.country,
            region: house.region,
            hasFlatRoof: house.hasFlatRoof,
            hasGroundInstallation: house.hasGroundInstallation,
            roofSections: sortRoofSections(house.roofSections),
            constructionYear: house.constructionYear,
            surface: house.surface,
            floors: house.floors,
            isIndividualHouse: house.isIndividualHouse,
            hasAsbestos: house.hasAsbestos,
            isHistoricBuilding: house.isHistoricBuilding,
            isAtticAccessible: house.isAtticAccessible,
            roofFramingMaterial: house.roofFramingMaterial,
            currentType: house.currentType,
            hasLinky: house.hasLinky,
            roofType: house.roofType,
            equipments: house.equipments as HouseEquipment[],
          }}
          onSave={updateHouse}
          isLoading={isUpdatingHouse}
        />
      </Modal>
    </>
  )
}
