import { MantineThemeOverride, MantineThemeColorsOverride } from "@mantine/core"
import _ from "lodash"

import { colors } from "@ensol/shared/styles/colors"

import "./global.css"
import "./fullcalendar-override.css"

const convertColorsIntoMantineThemeOverride = (colors: {
  [name: string]: { [shade: number]: string }
}): MantineThemeColorsOverride =>
  _.mapValues(colors, (shades) => Object.values(shades))

const breakpoints = {
  xs: "480px",
  sm: "768px",
  md: "1024px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1600px",
}
type Breakpoint = keyof typeof breakpoints

export const devices = Object.entries(breakpoints).reduce(
  (devices, [device, breakpoint]) => ({
    ...devices,
    [device]: `(max-width: ${breakpoint})`,
  }),
  {} as Record<Breakpoint, string>,
)

export const theme: MantineThemeOverride = {
  colors: convertColorsIntoMantineThemeOverride(colors),
  breakpoints,
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "20px",
  },
  headings: {
    fontWeight: "500",
    sizes: {
      h1: { fontSize: "28px" },
      h2: { fontSize: "24px" },
      h3: { fontSize: "20px" },
      h4: { fontSize: "16px" },
      h5: { fontSize: "14px" },
      h6: { fontSize: "12px" },
    },
  },
  primaryShade: 4,
  primaryColor: "blue",
  components: {
    ActionIcon: {
      defaultProps: {
        variant: "subtle",
        size: "sm",
      },
    },
    ThemeIcon: {
      defaultProps: {
        variant: "subtle",
        size: "sm",
      },
    },
    Notification: {
      styles: {
        description: { whiteSpace: "break-spaces" },
      },
    },
  },
}
