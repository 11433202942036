import { Group, MantineStyleProps, Text } from "@mantine/core"
import { ReactNode } from "react"

type Props = {
  label: string
  children: ReactNode
}

export const LabelledInsert = ({
  label,
  children,
  ...props
}: Props & MantineStyleProps) => (
  <Group
    gap="8"
    p="12px 8px 8px 8px"
    miw="140"
    pos="relative"
    style={({ colors }) => ({
      borderRadius: 8,
      border: `1px solid ${colors.gray[4]}`,
    })}
    {...props}
  >
    <Text
      pos="absolute"
      p="0 4"
      top="-8px"
      size="xs"
      bg="inherit"
      fw="500"
      c="gray.6"
    >
      {label}
    </Text>
    {children}
  </Group>
)
