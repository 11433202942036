export const colors = {
  blue: {
    100: "#F5F7FF",
    200: "#E0E2FF",
    300: "#C2C6FF",
    400: "#8E98FF",
    500: "#6D7BF2",
    600: "#495FD1",
    700: "#1C45B2",
    800: "#002C93",
    900: "#001675",
    1000: "#000059",
  },
  denim: {
    100: "#E3E3F5",
    200: "#C1C2E1",
    300: "#7277C0",
    400: "#5F65A0",
    500: "#394279",
    600: "#2C376C",
    700: "#1E2C60",
    800: "#0F2153",
    900: "#001747",
    1000: "#000B3C",
  },
  purple: {
    100: "#FAF3FF",
    200: "#F2E0FF",
    300: "#E2BDFF",
    400: "#CF92F2",
    500: "#AD64D9",
    600: "#8A43B5",
    700: "#672293",
    800: "#450072",
    900: "#230052",
    1000: "#180036",
  },
  pink: {
    100: "#FFF3FF",
    200: "#FFD8F9",
    300: "#FFBEDE",
    400: "#FFA4C4",
    500: "#F98AAB",
    600: "#C45B7D",
    700: "#922C51",
    800: "#60002A",
    900: "#360000",
    1000: "#230000",
  },
  gray: {
    100: "#F8F9FA",
    200: "#F1F3F5",
    300: "#E9ECEF",
    400: "#DEE2E6",
    500: "#CED4DA",
    600: "#ADB5BD",
    700: "#868E96",
    800: "#495057",
    900: "#343A40",
    1000: "#212529",
  },
  red: {
    100: "#FFE1DB",
    200: "#FFCABD",
    300: "#FF997A",
    400: "#FF7158",
    500: "#F24937",
    600: "#C51817",
    700: "#990000",
    800: "#700000",
    900: "#4D0000",
    1000: "#400000",
  },
  green: {
    100: "#F3FCE8",
    200: "#DAF9D2",
    300: "#A5EE9C",
    400: "#71E569",
    500: "#3DC13D",
    600: "#007A00",
    700: "#003900",
    800: "#000B00",
    900: "#000000",
    1000: "#000000",
  },
  yellow: {
    100: "#FFF6DB",
    200: "#FEECBC",
    300: "#FFCF3D",
    400: "#DEB432",
    500: "#CEA730",
    600: "#AF8D27",
    700: "#8F731C",
    800: "#655011",
    900: "#3D2F02",
    1000: "#241A00",
  },
}

export type ColorGroup = keyof typeof colors

export type ColorShade = `${ColorGroup}.${number}`
