import { visitScheduledStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Consuel/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { ConsuelForm } from "./components/ConsuelForm"

export const QualiPvVisitScheduled = ({
  project,
  processId,
}: StepComponentProps) => (
  <ConsuelForm
    schema={visitScheduledStepFormSchema}
    initialValues={{
      ...getInitialValues(visitScheduledStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    emails={[
      {
        eventName: EmailEvent.CONSUEL_SECOND_NOTICE_OF_VISIT,
        label: "Deuxième avis de visite",
      },
    ]}
    actions={[
      {
        action: "validate",
        label: "Date envoyée",
      },
    ]}
  />
)
