import { Button } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { Link } from "react-router-dom"

type Props = {
  to: string
  children: React.ReactNode
}

export const BackButton = ({ to, children }: Props) => (
  <Button
    p="0"
    h="12"
    size="sm"
    component={Link}
    to={to}
    variant="transparent"
    color="gray.9"
    leftSection={<IconArrowLeft size={16} />}
    display="flex"
  >
    {children}
  </Button>
)
