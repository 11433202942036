import {
  fromArrayBuffer,
  type Dimensions,
  type ReadRasterResult,
  type TypedArray,
} from "geotiff"

export const createImageFromTiffRGB = (tiffData: TypedArray & Dimensions) => {
  const canvas = document.createElement("canvas")

  const { width, height } = tiffData

  canvas.width = width
  canvas.height = height

  const context = canvas.getContext("2d") as CanvasRenderingContext2D
  const imageData = context.getImageData(0, 0, width, height)
  const data = imageData.data

  for (let row = 0; row < height; row++) {
    for (let column = 0; column < width; column++) {
      const sourceIndex = 3 * row * width + 3 * column
      const targetIndex = 4 * row * width + 4 * column
      data[targetIndex] = tiffData[sourceIndex]
      data[targetIndex + 1] = tiffData[sourceIndex + 1]
      data[targetIndex + 2] = tiffData[sourceIndex + 2]
      data[targetIndex + 3] = 255 // this represents opacity 100%
    }
  }
  context.putImageData(imageData, 0, 0)

  return canvas.toDataURL("image/png")
}

export const createHeatmapLayerFromTiffRaster = (
  tiffData: ReadRasterResult,
  layerIndex = 0,
) => {
  const canvas = document.createElement("canvas")

  canvas.width = tiffData.width
  canvas.height = tiffData.height

  const scale = 1

  const context = canvas.getContext("2d") as CanvasRenderingContext2D

  // @ts-expect-error TypedArray vs TypedArrayArray from geotiff makes this a bit tricky
  const maximumKwhPerKwPerYear = tiffData[layerIndex].reduce(
    (unit: number, currentUnit: number) =>
      unit > currentUnit ? unit : currentUnit,
    0,
  )

  for (let row = 0; row < tiffData.height; row += Math.round(1 / scale))
    for (
      let column = 0;
      column < tiffData.width;
      column += Math.round(1 / scale)
    ) {
      const index = row * tiffData.width + column

      // @ts-expect-error TypedArray vs TypedArrayArray from geotiff makes this a bit tricky
      const value = tiffData[layerIndex][index]

      if (value === -9999) continue

      const heatmapStyleHue = (1.0 - value / maximumKwhPerKwPerYear) * 240

      context.fillStyle = `hsl(${heatmapStyleHue}, 100%, 50%)`

      context.fillRect(column * scale, row * scale, 1, 1)
    }

  return canvas.toDataURL("image/png")
}

export const parseGeoTiff = async (
  fluxUrl: string,
  googleApiKey: string,
  format: "raster" | "rgb",
) => {
  const geoTiffResponse = await fetch(`${fluxUrl}&key=${googleApiKey}`)
  const tiffImageBuffer = await geoTiffResponse.arrayBuffer()
  const tiff = await fromArrayBuffer(tiffImageBuffer)

  const image = await tiff.getImage()

  switch (format) {
    case "raster":
      return image.readRasters()
    case "rgb":
      return image.readRGB()
    default:
      return image.readRasters()
  }
}
