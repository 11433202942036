import _ from "lodash"

import { typedKeys } from "@ensol/types/utils"

import { AbortReason } from "@ensol/shared/entities/projects/abort"

export enum SchedulingIssuesMainReason {
  PRELIMINARY_REQUEST = "PRELIMINARY_REQUEST",
  WORKS = "WORKS",
  SALES = "SALES",
  FINANCING = "FINANCING",
  PROJECT_CHANGE = "PROJECT_CHANGE",
  IN_PROGRESS = "IN_PROGRESS",
}

enum InProgressSchedulingIssues {
  HOLIDAYS = "Vacances",
  NO_ANSWER = "Ne répond pas",
  WAITING_FOR_TECHNICAL_REVIEW_REPORT = "En attente CR VT",
}

export const SCHEDULING_ISSUES_MAIN_REASONS_LABEL: Record<
  SchedulingIssuesMainReason,
  string
> = {
  [SchedulingIssuesMainReason.PRELIMINARY_REQUEST]: "DP",
  [SchedulingIssuesMainReason.WORKS]: "Travaux",
  [SchedulingIssuesMainReason.SALES]: "Sales",
  [SchedulingIssuesMainReason.FINANCING]: "Financement",
  [SchedulingIssuesMainReason.PROJECT_CHANGE]: "Changement de projet",
  [SchedulingIssuesMainReason.IN_PROGRESS]: "En cours",
}

export const SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN: Record<
  SchedulingIssuesMainReason,
  Array<AbortReason | InProgressSchedulingIssues>
> = {
  [SchedulingIssuesMainReason.PRELIMINARY_REQUEST]: [
    AbortReason.REGULARIZATION,
    AbortReason.ABF,
    AbortReason.CLASSIFIED_AREA_NO_PANELS,
  ],
  [SchedulingIssuesMainReason.WORKS]: [
    AbortReason.PC,
    AbortReason.CARPORT,
    AbortReason.EXTERNAL_CABLE_PASSAGE,
    AbortReason.LINKY,
    AbortReason.ELECTRICAL,
    AbortReason.ROOF,
  ],
  [SchedulingIssuesMainReason.SALES]: [
    AbortReason.COMPETITION,
    AbortReason.PERSONAL_REASONS,
    AbortReason.GHOSTING,
    AbortReason.TERMINATION_IN_PROGRESS,
    AbortReason.DEPOSIT,
  ],
  [SchedulingIssuesMainReason.FINANCING]: [
    AbortReason.WAITING_FOR_BANK_RESPONSE,
    AbortReason.ENSOL_FINANCING_REQUEST,
    AbortReason.FINANCING_REFUSED,
  ],
  [SchedulingIssuesMainReason.PROJECT_CHANGE]: [
    AbortReason.REDUCTION_NUMBER_OF_PANELS,
    AbortReason.SHADING,
    AbortReason.INCREASE_QUOTE_PRICE,
    AbortReason.AESTHETIC_CHANGE,
    AbortReason.TERRACOTTA_PV,
  ],
  [SchedulingIssuesMainReason.IN_PROGRESS]: [
    InProgressSchedulingIssues.HOLIDAYS,
    InProgressSchedulingIssues.NO_ANSWER,
    InProgressSchedulingIssues.WAITING_FOR_TECHNICAL_REVIEW_REPORT,
  ],
}

export const getSchedulingIssueMainReasonLabel = (
  subReason: string,
): string | null => {
  const mainReason = typedKeys(
    SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN,
  ).find((mainReason) =>
    _.includes(
      SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN[mainReason],
      subReason,
    ),
  )

  return mainReason ? SCHEDULING_ISSUES_MAIN_REASONS_LABEL[mainReason] : null
}
