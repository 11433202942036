import { Divider, Stack, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"

import { BackButton } from "@ensol/entool/components/BackButton"
import {
  CONTAINER_MIN_WIDTH,
  CONTENT_PADDING_IN_PX,
  useNavbarDimensions,
} from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"
import { getZIndex } from "@ensol/entool/styles/z-index"

export type HeaderProps = {
  backPath: string
  LeftComponent: ReactNode
  RightComponent: ReactNode
  height?: number
}

export const Header = ({
  backPath,
  LeftComponent,
  RightComponent,
  height,
}: HeaderProps) => {
  const { navbarHeight } = useNavbarDimensions()
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <Stack
      bg="blue.0"
      pt={{ base: 8, md: 16 }}
      pos="sticky"
      h={height}
      top={navbarHeight}
      gap="0"
      align={isSmallScreen ? "flex-start" : "center"}
      justify="space-between"
      style={{ zIndex: getZIndex("stickyHeader") }}
    >
      <Group
        miw={CONTAINER_MIN_WIDTH}
        justify="space-between"
        h="100%"
        wrap="nowrap"
        px={CONTENT_PADDING_IN_PX}
        pb={{ base: 8, md: 16 }}
        w={{ base: "100%", md: "auto" }}
      >
        <Stack gap={isSmallScreen ? 4 : 8} w={{ base: "100%", md: "auto" }}>
          <BackButton to={backPath}>Retour</BackButton>
          {LeftComponent}
        </Stack>
        {!isSmallScreen && (
          <Stack gap="4" justify="center" h="100%">
            {RightComponent}
          </Stack>
        )}
      </Group>
      <Divider w="100%" />
    </Stack>
  )
}
