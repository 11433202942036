import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"

type ConfirmActionInput = {
  message: string
  onConfirm: () => void
}

export const confirmAction = ({ message, onConfirm }: ConfirmActionInput) =>
  modals.openConfirmModal({
    title: "Confirmation de l'action",
    children: <Text size="sm">{message}</Text>,
    labels: { confirm: "Confirmer", cancel: "Annuler" },
    centered: true,
    onConfirm,
  })
