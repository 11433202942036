import { Stack, Group } from "@mantine/core"
import { IconAt, IconPhone } from "@tabler/icons-react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { getFullName } from "@ensol/shared/entities/prospects"

import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import { ProspectHeader } from "@ensol/entool/components/entities/Prospect/ProspectHeader"
import { CreateHouseButton } from "@ensol/entool/components/form/House/CreateHouseButton"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { House } from "@ensol/entool/pages/Prospect/House"
import { useGetProspectQuery } from "@ensol/entool/queries/prospects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

export const ProspectPage = () => {
  const { prospectId } = useParams()
  const prospectQuery = useGetProspectQuery(prospectId as string)

  return (
    <QueryWrapper query={prospectQuery}>
      {({ data: prospect }) => (
        <Stack flex="1" gap="0" h="100%">
          <Helmet>
            <title>{getFullName(prospect)} | Entool</title>
          </Helmet>
          <ProspectHeader
            prospect={prospect}
            backPath="/prospects"
            LeftComponent={
              <Group>
                <HeaderInfos
                  infos={[
                    { Icon: IconAt, text: prospect.email },
                    { Icon: IconPhone, text: prospect.phone },
                  ]}
                />
                <HubspotLink
                  hubspotContactId={prospect.hubspotContactId}
                  variant="transparent"
                  p="0"
                />
              </Group>
            }
            RightComponent={null}
          />
          <ContentContainer
            flex="1"
            px={CONTENT_PADDING_IN_PX}
            mt={CONTENT_PADDING_IN_PX}
          >
            <Stack gap={24}>
              {prospect.houses.map((house) => (
                <House key={house.id} house={house} />
              ))}
            </Stack>
            <CreateHouseButton prospectId={prospect.id} />
          </ContentContainer>
        </Stack>
      )}
    </QueryWrapper>
  )
}
