import { Modal, Stack, TextInput, Button } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import * as z from "zod"

import { INTERCOM_LINK_REGEX } from "./useLinkIntercomConversation"

const linkIntercomConversationSchema = z.object({
  conversationLink: z
    .string()
    .regex(
      INTERCOM_LINK_REGEX,
      "L'url de la conversation Intercom est invalide",
    ),
})

type Props = {
  isOpen: boolean
  close: () => void
  onSubmit: (conversationLink: string) => void
}

export const LinkForm = ({ isOpen, close, onSubmit }: Props) => {
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(linkIntercomConversationSchema),
    initialValues: { conversationLink: "" },
  })

  return (
    <Modal
      title="Lier à une conversation Intercom"
      opened={isOpen}
      onClose={close}
      centered
    >
      <Stack>
        <TextInput
          placeholder="https://app.intercom.com/.../conversation/12345"
          data-autofocus
          {...form.getInputProps("conversationLink")}
        />
        <Button
          fullWidth
          onClick={async () => {
            await onSubmit(form.values.conversationLink)
            close()
          }}
          disabled={!form.isValid()}
        >
          Lier la conversation
        </Button>
      </Stack>
    </Modal>
  )
}
