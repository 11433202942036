import FullCalendar from "@fullcalendar/react"
import { useEffect, useRef } from "react"

export const useCalendarShortcuts = () => {
  const calendarRef = useRef<FullCalendar | null>(null)

  useEffect(() => {
    const eventHandler = ({ code }: KeyboardEvent) => {
      const calendarApi = calendarRef.current?.getApi()

      if (!calendarApi) {
        return
      }

      if (code === "ArrowLeft") {
        calendarApi.prev()
      } else if (code === "ArrowRight") {
        calendarApi.next()
      }
    }

    document.addEventListener("keydown", eventHandler)
    return () => document.removeEventListener("keydown", eventHandler)
  }, [])

  return calendarRef
}
