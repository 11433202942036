import dayjs, { UnitType } from "dayjs"

import { TimeFrame } from "@ensol/types/reports"

export const computeTimeFrameDuration = (
  timeFrame: TimeFrame,
  unit: UnitType,
) => dayjs(timeFrame.endDate).diff(dayjs(timeFrame.startDate), unit)

export const isDateInTimeFrame = (date: string, timeFrame: TimeFrame | null) =>
  timeFrame === null ||
  (dayjs(date).isAfter(timeFrame.startDate) &&
    dayjs(date).isBefore(timeFrame.endDate))
