import { Anchor, Button, SimpleGrid, Stack } from "@mantine/core"

import { CetelemFormInput } from "@ensol/types/forms/projects/payment"
import { ProjectDocumentType } from "@ensol/types/project"

import { getProspectFileName } from "@ensol/shared/utils/files"

import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { GenerateEstimateForm } from "@ensol/entool/pages/OpsProject/processes/Payment/components/GenerateEstimateForm"
import { useCreateDocumentMutation } from "@ensol/entool/queries/projects"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const CetelemForm = <Input extends CetelemFormInput>({
  project,
  processId,
  actions,
  initialValues,
  schema,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const {
    mutateAsync: createCetelemFinancingRequest,
    isPending: isFinancingRequestLoading,
  } = useCreateDocumentMutation(
    project.id,
    ProjectDocumentType.CETELEM_FINANCING_REQUEST,
  )

  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: "paymentStep",
  })

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(project)}
    >
      <GenerateEstimateForm project={project} />
      <SimpleGrid cols={2} spacing={32}>
        <Stack>
          <FormField
            initialValues={initialValues}
            propertyName="cetelemFinancingRequestPath"
            isRequired
          >
            <Button
              onClick={async () => {
                const { cetelemFinancingRequestPath } =
                  await createCetelemFinancingRequest()
                form
                  .getInputProps("cetelemFinancingRequestPath")
                  .onChange(cetelemFinancingRequestPath)
              }}
              loading={isFinancingRequestLoading}
              disabled={project.cetelemFinancingRequestPath !== null}
              w={FILE_THUMBNAIL_WIDTH_IN_PX}
            >
              Générer la demande de financement Cetelem
            </Button>
            <FileField
              previewProps={{
                fileName: getProspectFileName(
                  "demande-financement-cetelem",
                  project.installation.house.prospect,
                ),
              }}
              inputProps={{ accept: ["application/pdf"] }}
              {...form.getInputProps("cetelemFinancingRequestPath")}
            />
          </FormField>
          {project.cetelemFinancingRequestSignatureLink !== null && (
            <Anchor
              href={project.cetelemFinancingRequestSignatureLink}
              target="_blank"
            >
              Lien de signature
            </Anchor>
          )}
        </Stack>
      </SimpleGrid>
    </StepActions>
  )
}
