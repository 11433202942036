import { HardwareStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { PreorderPending } from "./1-PreorderPending"
import { PreorderDone } from "./2-PreorderDone"
import { OrderPending } from "./3-OrderPending"
import { PreparationPending } from "./4-PreparationPending"
import { DeliveryPending } from "./5-DeliveryPending"
import { OrderDelivered } from "./6-OrderDelivered"
import { OrderChecked } from "./7-OrderChecked"
import { OrderInstalled } from "./8-OrderInstalled"

export const getHardwareStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case HardwareStep.PREORDER_PENDING:
      return { StepComponent: PreorderPending, isCancellable: false }
    case HardwareStep.PREORDER_DONE:
      return { StepComponent: PreorderDone }
    case HardwareStep.ORDER_PENDING:
      return { StepComponent: OrderPending }
    case HardwareStep.PREPARATION_PENDING:
      return { StepComponent: PreparationPending }
    case HardwareStep.DELIVERY_PENDING:
      return { StepComponent: DeliveryPending }
    case HardwareStep.ORDER_DELIVERED:
      return { StepComponent: OrderDelivered }
    case HardwareStep.ORDER_CHECKED:
      return { StepComponent: OrderChecked }
    case HardwareStep.ORDER_INSTALLED:
      return { StepComponent: OrderInstalled }
    default:
      return {
        StepComponent: null,
      }
  }
}
