import { UseFormReturnType } from "@mantine/form"
import * as Sentry from "@sentry/react"
import _ from "lodash"
import * as z from "zod"

import { hasKey } from "@ensol/types/utils"

export const isFormValid = <Values extends Record<string, unknown>>(
  form: UseFormReturnType<Values>,
) => form.isValid() && _.isEmpty(form.errors)

export const formatZodValidationError = (
  issues: z.ZodIssue[],
  labels: Record<string, string>,
) =>
  issues
    .map((issue) => {
      const path = _.last(issue.path)
      if (path !== undefined && hasKey(labels, path)) {
        const field = labels[path]
        return `${field} : ${issue.message}`
      } else {
        Sentry.captureException(
          new Error("Could not find field label for validation error message"),
          { extra: { path } },
        )
        return `${path} : ${issue.message}`
      }
    })
    .join("\n")
