type InstallationInsightInfo = {
  name: string
}

export const InstallationInsightType = {
  LOW_AUTO_CONSUMPTION: "LOW_AUTO_CONSUMPTION",
  HIGH_AUTO_CONSUMPTION: "HIGH_AUTO_CONSUMPTION",
  LOW_AUTO_PRODUCTION: "LOW_AUTO_PRODUCTION",
  BOILER_MISSCHEDULED: "BOILER_MISSCHEDULED",
  EV_DETECTED: "EV_DETECTED",
} as const

export type InstallationInsightType = keyof typeof InstallationInsightType

export const INSTALLATION_INSIGHTS: Record<
  InstallationInsightType,
  InstallationInsightInfo
> = {
  [InstallationInsightType.LOW_AUTO_CONSUMPTION]: {
    name: "+batterie",
  },
  [InstallationInsightType.HIGH_AUTO_CONSUMPTION]: {
    name: "+PV (basse production)",
  },
  [InstallationInsightType.LOW_AUTO_PRODUCTION]: {
    name: "+PV (haute consommation)",
  },
  [InstallationInsightType.BOILER_MISSCHEDULED]: {
    name: "Chauffe-eau mal réglé",
  },
  [InstallationInsightType.EV_DETECTED]: {
    name: "+borne de recharge",
  },
}

export const getInstallationInsight = (type: InstallationInsightType) =>
  INSTALLATION_INSIGHTS[type]
