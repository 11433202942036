import { Option, OptionType } from "./radio"

export const getOptionsFromList = <T extends OptionType>(
  list: T[],
): Option<T>[] => list.map((item) => ({ label: String(item), value: item }))

export const booleanOptions = [
  {
    label: "Oui",
    value: true,
  },
  {
    label: "Non",
    value: false,
  },
]
