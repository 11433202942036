import * as Sentry from "@sentry/react"
import _ from "lodash"
import { useCallback, useState } from "react"

import { Location } from "@ensol/types/forms/houses"

import { getLoader } from "./loader"

export const useSearchPlace = (googleApiKey: string) => {
  const [showLoader, setShowLoader] = useState(false)
  const [results, setResults] = useState<{ label: string; value: string }[]>([])

  const handleSearch = useCallback(
    async (input: string) => {
      if (!input) {
        setResults([])
        return ""
      }

      const loader = await getLoader(googleApiKey).importLibrary("places")
      const autocompleteService = new loader.AutocompleteService()

      setShowLoader(!results.length)
      try {
        const response = await autocompleteService.getPlacePredictions({
          input,
          types: ["address"],
          language: "fr",
          componentRestrictions: { country: "fr" },
          region: "fr",
        })

        const predictions = response.predictions

        if (!predictions?.length) {
          setResults([])
          return ""
        }

        setResults(
          predictions.map((pred) => ({
            label: pred.description,
            value: pred.place_id,
          })),
        )

        return predictions[0].place_id
      } catch (error) {
        Sentry.captureException(error, {
          extra: { input },
        })
        console.log(error)
      } finally {
        setShowLoader(false)
      }
    },
    [googleApiKey, results.length],
  )

  return {
    isLoading: showLoader,
    predictions: results,
    // TODO: abusé throttle qui retourne undefined
    onSearch: _.throttle((value) => handleSearch(value), 500) as (
      input: string,
    ) => Promise<string>,
  }
}

export const getPlaceDetails = async (
  apiKey: string,
  placeId: string,
): Promise<Location | undefined> => {
  const { PlacesService } = await getLoader(apiKey).importLibrary("places")
  const placesService = new PlacesService(
    document.getElementById("places") as HTMLDivElement,
  )

  return new Promise((resolve) =>
    placesService.getDetails({ placeId }, (place) => {
      if (place?.geometry?.location) {
        const streetNumber = place.address_components!.find((c) =>
          c.types.includes("street_number"),
        )?.long_name

        resolve({
          placeId,
          ...place.geometry.location.toJSON(),
          address: place.formatted_address!,
          streetAddress: `${streetNumber ? `${streetNumber} ` : ""}${
            place.address_components!.find((c) => c.types.includes("route"))
              ?.long_name ?? "N/A"
          }`,
          city:
            place.address_components!.find((c) => c.types.includes("locality"))
              ?.long_name ?? "N/A",
          postcode:
            place.address_components!.find((c) =>
              c.types.includes("postal_code"),
            )?.long_name ?? "N/A",
          region:
            place.address_components!.find((c) =>
              c.types.includes("administrative_area_level_1"),
            )?.long_name ?? "N/A",
          country:
            place.address_components!.find((c) => c.types.includes("country"))
              ?.long_name ?? "N/A",
        })
      } else {
        resolve(undefined)
      }
    }),
  )
}
