import { Group, Select, Stack, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconInfoCircle } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateRoofTypeConfigSchema,
} from "@ensol/types/forms/houses"

import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import { ROOF_TYPE_OPTIONS } from "@ensol/entool/utils/house/options"

type Props = {
  house: HousesResponses.House
}

export const UpdateRoofType = ({ house }: Props) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateRoofTypeConfigSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_ROOF_TYPE,
      roofType: house.roofType,
    },
  })

  return (
    <Field name="Couverture" withAsterisk noBorder>
      <Stack gap="4">
        <Group align="center" gap="4">
          <IconInfoCircle size={16} />
          <Text size="sm">
            {house.hasGroundInstallation
              ? "La pose est au sol"
              : house.hasFlatRoof
                ? "La toiture est plate"
                : "La toiture n'est pas plate"}
          </Text>
        </Group>
        <Select
          w={FIELD_WIDTH}
          data={ROOF_TYPE_OPTIONS}
          {...form.getInputProps("roofType")}
        />
      </Stack>
    </Field>
  )
}
