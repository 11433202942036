import { Badge, Group, Stack, Tabs, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import _ from "lodash"
import { useEffect, useState } from "react"
import { z } from "zod"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  RoofSectionWithPanels,
  roofSectionWithPanelsSchema,
} from "@ensol/types/forms/installations/photovoltaic"
import { HouseSignedInstallations } from "@ensol/types/installation"
import { ProductionDataSource } from "@ensol/types/simulation"

import { formatRoofSectionName } from "@ensol/shared/entities/houses/roofSection"
import {
  computeSignedInstallationsPanelsCountForRoofSection,
  getRecommendedPanelsCount,
} from "@ensol/shared/entities/installations/energy"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { NumberInputWithSuggestion } from "@ensol/entool/components/form/NumberInputWithSuggestion"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"

type Props = {
  roofSectionsWithPanels: RoofSectionWithPanels[]
  onChange: (data: RoofSectionWithPanels[]) => void
  setError: (error: string) => void
  clearError: () => void
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "hasFlatRoof" | "hasGroundInstallation" | "roofSections"
  >
  signedInstallations?: HouseSignedInstallations
  panelType: PanelType
  productionDataSource?: ProductionDataSource
  yearlyConsumption?: number
  estimatedProduction?: number
  maxPanelsCount?: number
  totalPanelsCount: number
}

export const RoofSectionsPanelsForm = ({
  roofSectionsWithPanels,
  onChange,
  setError,
  clearError,
  house,
  signedInstallations,
  panelType,
  productionDataSource,
  yearlyConsumption,
  estimatedProduction,
  maxPanelsCount,
  totalPanelsCount,
}: Props) => {
  const form = useForm({
    onValuesChange: ({ roofSectionsWithPanels }) =>
      onChange(roofSectionsWithPanels),
    validate: zodResolver(
      z.object({
        roofSectionsWithPanels: z.array(roofSectionWithPanelsSchema),
      }),
    ),
    initialValues: { roofSectionsWithPanels },
  })

  const [panelsCountSuggestion, setPanelsCountSuggestion] = useState<number>()

  // Suggestions for panels count
  useEffect(() => {
    if (yearlyConsumption) {
      const recommendedPanelsCount = getRecommendedPanelsCount({
        yearlyConsumption,
        panelType,
        shouldBeCapped: false,
      })

      setPanelsCountSuggestion(recommendedPanelsCount)
    }
  }, [panelType, yearlyConsumption])

  const productionConsumptionPercent =
    estimatedProduction && yearlyConsumption
      ? Math.round((estimatedProduction / yearlyConsumption) * 100)
      : null

  // This is necessary in case the roof sections have been updated
  useEffect(() => {
    if (
      !_.isEqual(
        house.roofSections.map(({ id }) => id),
        form.values.roofSectionsWithPanels.map(
          ({ roofSection }) => roofSection.id,
        ),
      )
    ) {
      form.setFieldValue(
        "roofSectionsWithPanels",
        computeHouseRoofSectionsWithPanels(
          house.roofSections,
          roofSectionsWithPanels,
        ),
      )
    }
  }, [form, house.roofSections, roofSectionsWithPanels])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("roof sections form is invalid")
    } else {
      clearError()
    }
  })

  return (
    <Stack>
      <Group justify="space-between" mb={8}>
        {!!totalPanelsCount && (
          <Group gap="4">
            <Text fw={500} c="gray.7">
              Total des panneaux :
            </Text>
            <Badge
              variant="light"
              color={
                productionDataSource === ProductionDataSource.GOOGLE &&
                maxPanelsCount &&
                maxPanelsCount <= totalPanelsCount
                  ? "red"
                  : "blue"
              }
            >
              {productionDataSource === ProductionDataSource.GOOGLE &&
              maxPanelsCount
                ? `${totalPanelsCount} / ${maxPanelsCount}`
                : totalPanelsCount}
            </Badge>
          </Group>
        )}
        {panelsCountSuggestion && (
          <Group gap="4">
            <Text fw={500} c="gray.7">
              Nombre de panneaux recommandé :
            </Text>
            <Badge variant="light" color="blue">
              {panelsCountSuggestion}
            </Badge>
          </Group>
        )}
      </Group>
      <Tabs
        orientation="vertical"
        defaultValue="roofSection_0"
        styles={{ list: { width: 200 }, tabLabel: { width: "100%" } }}
      >
        <Tabs.List>
          {roofSectionsWithPanels.map(({ roofSection }, index) => {
            const installedPanelsCountOnRoofSection =
              computeSignedInstallationsPanelsCountForRoofSection(
                roofSection.id,
                signedInstallations,
              )

            return (
              <Tabs.Tab
                key={`roofSection_${index}`}
                value={`roofSection_${index}`}
                title={
                  productionDataSource === ProductionDataSource.GOOGLE &&
                  index > 0
                    ? "Uniquement disponible avec PVGIS"
                    : undefined
                }
                disabled={
                  productionDataSource === ProductionDataSource.GOOGLE &&
                  index > 0
                }
              >
                <Group wrap="nowrap" justify="space-between">
                  <Text size="sm">
                    {formatRoofSectionName(
                      roofSection,
                      house.hasGroundInstallation,
                      house.hasFlatRoof,
                    )}
                  </Text>
                  <Badge variant="light" color="blue">
                    {installedPanelsCountOnRoofSection
                      ? `${installedPanelsCountOnRoofSection} (+${roofSectionsWithPanels[index].panelsCount})`
                      : roofSectionsWithPanels[index].panelsCount}
                  </Badge>
                </Group>
              </Tabs.Tab>
            )
          })}
        </Tabs.List>
        {roofSectionsWithPanels.map((_, index) => {
          return (
            <Tabs.Panel
              key={`roofSection_${index}`}
              value={`roofSection_${index}`}
            >
              <Stack pl={24}>
                <LegacyField name="Nombre de Panneaux" noBorder>
                  <NumberInputWithSuggestion
                    w={200}
                    ml="auto"
                    disabled={
                      productionDataSource === ProductionDataSource.GOOGLE &&
                      index > 0
                    }
                    suggestion={
                      productionDataSource === ProductionDataSource.GOOGLE &&
                      index === 0
                        ? panelsCountSuggestion
                        : undefined
                    }
                    min={0}
                    max={
                      productionDataSource === ProductionDataSource.GOOGLE
                        ? maxPanelsCount
                        : 80
                    }
                    data-test="panelsCount"
                    {...form.getInputProps(
                      `roofSectionsWithPanels.${index}.panelsCount`,
                    )}
                  />
                  {productionConsumptionPercent !== null && (
                    <Group gap="4">
                      <Text fw={500} size="sm" c="gray.7">
                        Ratio prod / conso :
                      </Text>
                      <Badge
                        variant="light"
                        color={
                          productionConsumptionPercent < 80 ||
                          productionConsumptionPercent > 90
                            ? "orange"
                            : "green"
                        }
                        size="sm"
                      >
                        {productionConsumptionPercent}%
                      </Badge>
                    </Group>
                  )}
                </LegacyField>
              </Stack>
            </Tabs.Panel>
          )
        })}
      </Tabs>
    </Stack>
  )
}
