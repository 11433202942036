import { Select, Stack, Textarea, Input, SimpleGrid } from "@mantine/core"
import { IconSolarPanel, IconTruckDelivery } from "@tabler/icons-react"

import { HardwareStepInput } from "@ensol/types/forms/projects/hardware"

import { HardwareDeliveryMode } from "@ensol/shared/entities/projects/delivery"
import {
  HardwareStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { HardwareInfo } from "@ensol/entool/pages/OpsProject/processes/Hardware/components/HardwareInfo"
import { useSuppliersOptions } from "@ensol/entool/queries/suppliers"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  HARDWARE_DELIVERY_ADDRESS_OPTIONS,
  HARDWARE_DELIVERY_MODE_OPTIONS,
  HARDWARE_DELIVERY_PICKUP_OPTIONS,
  INTEGRATION_KITS_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const HardwareForm = <Input extends HardwareStepInput>({
  schema,
  initialValues,
  project,
  processId,
  actions,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId,
  })
  const suppliersOptions = useSuppliersOptions()

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(form.values)}
    >
      <Section title="Commande" icon={IconTruckDelivery} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="supplierId"
              isRequired
            >
              <Select
                w={FIELD_WIDTH}
                data={suppliersOptions}
                {...form.getInputProps("supplierId")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="hardwareDeliveryMode"
            >
              <RadioGroup
                options={HARDWARE_DELIVERY_MODE_OPTIONS}
                {...form.getInputProps("hardwareDeliveryMode")}
              />
            </FormField>
            {project.hardwareDeliveryMode !== null && (
              <FormField
                initialValues={initialValues}
                propertyName="hardwareDeliveryAddress"
              >
                <Select
                  w={FIELD_WIDTH}
                  searchable
                  clearable
                  data={
                    project.hardwareDeliveryMode ===
                    HardwareDeliveryMode.ADDRESS
                      ? HARDWARE_DELIVERY_ADDRESS_OPTIONS
                      : HARDWARE_DELIVERY_PICKUP_OPTIONS
                  }
                  {...form.getInputProps("hardwareDeliveryAddress")}
                />
              </FormField>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="orderQuoteNumber"
            >
              <Input
                w={FIELD_WIDTH}
                {...form.getInputProps("orderQuoteNumber")}
              />
            </FormField>
            <FormField initialValues={initialValues} propertyName="orderAmount">
              <NumberInput
                w={FIELD_WIDTH}
                min={0}
                suffix="€"
                hideControls
                {...form.getInputProps("orderAmount")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="orderDeliveryDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                clearable
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps("orderDeliveryDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isFinalDeliveryDate"
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isFinalDeliveryDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="orderDeliveryComment"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("orderDeliveryComment")}
              />
            </FormField>
          </Stack>
          {getStepIndex(project[processId], processId) >=
            getStepIndex(HardwareStep.ORDER_PENDING, processId) && (
            <HardwareInfo project={project} />
          )}
        </SimpleGrid>
      </Section>
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
              isRequired
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
            <FormField initialValues={initialValues} propertyName="isPLCNeeded">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
