import { toSubmitStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { PRELIMINARY_REQUEST_PROPERTIES } from "./config"

export const ToSubmit = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={toSubmitStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(PRELIMINARY_REQUEST_PROPERTIES, project),
      ...getInitialValues(toSubmitStepFormSchema, project),
    }}
    project={project}
    actions={[{ action: "validate", label: "Envoyé" }]}
    {...props}
  />
)
