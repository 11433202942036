import _ from "lodash"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

export const getProjectInstallers = ({
  technicalVisitInstaller,
  installer,
}: Pick<ProjectResponses.Project, "technicalVisitInstaller" | "installer">) =>
  _.uniq([
    ...(technicalVisitInstaller ? [technicalVisitInstaller.name] : []),
    ...(installer ? [installer.name] : []),
  ])
