import { PreliminaryRequestStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { WaitingForInfo } from "./1-WaitingForInfo"
import { IncompleteWaitingForStakeholder } from "./10-IncompleteWaitingForStakeholder"
import { IncompleteToSubmit } from "./11-IncompleteToSubmit"
import { IncompleteSubmitted } from "./12-IncompleteSubmitted"
import { Rejected } from "./13-Rejected"
import { RejectedWaitingForStakeholder } from "./14-RejectedWaitingForStakeholder"
import { RejectedToSubmit } from "./15-RejectedToSubmit"
import { RejectedSubmitted } from "./16-RejectedSubmitted"
import { Aborted } from "./17-Aborted"
import { Approved } from "./18-Approved"
import { AuthorisationReceived } from "./19-AuthorisationReceived"
import { PreliminaryValidation } from "./2-PreliminaryValidation"
import { SubmissionOnHold } from "./3-SubmissionOnHold"
import { SubmissionPending } from "./4-SubmissionPending"
import { WaitingForSolteo } from "./5-WaitingForSolteo"
import { ToSubmit } from "./6-ToSubmit"
import { Submitted } from "./7-Submitted"
import { Acknowledgment } from "./8-Acknowledgment"
import { Incomplete } from "./9-Incomplete"

export const getPreliminaryRequestStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case PreliminaryRequestStep.WAITING_FOR_INFO:
      return { StepComponent: WaitingForInfo, isCancellable: false }
    case PreliminaryRequestStep.PRELIMINARY_VALIDATION:
      return { StepComponent: PreliminaryValidation, isCancellable: false }
    case PreliminaryRequestStep.SUBMISSION_ON_HOLD:
      return { StepComponent: SubmissionOnHold, isCancellable: false }
    case PreliminaryRequestStep.SUBMISSION_PENDING:
      return { StepComponent: SubmissionPending, isCancellable: false }
    case PreliminaryRequestStep.WAITING_FOR_SOLTEO:
      return { StepComponent: WaitingForSolteo }
    case PreliminaryRequestStep.TO_SUBMIT:
      return { StepComponent: ToSubmit }
    case PreliminaryRequestStep.SUBMITTED:
      return { StepComponent: Submitted }
    case PreliminaryRequestStep.ACKNOWLEDGMENT:
      return { StepComponent: Acknowledgment }
    case PreliminaryRequestStep.INCOMPLETE:
      return { StepComponent: Incomplete }
    case PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER:
      return { StepComponent: IncompleteWaitingForStakeholder }
    case PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT:
      return { StepComponent: IncompleteToSubmit }
    case PreliminaryRequestStep.INCOMPLETE_SUBMITTED:
      return { StepComponent: IncompleteSubmitted }
    case PreliminaryRequestStep.REJECTED:
      return { StepComponent: Rejected }
    case PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER:
      return { StepComponent: RejectedWaitingForStakeholder }
    case PreliminaryRequestStep.REJECTED_TO_SUBMIT:
      return { StepComponent: RejectedToSubmit }
    case PreliminaryRequestStep.REJECTED_SUBMITTED:
      return { StepComponent: RejectedSubmitted }
    case PreliminaryRequestStep.ABORTED:
      return { StepComponent: Aborted, isCancellable: false }
    case PreliminaryRequestStep.APPROVED:
      return { StepComponent: Approved }
    case PreliminaryRequestStep.AUTHORISATION_RECEIVED:
      return { StepComponent: AuthorisationReceived }

    default:
      return {
        StepComponent: null,
      }
  }
}
