import { InverterBrand } from "./inverters"

type ApplicationInfo = {
  name: string
  url: string
}

export const FOLLOW_UP_APPLICATIONS: Record<InverterBrand, ApplicationInfo> = {
  Enphase: {
    name: "Enlighten",
    url: "https://enlighten.enphaseenergy.com",
  },
  Huawei: { name: "FusionSolar", url: "https://eu5.fusionsolar.huawei.com" },
}

export const getApplication = (inverterBrand: InverterBrand): ApplicationInfo =>
  FOLLOW_UP_APPLICATIONS[inverterBrand]

export const getEnphaseUrl = (systemId: string | null) => {
  const baseUrl = FOLLOW_UP_APPLICATIONS.Enphase.url

  if (!systemId) {
    return baseUrl
  }

  return `${baseUrl}/systems/${systemId}`
}
