import { Group, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"

import { FundingSourceStatusInfo } from "@ensol/entool/components/entities/Project/FundingSourceStatusInfo"
import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FUNDING_SOURCE_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  fundingSourceStatus: FundingSourceStatus | null
}

export const FundingSourceField = <
  Values extends { fundingSource: FundingSource | null },
>({
  form,
  fundingSourceStatus,
}: Props<Values>) => (
  <Field
    name={
      <Group gap={2}>
        <Text fw={500} c="gray.7">
          Source de financement
        </Text>
        <FundingSourceStatusInfo ml="4" type={fundingSourceStatus} />
      </Group>
    }
    noBorder
    withAsterisk
  >
    <RadioGroup<FundingSource>
      options={FUNDING_SOURCE_OPTIONS}
      {...form.getInputProps("fundingSource")}
    />
  </Field>
)
