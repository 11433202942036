import { Stack, Divider } from "@mantine/core"
import { IconCoinEuro } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { SalesUpdateInstallationInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { computeAllCosts } from "@ensol/shared/entities/installations/costs"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { getSubsidy } from "@ensol/shared/entities/installations/subsidies"
import { formatCurrency } from "@ensol/shared/utils/format"

import {
  SummaryTitle,
  SummaryItem,
} from "@ensol/entool/pages/Installation/Summary/shared"

type Props = {
  installationValues: SalesUpdateInstallationInput
  house: HousesResponses.House<{ include: { roofSections: true } }>
  simulationResult?: SimulationResults
}

export const CostsSummary = ({
  installationValues,
  house,
  simulationResult,
}: Props) => {
  const totalDiscount = computeTotalDiscount(installationValues.discount)

  const { installationCost, finalCostWithSubsidies, subsidies } =
    computeAllCosts({
      ...installationValues,
      totalDiscount,
      house,
    })

  return (
    <Stack>
      <SummaryTitle title="Prix" icon={IconCoinEuro} />
      <Stack mb={12} gap={6}>
        <SummaryItem
          title="Prix total TTC"
          value={formatCurrency(installationCost)}
        />
        <Divider my={4} />
        <SummaryItem
          title="Prime voisin TTC"
          value={formatCurrency(-totalDiscount)}
          warning={simulationResult?.warnings.totalDiscount}
        />
        {subsidies.map(({ subsidyType, subsidyAmount }) => (
          <SummaryItem
            key={subsidyType}
            title={getSubsidy(subsidyType).shortName}
            value={formatCurrency(-subsidyAmount)}
          />
        ))}
        <Divider my={4} />
        <SummaryItem
          title="Prix net"
          value={formatCurrency(finalCostWithSubsidies)}
        />
        {simulationResult !== undefined && (
          <>
            <SummaryItem
              size="xs"
              title="Prix net / Wc"
              value={formatCurrency(simulationResult.costPerInstalledCapacity)}
            />
            <SummaryItem
              size="xs"
              title="Prix net / kWh PV"
              value={formatCurrency(
                simulationResult.panelsEnergyProductionCost,
              )}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}
