import { AxiosError, isAxiosError } from "axios"
import { ZodError } from "zod"

export const getAxiosStatusCodeValidator =
  (statusCode: number) =>
  <T = unknown>(error: unknown): error is AxiosError<T> =>
    isAxiosError(error) && error.response?.status === statusCode

export const isAxiosNotFoundError = getAxiosStatusCodeValidator(404)
export const isAxiosUnauthorizedError = getAxiosStatusCodeValidator(401)
export const isAxiosBadRequestError = getAxiosStatusCodeValidator(400)
export const isAxiosForbiddenError = getAxiosStatusCodeValidator(403)

export const isZodError = (error: unknown): error is ZodError => {
  if (error instanceof ZodError) return true

  return error instanceof Object && "name" in error && error.name === "ZodError"
}

export class EnsolError extends Error {
  constructor(public message: string) {
    super(message)
    this.name = "EnsolError"
  }

  toString() {
    return {
      name: this.name,
      message: this.message,
    }
  }
}

export const isEnsolError = (error: unknown): error is EnsolError => {
  if (error instanceof EnsolError) return true

  return (
    error instanceof Object && "name" in error && error.name === "EnsolError"
  )
}
