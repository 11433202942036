import { useParams } from "react-router-dom"

import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"

import { PanelsLayout } from "./PanelsLayout"

export const PanelsLayoutPage = () => {
  const { installationId } = useParams()

  const installationQuery = useGetInstallationQuery(installationId as string)

  return (
    <ContentContainer>
      <QueryWrapper query={installationQuery}>
        {({ data: installation }) => (
          <PanelsLayout installation={installation} />
        )}
      </QueryWrapper>
    </ContentContainer>
  )
}
